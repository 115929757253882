import { RcFile } from "antd/lib/upload";
import { AxiosInstancesManager } from "src/configs/AxiosInstancesManager";
import { DMSFileData } from "src/models";

export const getFiles = async ({
  take = 10,
  skip = 0,
  fileTypes = [],
  ownerCompanyId,
  searchText,
  fileName,
  metaDataSearches,
}: {
  take?: number;
  skip?: number;
  fileTypes?: string[];
  ownerCompanyId?: string;
  searchText?: string;
  fileName?: string;
  metaDataSearches?: Array<Object>;
}) =>
  AxiosInstancesManager.getDocument().post<DMSFileData[]>(
    `/api/v2/Files/Filters`,
    {
      fileTypes,
      ownerCompanyId,
      searchText,
      fileName,
      metaDataSearches,
    },
    {
      params: {
        take,
        skip,
      },
    }
  );

export const downloadFile = async (id: string) =>
  await AxiosInstancesManager.getDocument().get<DMSFileData>(
    `/api/v2/files/${id}/download`,
    {
      responseType: "blob",
    }
  );

export const downloadThumbnail = async (id: string) =>
  await AxiosInstancesManager.getDocument().get<DMSFileData>(
      `/api/v2/files/${id}/thumbnail`,
      {
        responseType: "blob",
      }
  );

export const uploadFile = ({
  file,
  fileType,
}: {
  file: RcFile;
  fileType: string;
}) => {
  const documentInstance = AxiosInstancesManager.getDocument();

  if (document) {
    const formData = new FormData();

    formData.append("file", file);

    return documentInstance.post(`/api/v2/Files/upload`, formData, {
      params: { fileType },
      data: {},
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }
};

export const getUploadedFileUserInfo = async (userId: string) => {
  const userInstance = AxiosInstancesManager.getCumd();

  try {
    const response = await userInstance.get(`/api/users/${userId}`);
    return `${response.data.userFirstName} ${response.data.userLastName}`;
  } catch (error) {
    console.error("Error fetching file uploaded user info:", error);
  }
};
