import React from "react";
import { useSelector } from "react-redux";

import {
  UserProfilePage,
  USER_PROFILE_TILE,
} from "@trace-one/business-components";

import useLogout from "../../core/oidc/useLogout";
import usePermissions from "../../core/oidc/usePermissions";
import { useAppDispatch } from "../../reduxStore";
import {
  selectNotificationUserApplications,
  selectUserId,
} from "../../reduxStore/user/selectors";
import { updateUserLanguage } from "../../reduxStore/user/slice";

interface UserProfileProps {
  section: USER_PROFILE_TILE;
}

export const UserProfile: React.FC<UserProfileProps> = ({ section }) => {
  const dispatch = useAppDispatch();
  const userId = useSelector(selectUserId);
  const userApplicationTypeIds = useSelector(
    selectNotificationUserApplications
  ).map(({ applicationTypeId }) => applicationTypeId);
  const { logout } = useLogout();
  const handleLanguageChange = value => dispatch(updateUserLanguage(value));

  const { isTraceoneAdmin } = usePermissions();

  return (
    <UserProfilePage
      userId={userId}
      onLogout={logout}
      onLanguageChange={handleLanguageChange}
      applicationTypeIds={userApplicationTypeIds}
      withMarginTop
      section={section}
      isCompanyAdmin={isTraceoneAdmin}
    />
  );
};

export default UserProfile;
