import { createAsyncThunk } from "@reduxjs/toolkit";

import { PmdAPI, CumdAPI } from "apis";
import { CompanyData } from "models";

import { TradeItemStatus } from "shared/constants";

export const fetchProducts = createAsyncThunk(
  "productList/fetchProducts",
  async ({
    searchText,
    tradeItemStatuses,
    brandIds,
    categoryId,
    categoryItemId,
    OnlyWithoutContacts,
    HasNoBrand,
    HasNoGtin,
    HasNoProductCategory,
    ownerCompanyId,
    skip,
    take,
    associatedCompanyIds,
    ContactUserId,
    ContactResponsibilityId,
  }: {
    searchText?: string;
    OnlyWithoutContacts?: boolean;
    tradeItemStatuses: string[];
    brandIds?: string[];
    categoryId?: string;
    HasNoBrand?: boolean;
    HasNoGtin?: boolean;
    HasNoProductCategory?: boolean;
    categoryItemId?: string;
    ownerCompanyId?: string;
    ContactUserId?: string;
    ContactResponsibilityId?: string;

    skip: number;
    take: number;
    associatedCompanyIds: string[];
  }) => {
    let filter = {};
    if (ContactUserId) {
      filter = { ContactResponsibilityId, ContactUserId };
    }

    const { data } = await PmdAPI.getTradeItemsByFilters(
      {
        searchText,
        tradeItemStatuses,
        brandIds,
        supplierCompanyIds: associatedCompanyIds ?? undefined,
      },
      {
        OnlyWithoutContacts,
        ...filter,
        categoryId,
        HasNoBrand,
        HasNoGtin,
        HasNoProductCategory,
        categoryItemId,
        ownerCompanyId,
        skip,
        take,
      }
    );
    return data;
  }
);

export const fetchManufacturedItemsByFilters = createAsyncThunk(
  "productList/fetchManufacturedItemsByFilters",
  async ({
    searchText,
    ownerCompanyId,
    skip,
    take,
    retailerCompanyId,
    manufacturedItemStatuses,
  }: {
    searchText?: string;
    ownerCompanyId?: string;
    retailerCompanyId?: string;
    manufacturedItemStatuses?: string[];
    skip: number;
    take: number;
  }) => {
    const { data: productList } = await PmdAPI.fetchManufacturedItemsByFilters(
      {
        manufacturedItemStatuses,
      },
      {
        searchText,
        ownerCompanyId: retailerCompanyId,
        supplierCompanyId: ownerCompanyId,
        skip,
        take,
      }
    );
    const companyIds = productList.manufacturedItems.map(s => s.ownerCompanyId);

    let companyList: CompanyData[] = [];
    if (companyIds.length) {
      const companyIdsUniq: string[] = [...new Set(companyIds)];
      const {
        data: { companies },
      } = await CumdAPI.getCompaniesByFilters(
        { companyIds: companyIdsUniq },
        {
          relatedAssociatedCompanyId: ownerCompanyId,
          includePrivate: true,
        }
      );
      companyList = companies;
    }

    return { productList, companyList };
  }
);

export const fetchKpiIndicators = createAsyncThunk(
  "siteList/fetchKpiIndicators",
  async ({ ownerCompanyId }: { ownerCompanyId: string }, thunkAPI) => {
    try {
      const [NoBrandCount, NoCategoryCount, NoContactCount, NoGtin] =
        await Promise.all([
          PmdAPI.getTradeItemsByFilters(
            {
              searchText: "",
              tradeItemStatuses: [TradeItemStatus.ACTIVE],
              brandIds: [],
              supplierCompanyIds: [],
            },
            {
              HasNoBrand: true,
              ownerCompanyId,
            }
          ).then(({ data }) => data.skipAndTakeQueryStats.totalCount),
          PmdAPI.getTradeItemsByFilters(
            {
              searchText: "",
              tradeItemStatuses: [TradeItemStatus.ACTIVE],
              brandIds: [],
              supplierCompanyIds: [],
            },
            {
              HasNoProductCategory: true,
              ownerCompanyId,
            }
          ).then(({ data }) => data.skipAndTakeQueryStats.totalCount),
          PmdAPI.getTradeItemsByFilters(
            {
              searchText: "",
              tradeItemStatuses: [TradeItemStatus.ACTIVE],
              brandIds: [],
              supplierCompanyIds: [],
            },
            {
              OnlyWithoutContacts: true,
              ownerCompanyId,
            }
          ).then(({ data }) => data.skipAndTakeQueryStats.totalCount),
          PmdAPI.getTradeItemsByFilters(
            {
              searchText: "",
              tradeItemStatuses: [TradeItemStatus.ACTIVE],
              brandIds: [],
              supplierCompanyIds: [],
            },
            {
              HasNoGtin: true,
              ownerCompanyId,
            }
          ).then(({ data }) => data.skipAndTakeQueryStats.totalCount),
        ]);

      return {
        NoBrandCount,
        NoCategoryCount,
        NoContactCount,
        NoGtin,
      };
    } catch (err) {
      const status = err?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({ status });
    }
  }
);
