import { CompanyData, CompanyRelationData, SiteData, UserData } from "models";

type State = {
  data: {
    site?: SiteData & {
      siteOwnerCompanyName: string;
    };
    supplier?: CompanyData; //CompanyData;
    relation?: any;
    allergenNames?: string[];
    relatedUsers?: UserData[];
    companyRelation?: CompanyRelationData;
  };
  isLoading: boolean;
  hasErrors: boolean;
  siteRetailerFormData: any;
  errorStatus: any;
};

export const initialState: State = {
  data: {},
  isLoading: false,
  hasErrors: false,
  errorStatus: null,
  siteRetailerFormData: {},
};
