import { useEffect, useRef, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Filter } from "@trace-one/business-components";
import {
  Checkbox,
  Typography,
  DateRangePicker,
} from "@trace-one/design-system";
import moment from "moment";

import { CumdAPI, PmdAPI, SmdAPI } from "apis";

import { useAppDispatch } from "reduxStore";
import {
  fetchSites,
  fetchProducts,
} from "reduxStore/documentList/asyncActions";
import {
  selectRelatedCompaniesOptions,
  selectFileTypes,
  selectSites,
  selectProducts,
  selectProjects,
  selectCompaniesMapForTable,
} from "reduxStore/documentList/selectors";
import { selectIsRetailer } from "reduxStore/oidc/selectors";
import {
  selectUserLanguageCode,
  selectUserApplications,
  selectUserOwningCompanyId,
} from "reduxStore/user/selectors";

import AsyncPickerDropdown from "components/AsyncPickerDropdown";
import AsyncSearchSelect from "components/AsyncSearchSelect";
import FilterMultiSelection from "components/FilterMultiSelection";
import Select from "components/Select";
import { ApplicationTypes, TradeItemStatus } from "shared/constants";
import buildCompanyTitle from "shared/utils/buildCompanyTitle";
import { adjustIntercomButton } from "shared/utils/toggleIntercom";

import AsyncCompanySelect, {
  getSupplierName,
} from "../../../../../components/AsyncCompanySelect";
import { DocumentFilter } from "../models";

import styles from "./DocumentSearch.module.less";

interface DocumentSearchProps {
  filterObj: DocumentFilter;
  mergeFilters: (
    value:
      | Partial<DocumentFilter>
      | ((prevState: DocumentFilter) => DocumentFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: (searchValue: string) => void;
  onClearFiltersClick: () => void;
}

const DocumentSearch: React.FC<DocumentSearchProps> = ({
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
}) => {
  const dispatch = useAppDispatch();
  const isRetailer = useSelector(selectIsRetailer);
  const userOwningCompanyId = useSelector(selectUserOwningCompanyId);
  const userApplications = useSelector(selectUserApplications);
  const projectAccess = userApplications.some(
    obj => obj.applicationTypeId === ApplicationTypes.PROJECT
  );
  const { data: relatedCompanyOptions } = useSelector(
    selectRelatedCompaniesOptions
  );
  const companiesMap = useSelector(selectCompaniesMapForTable);
  const sites = useSelector(selectSites).map(site => ({
    value: site.value,
    name: site.name + ", " + companiesMap[site.companyId]?.companyDisplayName,
  }));
  const products = useSelector(selectProducts);
  const projects = useSelector(selectProjects);
  const { data: fileTypes } = useSelector(selectFileTypes);
  const languageCode = useSelector(selectUserLanguageCode);

  const { formatMessage } = useIntl();
  const companies = isRetailer ? relatedCompanyOptions : [];
  const fileTypesOptions = useMemo(() => {
    return fileTypes?.map(fileType => ({
      label: fileType.translations[0]?.value,
      value: fileType.id,
      testId: "filter-" + fileType.translations[0]?.value,
    }));
  }, [fileTypes]);
  const showIntercom = useRef(true);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [associatedCompanyLabel, setAssociatedCompanyLabel] =
    useState<string>("");
  const [isRelationStatusEnabled, setIsRelationStatusEnabled] =
    useState<boolean>(true);
  const [companyType, setCompanyType] = useState<string>(null);
  const [searchProductValue, setSearchProductValue] = useState<string>("");
  const [searchSiteValue, setSearchSiteValue] = useState<string>("");

  useEffect(() => {
    dispatch(
      fetchSites({
        associatedCompanyId: userOwningCompanyId,
        ownerCompanyIdCollection: filterObj.ownerCompanyId
          ? [filterObj.ownerCompanyId]
          : undefined,
      })
    );
    dispatch(
      fetchProducts({
        ownerCompanyId: userOwningCompanyId,
        supplierCompanyIds: filterObj.ownerCompanyId
          ? [filterObj.ownerCompanyId]
          : undefined,
      })
    );
  }, [filterObj.ownerCompanyId, dispatch]);

  useEffect(() => {
    const {
      ownerCompanyId,
      fileTypeIds,
      isMyCompany,
      uploadedAtStartDate,
      uploadedAtEndDate,
      tradeItemId,
      siteId,
      projectId,
    } = filterObj;
    const { name, isCompanyRelationStatusEnabled, companyType } =
      companies.find(({ companyId }) => companyId === ownerCompanyId) ?? {};
    const filteredTypes = fileTypeIds
      ? fileTypes
          .filter(type => fileTypeIds?.includes(type.id))
          .map(type => ({
            label: type.translations[0]?.value,
            value: type.id,
          }))
      : [];
    const defaultSelectedFilters = [
      filteredTypes.length && {
        filterId: "1",
        values: filteredTypes.map(e => e.label),
      },
      (uploadedAtStartDate || uploadedAtEndDate) && {
        filterId: "2",
        values: [uploadedAtStartDate + "-" + uploadedAtEndDate],
      },
      isMyCompany && {
        filterId: "3",
        values: [isMyCompany],
      },
      ownerCompanyId && {
        filterId: "4",
        values: [ownerCompanyId],
      },
      ...(tradeItemId || siteId || projectId
        ? [
            {
              filterId: "5",
              values: [true],
            },
          ]
        : []),
    ].filter(i => !!i);

    setSelectedFilters(defaultSelectedFilters);
    setAssociatedCompanyLabel(name);
    setIsRelationStatusEnabled(isCompanyRelationStatusEnabled);
    setCompanyType(companyType);
  }, [filterObj, companies, fileTypes]);

  const filters = [
    {
      title: formatMessage({
        id: `documentLibraryListPage.docDetails.tabs.informations.docType`,
      }),
      controlType: "multi_selection",
      clearSelection: () => {
        mergeFilters({
          fileTypeIds: undefined,
        });
      },
      elementToRender: (
        <FilterMultiSelection
          includeSelectAll
          dataTestId="md-filetype-selection"
          options={fileTypesOptions}
          values={filterObj.fileTypeIds ? filterObj.fileTypeIds : []}
          onChange={selectedValues => {
            mergeFilters({
              fileTypeIds: selectedValues.length
                ? selectedValues.map(e => e.value)
                : undefined,
            });
          }}
        />
      ),
      quickFilter: (
        <AsyncPickerDropdown
          data-test-id="picker-filter-docType"
          searchPlaceholderText={formatMessage({ id: "general.search" })}
          clearBtnText={formatMessage({ id: "general.clear" })}
          closeBtnText={formatMessage({ id: "general.close" })}
          title={formatMessage({
            id: `documentLibraryListPage.docDetails.tabs.informations.docType`,
          })}
          showBadgeCount
          defaultOptions={fileTypesOptions}
          onAsyncSearch={undefined}
          minLengthToSearch={1}
          searchOnChange
          onSearch={str => {
            return fileTypesOptions.filter(x =>
              x.label.toLowerCase().includes(str.toLowerCase())
            );
          }}
          value={filterObj.fileTypeIds ?? []}
          selectionType="multi"
          showSearchInput
          onChange={({ value }) => {
            mergeFilters({
              fileTypeIds: value.length ? value : undefined,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              fileTypeIds: undefined,
            })
          }
        ></AsyncPickerDropdown>
      ),
      filterId: "1",
    },
    {
      title: formatMessage({
        id: `documentLibraryListPage.filterComponent.uploadedAt`,
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          uploadedAtStartDate: undefined,
          uploadedAtEndDate: undefined,
        });
      },
      elementToRender: (
        <DateRangePicker
          allowEmpty={[true, true]}
          disabledDate={current => {
            return current.isAfter(moment());
          }}
          value={[
            filterObj.uploadedAtStartDate
              ? moment(filterObj.uploadedAtStartDate)
              : undefined,
            filterObj.uploadedAtEndDate
              ? moment(filterObj.uploadedAtEndDate)
              : undefined,
          ]}
          onChange={dateRange => {
            mergeFilters({
              uploadedAtStartDate: dateRange?.[0]
                ? moment(dateRange[0]).toString()
                : undefined,
              uploadedAtEndDate: dateRange?.[1]
                ? moment(dateRange[1]).toString()
                : undefined,
            });
          }}
          locale={languageCode}
        />
      ),
      filterId: "2",
    },
    {
      title: formatMessage({
        id: `documentLibraryListPage.table.col.companyOwner`,
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          isMyCompany: false,
        });
      },
      elementToRender: (
        <Checkbox
          checked={!!associatedCompanyLabel ? false : filterObj.isMyCompany}
          disabled={!!associatedCompanyLabel}
          onChange={e => {
            mergeFilters({
              isMyCompany: e.target.checked,
            });
          }}
          data-test-id="md-mycompany-input"
        >
          <Typography component="span">
            {formatMessage({
              id: `documentLibraryListPage.filterComponent.myCompany`,
            })}
          </Typography>
        </Checkbox>
      ),
      filterId: "3",
    },
    {
      title: formatMessage({
        id: `documentLibraryListPage.filterComponent.externalCompany`,
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          ownerCompanyId: undefined,
        });
      },
      elementToRender: (
        <AsyncCompanySelect
          getPopupContainer={trigger => trigger.parentElement}
          label={getSupplierName(
            associatedCompanyLabel,
            isRelationStatusEnabled,
            {
              companyType: companyType,
            }
          )}
          optionLabelProp="label"
          value={filterObj.ownerCompanyId}
          onChange={ownerCompanyId => {
            mergeFilters({
              tradeItemId: undefined,
              siteId: undefined,
              ownerCompanyId,
              isMyCompany: false,
            });
          }}
          defaultOptions={companies.map(company => ({
            value: company.companyId,
            name: getSupplierName(
              buildCompanyTitle(company),
              company.isCompanyRelationStatusEnabled,
              company
            ),
            title: buildCompanyTitle(company),
          }))}
          isRetailer={isRetailer}
          onLabelChange={name => {
            setAssociatedCompanyLabel(name);
          }}
          isSupplierInput={undefined}
          companyActivityId={undefined}
          key={associatedCompanyLabel}
          id="md-documentFilter-companyId"
          includePrivate={false}
          data-test-id="md-documentFilter-companyId"
        />
      ),
      quickFilter: (
        <AsyncPickerDropdown
          data-test-id="picker-filter-externalCompany"
          searchPlaceholderText={formatMessage({ id: "general.search" })}
          clearBtnText={formatMessage({ id: "general.clear" })}
          closeBtnText={formatMessage({ id: "general.close" })}
          title={formatMessage({
            id: `documentLibraryListPage.filterComponent.externalCompany`,
          })}
          showBadgeCount
          searchOnChange
          onSearch={undefined}
          defaultOptions={companies.map(company => ({
            label: getSupplierName(
              buildCompanyTitle(company),
              company.isCompanyRelationStatusEnabled,
              company
            ),
            value: company.companyId,
            testId: "filter-" + buildCompanyTitle(company),
          }))}
          value={filterObj.ownerCompanyId ? [filterObj.ownerCompanyId] : []}
          showSearchInput
          selectionType="single"
          minLengthToSearch={3}
          onAsyncSearch={searchValue => {
            return CumdAPI.getCompaniesByFilters(
              {},
              {
                searchName: searchValue,
                relatedOwnerCompanyId: isRetailer
                  ? userOwningCompanyId
                  : undefined,
                relatedAssociatedCompanyId: isRetailer
                  ? undefined
                  : userOwningCompanyId,
                includePrivate: false,
              }
            ).then(({ data }) => {
              return data.companies.map(company => ({
                label: getSupplierName(
                  buildCompanyTitle(company),
                  company.isCompanyRelationStatusEnabled,
                  company
                ),
                value: company.companyId,
                searchLabel: buildCompanyTitle(company),
                testId: "filter-" + buildCompanyTitle(company),
              }));
            });
          }}
          onChange={({ value }) => {
            const selectedId =
              value[0] === filterObj.ownerCompanyId ? undefined : value[0];
            mergeFilters({
              tradeItemId: undefined,
              siteId: undefined,
              ownerCompanyId: selectedId,
              isMyCompany: false,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              ownerCompanyId: undefined,
            })
          }
        ></AsyncPickerDropdown>
      ),
      filterId: "4",
    },
    ...(window.env.GSS_INTEGRATION === "true"
      ? [
          {
            title: formatMessage({
              id: "documentLibraryListPage.docDetails.tabs.linkedItems.title",
            }),
            controlType: "single_selection",
            clearSelection: () => {
              mergeFilters({
                tradeItemId: undefined,
                siteId: undefined,
                projectId: undefined,
              });
            },
            elementToRender: (
              <div className={styles.documentSearchLinks}>
                <AsyncSearchSelect
                  allowClear
                  showSearch
                  searchValue={searchSiteValue}
                  onSearch={value => {
                    setSearchSiteValue(value);
                  }}
                  minLengthToSearch={3}
                  defaultOptions={sites}
                  getPopupContainer={trigger => trigger.parentElement}
                  value={filterObj.siteId}
                  onAsyncSearch={({ searchValue }) => {
                    return SmdAPI.getRelationsByFilters(
                      {
                        ownerCompanyIdCollection: filterObj.ownerCompanyId
                          ? [filterObj.ownerCompanyId]
                          : undefined,
                      },
                      {
                        associatedCompanyId: userOwningCompanyId,
                        searchText: searchValue,
                        ownerCompanyRelationStatus: true,
                      }
                    ).then(({ data }) => {
                      return data.sites.map(c => ({
                        value: c.id,
                        name: c.siteName,
                        companyId: c.ownerCompanyId,
                      }));
                    });
                  }}
                  onChange={siteId => {
                    mergeFilters({
                      siteId,
                    });
                  }}
                  placeholder={formatMessage({
                    id: "documentLibraryListPage.filterComponent.selectSite",
                  })}
                  data-test-id="md-documentFilter-site"
                />
                {projectAccess && (
                  <AsyncSearchSelect
                    allowClear
                    showSearch
                    defaultOptions={products}
                    searchValue={searchProductValue}
                    onSearch={value => {
                      setSearchProductValue(value);
                    }}
                    minLengthToSearch={3}
                    getPopupContainer={trigger => trigger.parentElement}
                    value={filterObj.tradeItemId}
                    onChange={tradeItemId => {
                      mergeFilters({
                        tradeItemId,
                      });
                    }}
                    placeholder={formatMessage({
                      id: "documentLibraryListPage.filterComponent.selectProduct",
                    })}
                    data-test-id="md-documentFilter-tradeItem"
                    onAsyncSearch={({ searchValue }) => {
                      return PmdAPI.getTradeItemsByFilters(
                        {
                          searchText: searchValue,
                          tradeItemStatuses: [TradeItemStatus.ACTIVE],
                          supplierCompanyIds: filterObj.ownerCompanyId
                            ? [filterObj.ownerCompanyId]
                            : undefined,
                        },
                        {
                          ownerCompanyId: userOwningCompanyId,
                        }
                      ).then(({ data }) => {
                        return data.tradeItems.map(c => ({
                          value: c.id,
                          name: `${c.itemName}${c.gtin ? ", " + c.gtin : ""}`,
                        }));
                      });
                    }}
                  />
                )}
                {projectAccess && (
                  <Select
                    allowClear
                    showSearch
                    options={projects}
                    getPopupContainer={trigger => trigger.parentElement}
                    value={filterObj.projectId}
                    onChange={projectId => {
                      mergeFilters({
                        projectId,
                      });
                    }}
                    placeholder={formatMessage({
                      id: "documentLibraryListPage.filterComponent.selectProject",
                    })}
                    data-test-id="md-documentFilter-project"
                    filterOption={(input, option) =>
                      (option?.name ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                )}
              </div>
            ),
            filterId: "5",
          },
        ]
      : []),
  ];

  return (
    <div>
      <Filter
        filters={filters}
        selectedFilters={selectedFilters}
        clearAllFilters={() => {
          onClearFiltersClick();
        }}
        search={{
          defaultValue: initialSearchValue,
          placeholder: formatMessage({
            id: `documentLibraryListPage.filterComponent.SearchLabel`,
          }),
          onSearch: query => {
            onSearch(query);
          },
        }}
        onAllFilterPanelToggle={() => {
          showIntercom.current = !showIntercom.current;
          adjustIntercomButton(showIntercom.current);
        }}
      />
    </div>
  );
};

export default DocumentSearch;
