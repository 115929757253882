import { createAsyncThunk } from "@reduxjs/toolkit";

import { CumdAPI, RlmdAPI } from "apis";
import { Languages } from "translations";

import { ApplicationTypes } from "shared/constants";

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async ({
    userId,
    companyActivityId,
  }: {
    userId: string;
    companyActivityId: string;
  }) => {
    const [user, companyActivityNameInEn] = await Promise.all([
      CumdAPI.getUserById(userId).then(({ data }) => data),
      // We want company activity name as user property in HEAP
      RlmdAPI.getReferenceListItemsByReferenceListName("Company_Activity", {
        languageCode: Languages.ENGLISH,
      }).then(
        ({ data }) =>
          data.find(({ itemCode }) => itemCode === companyActivityId)?.text
      ),
    ]);

    return { user, companyActivityNameInEn };
  }
);

export const fetchUserApplications = createAsyncThunk(
  "user/fetchUserApplications",
  async () => {
    const {
      data: { userApplications },
    } = await CumdAPI.getUserApplications();
    return userApplications;
  }
);

export const fetchUseGroupList = createAsyncThunk(
  "user/fetchUseGroupList",
  async ({
    userId,
    applicationTypeId,
  }: {
    userId: string;
    applicationTypeId: number;
  }) => {
    const { data } = await CumdAPI.getCompanyGroupList(userId, {
      applicationTypeId,
    });
    return data;
  }
);

export const fetchSharedRolesByUserId = createAsyncThunk(
  "user/fetchSharedRolesByUserId",
  async ({ userId, companyId }: { userId: string; companyId: string }) => {
    const { data } = await CumdAPI.searchSharedRolesByUserId(userId, {
      companyId,
      applicationTypeId: ApplicationTypes.MASTER_DATA,
    });
    return data[0];
  }
);

export const fetchUserApplicationsCompanies = createAsyncThunk(
  "user/fetchUserApplicationsCompanies",
  async (companyIds: string[]) => {
    const { data } = await CumdAPI.getCompaniesByCompanyIds({ companyIds });
    return data;
  }
);
