import React, { useContext } from "react";
import Sidebar from "./Sidebar";
import SidebarSearch from "./SidebarSearch";
import SidebarHeader from "./SidebarHeader";
import { SidebarLayoutProps } from "../types";
import "./styles.less";
import { SidebarContext } from "../context/SidebarContextProvider";
import { getPrefixClassName } from "src/utils/general";
import Footer from "./Footer";

const SidebarLayout: React.FC<SidebarLayoutProps> = ({
  sideBarHeaderText,
  footerProps,
  showSearchBox,
  showHeaderText,
  sidebarSectionData,
  handleSearchChange,
}) => {
  const prefixCls = getPrefixClassName("module-sidebar-navigator");

  const { isMinified } = useContext(SidebarContext);
  return (
    <div className={isMinified ? `${prefixCls}-sideMenuBarMinifed` : `${prefixCls}-sideMenuBar`} data-test-id = "newSidebarContent">
      <div className={`${prefixCls}-moduleSideBarMenuHeader`} data-test-id="moduleSideBarMenuHeader">
        <SidebarHeader sideBarHeaderText={sideBarHeaderText} showHeaderText = {showHeaderText}/>
        {showSearchBox && <SidebarSearch handleChange={handleSearchChange}/>}
      </div>
      <div data-test-id="moduleSideBarMenuBody">
        <Sidebar sidebarSectionData={sidebarSectionData} />
      </div>
      <div data-test-id="moduleSideBarMenuFooter">
        {footerProps?.showFooter && <Footer {...footerProps} />}
      </div>
    </div>
  );
};

export default SidebarLayout;
