import { useSelector } from "react-redux";
import { Switch, Redirect } from "react-router-dom";

import usePermissions from "core/oidc/usePermissions";

import { selectIsRetailer } from "reduxStore/oidc/selectors";

import AuthorizedRoute from "components/AuthorizedRoute";
import RouteIfNotFound from "components/RouteIfNotFound";

import ProductCreate from "./pages/ProductCreate";
import ProductDetails from "./pages/ProductDetails";
import ProductEdit from "./pages/ProductEdit";
import ProductHistory from "./pages/ProductHistory";
import ProductList from "./pages/ProductList";
import ProductRetailerDetails from "./pages/ProductRetailerDetails";
import ProductSupplierDetails from "./pages/ProductSupplierDetails";

const ProductsRouting: React.FC = () => {
  const { masterdataMyProducts, productsRead, productsWrite, productsUpdate } =
    usePermissions();
  const isRetailer = useSelector(selectIsRetailer);

  return (
    <Switch>
      <AuthorizedRoute
        path="/products/list"
        component={ProductList}
        canActive={
          isRetailer
            ? masterdataMyProducts
            : productsRead && window.env.SUPPLIER_PRODUCT === "true"
        }
        exact
      />
      <AuthorizedRoute
        path="/products/create"
        component={ProductCreate}
        canActive={masterdataMyProducts && productsWrite}
        exact
      />
      <AuthorizedRoute
        path="/products/edit/:productId"
        // canActive={masterdataMyProducts && (productsWrite || productsUpdate)}
        // TODO will need to changed when DetailPage will be available
        canActive={
          masterdataMyProducts &&
          (productsWrite || productsUpdate || productsRead)
        }
        exact
      >
        <ProductEdit
          // TODO Remove it
          isReadOnly={productsRead && !productsWrite && !productsUpdate}
        />
      </AuthorizedRoute>
      <AuthorizedRoute
        path="/products/:productId"
        canActive={
          isRetailer
            ? masterdataMyProducts && productsRead
            : productsRead && window.env.SUPPLIER_PRODUCT === "true"
        }
        exact
      >
        {isRetailer ? <ProductDetails /> : <ProductSupplierDetails />}
      </AuthorizedRoute>
      <AuthorizedRoute
        path="/products/history/:productId"
        component={ProductHistory}
        canActive={masterdataMyProducts && productsRead}
        exact
      />
      <AuthorizedRoute
        path="/products/manufacturedItem/:productId/:manufacturedItemId"
        component={ProductRetailerDetails}
        canActive={masterdataMyProducts && productsRead}
        exact
      />
      <Redirect from="/products/" to="/products/list" exact />
      <RouteIfNotFound />
    </Switch>
  );
};

export default ProductsRouting;
