import React from "react";
import { Button, Tooltip, Typography } from "@trace-one/design-system";
import { SectionNameProps } from "./types";
import "./styles.less";
const SectionName: React.FC<SectionNameProps> = ({
  sectionName,
  onClick,
  isSelected,
  disabled,
}) => {
  const buttonProps = {
    "data-test-id": "menu-item-section-name",
    type: "link",
    linkColor: isSelected ? "secondary-dark" : "grey-5",
    onClick,
    disabled,
  };

  return (
    <>
      {sectionName?.length > 17
        ? (
        <Tooltip
          text={sectionName}
          zIndex={6}
          getPopupContainer={trigger => trigger.parentNode}
        >
          <Button {...buttonProps}>{sectionName.substring(0, 17)}...</Button>
        </Tooltip>
          )
        : (
          <Button {...buttonProps}>
            <Typography className="subsection-name" variant="body-regular">
              {sectionName}
            </Typography>
          </Button>
          )}
    </>
  );
};

export default SectionName;
