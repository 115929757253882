import { AxiosInstancesManager } from "src/configs/AxiosInstancesManager";
import {
  UserData,
  CompanyConfigurationsData,
  TimeZonesData,
  UserApplication,
} from "../../models";
import { isListEmpty } from "../../utils/general";
import { CompanyDetailsData, CompanyGroupList, CompanyGroup } from "../../models/companyDetails";
import { AxiosRequestConfig } from "axios";
import {
  ParamsWithPagination,
  CompanyParams,
  Pagination,
  Company,
} from "./types";

export const getCompaniesByFilters = (
  data?: {
    companyIds?: string[];
    applicationTypeIds?: number[];
  },
  params?: ParamsWithPagination<CompanyParams>,
  config?: AxiosRequestConfig
) => {
  const cumdInstance = AxiosInstancesManager.getCumd();

  return cumdInstance.post<Pagination<"companies", Company[]>>(
    `/api/companies/filters`,
    data,
    { params, ...config }
  );
};

export const getUser = ({ userId }: { userId: string }) => {
  const cumdInstance = AxiosInstancesManager.getCumd();

  return cumdInstance.get<UserData>(`/api/users/${userId}`);
};

export const getCompanyGroupList = ({ userId, applicationTypeId }: { userId: string, applicationTypeId: number }) => {
  const cumdInstance = AxiosInstancesManager.getCumd();

  return cumdInstance.get<CompanyGroupList[]>(`/api/users/${userId}/companygroup/companies`, {
    params: { applicationTypeId },
  });
};

export const getCompanyGroupById = (id: string) => {
  const cumdInstance = AxiosInstancesManager.getCumd();

  return cumdInstance.get<CompanyGroup>(`/api/companygroup/${id}`);
};

export const updateUserLanguage = ({
  userId,
  languageCode,
}: {
  userId: string;
  languageCode: string;
}) => {
  const cumdInstance = AxiosInstancesManager.getCumd();

  return cumdInstance.patch(`api/users/${userId}/language`, {
    newLanguagePreference: languageCode,
    userIdType: "global_user_id",
  });
};

export const updateUserEmail = ({
  userId,
  newEmail,
}: {
  userId: string;
  newEmail: string;
}) => {
  const cumdInstance = AxiosInstancesManager.getCumd();

  return cumdInstance.patch(`api/users/${userId}/email`, {
    newEmail,
  });
};

export const fetchUserDetails = ({ userIds }: { userIds: string[] }) => {
  const cumdInstance = AxiosInstancesManager.getCumd();

  if (!isListEmpty(userIds)) {
    return cumdInstance.post<UserData[]>(`/api/users/collections`, {
      userIds,
    });
  }
};

export const updateUserProfile = ({
  userId,
  newNotificationEmail,
  newTimeZone,
  newCountry,
}: {
  userId: string;
  newNotificationEmail?: string;
  newTimeZone?: string;
  newCountry?: string;
}) => {
  const cumdInstance = AxiosInstancesManager.getCumd();

  return cumdInstance.patch(`api/users/${userId}/profile`, {
    newNotificationEmail,
    newTimeZone,
    newCountry,
  });
};

export const getCompanyConfigurations = (companyId: string) => {
  const cumdInstance = AxiosInstancesManager.getCumd();

  return cumdInstance.get<CompanyConfigurationsData>(
    `/api/companies/${companyId}/configurations`
  );
};

export const getCompanyDetails = (companyId: string) => {
  const cumdInstance = AxiosInstancesManager.getCumd();

  return cumdInstance.get<CompanyDetailsData>(`/api/companies/${companyId}/`);
};

export const getTimeZones = () => {
  const cumdInstance = AxiosInstancesManager.getCumd();

  return cumdInstance.get<TimeZonesData>(`/api/timezones`);
};

export const getUserApplications = () => {
  const cumdInstance = AxiosInstancesManager.getCumd();
  return cumdInstance.get<{ userApplications?: UserApplication[] }>(
    "/api/users/applications/v2"
  );
};
