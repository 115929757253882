import React, { useEffect, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { CertificateModal } from "@trace-one/business-components";
import { Heading, TagV1 as Tag, toaster } from "@trace-one/design-system";
import { NET_CONTENT_PARENT_ID_FOR_PRODUCT_FORM } from "pages/Products/constants";
import { compose } from "redux";

import { useAppDispatch } from "reduxStore";
import { fetchSupplierProduct } from "reduxStore/productDetails/asyncActions";
import { selectSupplierProductDetailsData } from "reduxStore/productDetails/selectors";
import { clearProductDetails } from "reduxStore/productDetails/slice";
import { setBackButtonData } from "reduxStore/shared/slice";
import { selectUserLanguageCode } from "reduxStore/user/selectors";

import ErrorPage from "components/ErrorPage";
import HeaderDetails from "components/HeaderDetails";
import Spinner from "components/Spinner";
import { ManufacturingItemStatus } from "shared/constants";
import {
  withCountries,
  withCustomProductTypes,
  withLanguages,
  withNetContentMeasures,
} from "shared/hocs";

import { PmdAPI } from "../../../../apis";

import ProductCertificatesTab from "./components/ProductCertificatesTab";
import ProductInformationTab from "./components/ProductInformationTab";

const enhance = compose<React.FC>(
  withCountries(),
  withNetContentMeasures({
    includeParentItems: true,
    parentItemId: NET_CONTENT_PARENT_ID_FOR_PRODUCT_FORM,
  }),
  withCustomProductTypes(),
  withLanguages()
);

const ProductSupplierDetails = ({ isInitiated }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { productId } = useParams<{ productId?: string }>();
  const {
    productSupplierData: {
      data: productData,
      isLoading: isProductLoading,
      hasError,
      errorStatus,
    },
  } = useSelector(selectSupplierProductDetailsData);
  const languageCode = useSelector(selectUserLanguageCode);

  const [isCertificationModalOpen, setIsCertificationModalOpen] =
    useState<boolean>(false);

  const handleModalClose = () => {
    setIsCertificationModalOpen(false);
  };

  const productIsDisabled =
    productData.manufacturedItemStatus === ManufacturingItemStatus.INACTIVE;

  const refetchProduct = useCallback(() => {
    dispatch(fetchSupplierProduct({ id: productId, languageCode }));
  }, [productId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearProductDetails());
    };
  }, []);

  useEffect(() => {
    refetchProduct();
  }, [refetchProduct]);

  useEffect(() => {
    if (productIsDisabled) {
      dispatch(
        setBackButtonData({
          fromUrl: `/products/list`,
        })
      );
    }
  }, [productIsDisabled]);

  const tabList = [
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "general.generalInformation" })}
        </Heading>
      ),
      content: <ProductInformationTab />,
    },
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "general.classificates" })}
        </Heading>
      ),
      content: <ProductCertificatesTab />,
    },
  ];

  if (hasError) return <ErrorPage status={errorStatus} />;
  if (!isInitiated || isProductLoading) return <Spinner underHeader />;

  const ProductStatusTag = () => {
    if (
      productData.manufacturedItemStatus === ManufacturingItemStatus.INACTIVE
    ) {
      return (
        <Tag
          label={intl.formatMessage({ id: "general.archived" })}
          color="grey"
          mode="light"
        />
      );
    }

    return null;
  };

  const buttonList = [
    {
      text: "general.actions",
      showBtn: true,
      testId: "product-details-actions-btn",
      tab: 0,
      actionList: [
        {
          name: "supplierListPage.table.actions.createCertificate",
          "data-test-id": "product-supplier-details-createCertificate",
          onClick: () => setIsCertificationModalOpen(true),
          showBtn: true,
          disabled:
            productData.manufacturedItemStatus ===
            ManufacturingItemStatus.INACTIVE,
        },
      ],
    },
  ];
  const handleCertificateCreatedInProduct = async (certificateId: string) => {
    const manufacturedItemId = productData?.id;
    const manufacturedItemName = productData?.manufacturedItemName;

    try {
      await PmdAPI.addCertificateToManufaturedItem(manufacturedItemId, {
        certificateId,
      });

      toaster.open({
        message: intl.formatMessage({ id: "toast.confirmation" }),
        description: intl.formatMessage(
          {
            id: "productsSupplierListPage.successToast.text",
          },
          {
            manufacturedItemName: manufacturedItemName,
          }
        ),
        type: "confirmation",
      });

      refetchProduct();
    } catch (error) {
      toaster.open({
        message: intl.formatMessage({ id: "toast.alert" }),
        description: intl.formatMessage(
          {
            id: "productsSupplierListPage.errorToast.text",
          },
          {
            manufacturedItemName: manufacturedItemName,
          }
        ),
        type: "alert",
      });
    }
  };

  return (
    <>
      <div>
        <HeaderDetails
          withBackNav
          title={
            productData.manufacturedItemName ||
            productData.tradeItemData?.itemName
          }
          subtitle={<></>}
          status={<ProductStatusTag />}
          tabs={tabList}
          headerIcon="product-add"
          haveActionItems
          buttons={buttonList}
        />
      </div>
      <CertificateModal
        open={isCertificationModalOpen}
        objectLink="product"
        useAI={true}
        onClose={handleModalClose}
        onCertificateCreated={handleCertificateCreatedInProduct}
      />
    </>
  );
};

export default enhance(ProductSupplierDetails);
