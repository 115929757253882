import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading, Paragraph, Typography } from "@trace-one/design-system";

import { selectSupplierProductDetailsData } from "reduxStore/productDetails/selectors";
import {
  selectLanguagesData,
  selectNetContentMeasuresData,
  selectCustomProductTypes,
  selectCountriesOptions,
} from "reduxStore/shared/selectors";

import Label from "components/Label";
import Panel from "components/Panel";
import TabPage from "components/TabPage";
import { capitalizeText } from "shared/utils/capitalizeFullText";

import styles from "./ProductIdentification.module.less";

const CategoryLine = category => {
  const categoryLine = [
    category.parent4CategoryItemName &&
      `${category.parent4CategoryItemName} > `,
    category.parent3CategoryItemName &&
      `${category.parent3CategoryItemName} > `,
    category.parent2CategoryItemName &&
      `${category.parent2CategoryItemName} > `,
    category.parent1CategoryItemName &&
      `${category.parent1CategoryItemName} > `,
    category.categoryItemName,
  ].join("");

  return categoryLine;
};

const MainInformation = () => {
  const intl = useIntl();
  const {
    productSupplierData: { data: productData },
  } = useSelector(selectSupplierProductDetailsData);
  const { data: customProductTypes } = useSelector(selectCustomProductTypes);
  const countriesList = useSelector(selectCountriesOptions);
  const { data: netContentMeasures } = useSelector(
    selectNetContentMeasuresData
  );

  const languagesData = useSelector(selectLanguagesData)?.data;

  const languagesMap = languagesData?.reduce<{ [itemCode: string]: string }>(
    (prev, current) => ({
      ...prev,
      [current.itemCode]: current.text,
    }),
    {}
  );
  const netContentLabel = ({ value, unitOfMeasure }) => {
    const text = netContentMeasures?.find(
      ({ id }) => id === unitOfMeasure
    )?.text;
    return `${value} ${text}`;
  };

  return (
    <div className={styles.infoWrapper}>
      <Panel>
        <TabPage.SecondaryTitle
          label={
            <Heading size="xs">
              {capitalizeText(
                intl.formatMessage({ id: "productDetails.productInformation" })
              )}
            </Heading>
          }
        />

        <Label
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "productDetails.netContents" })}
            </Heading>
          }
          testId="prodDetails-netContents"
        >
          <Paragraph size="m">
            {productData.tradeItemData?.netContents?.map(
              ({ value, unitOfMeasure }, idx) => {
                const label = netContentLabel({ value, unitOfMeasure });
                return <div key={label}>{label}</div>;
              }
            )}
          </Paragraph>
        </Label>

        <Label
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "productDetails.GTIN" })}
            </Heading>
          }
          testId="prodDetails-GTIN"
        >
          <Paragraph size="m">{productData.tradeItemData?.gtin} </Paragraph>
        </Label>

        {productData.tradeItemData?.customProductTypeId && (
          <Label
            label={
              <Heading size="xxs">
                {intl.formatMessage({ id: "productDetails.customProductType" })}
              </Heading>
            }
            testId="prodDetails-customProductType"
          >
            <Paragraph size="m">
              {
                customProductTypes?.find(
                  c => c.id === productData.tradeItemData?.customProductTypeId
                )?.text
              }{" "}
            </Paragraph>
          </Label>
        )}

        <Label
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "productDetails.productCategory" })}
            </Heading>
          }
          testId="prodDetails-productCategory"
        >
          <Paragraph size="m">
            {(() => {
              const categoryLines = productData.categories
                ?.map(category => CategoryLine(category))
                .sort(function (a, b) {
                  return a.localeCompare(b);
                });
              return categoryLines?.map(category => (
                <Typography key={category} variant="body-regular">
                  {category}
                </Typography>
              ));
            })()}
          </Paragraph>
        </Label>

        {productData.tradeItemData?.productLanguage !== null && (
          <Label
            label={
              <Heading size="xxs">
                {intl.formatMessage({
                  id: "productForm.productIdentification.productLanguage",
                })}
              </Heading>
            }
            testId="prodDetails-productLanguage"
          >
            <Paragraph size="m">
              <Paragraph size="m">
                {languagesMap[productData.tradeItemData?.productLanguage]}
              </Paragraph>
            </Paragraph>
          </Label>
        )}

        <Label
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "productDetails.baseUnit" })}
            </Heading>
          }
          testId="prodDetails-baseUnit"
        >
          <Paragraph size="m">
            {productData.tradeItemData?.isTradeItemBaseUnit !== null && (
              <FormattedMessage
                id={
                  productData.tradeItemData?.isTradeItemBaseUnit
                    ? "general.yes"
                    : "general.no"
                }
              />
            )}
          </Paragraph>
        </Label>

        {productData.tradeItemData?.multipack !== null && (
          <Label
            label={
              <Heading size="xxs">
                {intl.formatMessage({
                  id: "productForm.productIdentification.multiPack",
                })}
              </Heading>
            }
            testId="prodDetails-multipack"
          >
            <Paragraph size="m">
              {
                <FormattedMessage
                  id={
                    productData.tradeItemData?.multipack
                      ? "general.yes"
                      : "general.no"
                  }
                />
              }
            </Paragraph>
          </Label>
        )}

        <Label
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "productDetails.consumerUnit" })}
            </Heading>
          }
          testId="prodDetails-consumerUnit"
        >
          <Paragraph size="m">
            {productData.tradeItemData?.isConsumerUnit !== null && (
              <FormattedMessage
                id={
                  productData.tradeItemData?.isConsumerUnit
                    ? "general.yes"
                    : "general.no"
                }
              />
            )}
          </Paragraph>
        </Label>

        {
          <Label
            label={
              <Heading size="xxs">
                {intl.formatMessage({ id: "productDetails.countriesOfOrigin" })}
              </Heading>
            }
            testId="prodDetails-countriesOfOrigin"
          >
            <Paragraph size="m">
              {productData.countriesOfOrigin
                ?.map(
                  countryCode =>
                    countriesList.find(c => c.value === countryCode)?.name
                )
                .join(", ")}
            </Paragraph>
          </Label>
        }
      </Panel>
    </div>
  );
};

export default MainInformation;
