import React, { useState } from "react";
import { useIntl } from "react-intl";

import { CertificateModal } from "@trace-one/business-components";
import { toaster } from "@trace-one/design-system";
import { Menu } from "antd";

import { ManufacturingItemStatus } from "shared/constants";

import { PmdAPI } from "../../../../../../apis";
import ActionDropdown from "../../../../../../components/ActionDropdown";
import { ManufacturedItemColumn } from "../models";

interface ProductActionProps {
  record: ManufacturedItemColumn;
}

const ProductAction: React.FC<ProductActionProps> = ({ record }) => {
  const intl = useIntl();

  const [isCertificationModalOpen, setIsCertificationModalOpen] =
    useState<boolean>(false);

  const handleModalClose = () => {
    setIsCertificationModalOpen(false);
  };

  const handleCertificateCreatedInProduct = async (certificateId: string) => {
    const manufacturedItemId = record?.key;
    const manufacturedItemName = record?.itemName;

    try {
      await PmdAPI.addCertificateToManufaturedItem(manufacturedItemId, {
        certificateId,
      });

      toaster.open({
        message: intl.formatMessage({ id: "toast.confirmation" }),
        description: intl.formatMessage(
          {
            id: "productsSupplierListPage.successToast.text",
          },
          {
            manufacturedItemName: manufacturedItemName,
          }
        ),
        type: "confirmation",
      });
    } catch (error) {
      toaster.open({
        message: intl.formatMessage({ id: "toast.alert" }),
        description: intl.formatMessage(
          {
            id: "productsSupplierListPage.errorToast.text",
          },
          {
            manufacturedItemName: manufacturedItemName,
          }
        ),
        type: "alert",
      });
    }
  };

  return (
    <>
      <ActionDropdown
        data-test-id="md-product-action-create-certificate"
        overlay={
          <Menu>
            <Menu.Item
              key="create-certificate"
              onClick={() => setIsCertificationModalOpen(true)}
              disabled={record?.status === ManufacturingItemStatus.INACTIVE}
            >
              {intl.formatMessage({
                id: "supplierListPage.table.actions.createCertificate",
              })}
            </Menu.Item>
          </Menu>
        }
      />
      )
      <CertificateModal
        open={isCertificationModalOpen}
        objectLink="product"
        useAI={true}
        onClose={handleModalClose}
        onCertificateCreated={handleCertificateCreatedInProduct}
      />
    </>
  );
};

export default ProductAction;
