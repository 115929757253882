import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Button,
  Graphic,
  Heading,
  Typography,
  Tooltip,
  Icon,
} from "@trace-one/design-system";
import { format } from "date-fns";

import { DmsAPI } from "apis";
import { DocumentsListDataItem } from "models";

import { selectSupplierDetails } from "reduxStore/SupplierDetails/selectors";

import Panel from "components/Panel";
import TabPage from "components/TabPage";
import { capitalizeText } from "shared/utils/capitalizeFullText";
import { downloadBlobAsFile } from "shared/utils/downloadBlobAsFile";

import styles from "../../SupplierDetails.module.less";

const RecentDocuments = ({
  documents,
}: {
  documents: DocumentsListDataItem[];
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { company } = useSelector(selectSupplierDetails);

  const downloadFile = async (
    fileId: string,
    versionId: string,
    fileName: string
  ) => {
    try {
      const result = await DmsAPI.downloadDocument(
        fileId,
        { versionId, fileName } as any,
        {
          responseType: "blob",
        }
      );

      if (!result?.data) {
        return;
      }

      downloadBlobAsFile(result.data, fileName);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Panel>
      <TabPage.SecondaryTitle
        label={
          <Heading data-test-id="recent-documents-header" size="xs">
            {capitalizeText(
              intl.formatMessage({
                id: "productDetails.recentDocuments",
              })
            )}
          </Heading>
        }
      />
      <ul className={styles.recentDocumentsSection}>
        {documents.slice(0, 6).map(doc => {
          let lastIndex = doc.fileName?.lastIndexOf(".");
          const fileType =
            lastIndex &&
            doc.fileName
              .substring(lastIndex + 1)
              .trim()
              .toLowerCase();
          const graphicName = fileType + "-document";
          return (
            <li key={doc.fileId} data-test-id={doc.fileId + "-filedetail"}>
              <div className={styles.leftDocDetails}>
                <Graphic name={graphicName} size="small" />
                <Tooltip placement="top" text={doc.fileName}>
                  <Typography className={styles.docName} component="span">
                    {doc.fileName}
                  </Typography>
                </Tooltip>
              </div>
              <div className={styles.rightDocDetails}>
                <Typography className={styles.docDate} component="span">
                  {format(new Date(doc.uploadedAt), "dd/MM/yyyy")}
                </Typography>
                <Icon
                  data-test-id={doc.fileName + "-fileDownload"}
                  onClick={() =>
                    downloadFile(doc?.fileId, doc?.versionId, doc?.fileName)
                  }
                  className={styles.downloadIcon}
                  name="download-computer"
                  size="small"
                  color="primary"
                />
              </div>
            </li>
          );
        })}
      </ul>
      <Button
        type="link"
        className={styles.iconBtn}
        data-test-id="see-all-documents"
        onClick={() => {
          history.push({
            pathname: `/documents/list`,
            search: `?supplierId=${company.companyId}`,
          });
        }}
      >
        {intl.formatMessage({
          id: "productDetails.seeAllDocuments",
        })}
      </Button>
    </Panel>
  );
};

export default RecentDocuments;
