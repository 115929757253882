import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { CompanySettings } from "@trace-one/api-clients.pmd/dist/models/company-settings";
import { Heading, Container } from "@trace-one/design-system";

import { PmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import { fetchLanguages } from "reduxStore/shared/asyncActions";
import {
  selectUserCompanyGroupList,
  selectUserLanguageCode,
  selectUserOwningCompanyId,
} from "reduxStore/user/selectors";

import BtnCreateListItem from "components/BtnCreateListItem";
import CompanyHeader from "components/CompanyHeader";
import useToast from "shared/hooks/useToast";

import { ErrorCode } from "../../../../shared/errors";

import BrandTable from "./BrandTable";
import styles from "./BrandTable/BrandTable.module.less";

const BrandList: React.FC = () => {
  const intl = useIntl();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { brandsWrite } = usePermissions();
  const languageCode = useSelector(selectUserLanguageCode);
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const groupList = useSelector(selectUserCompanyGroupList);
  const [isCreateBrandOpen, setIsCreateBrandOpen] = useState<boolean>(false);
  const [companySettings, setCompanySettings] = useState<CompanySettings>({});

  const handleFetchCompanySettings = async () => {
    try {
      const companySettings = await PmdAPI.getCompanySettings(ownerCompanyId);
      setCompanySettings(companySettings.data);
    } catch (error) {
      if (
        error.response?.data.errorCode === ErrorCode.COMPANY_SETTINGS_NOT_FOUND
      ) {
        return null;
      } else {
        toast.saveError({ error });
      }
    }
  };

  useEffect(() => {
    dispatch(fetchLanguages({ languageCode }));
    handleFetchCompanySettings();
  }, []);

  return (
    <Container isPageWrapper>
      <div className={styles.mainContainer}>
        <div>
          {!!groupList.length && <CompanyHeader />}
          <Heading size="l">
            {intl.formatMessage({
              id: "brandList.title",
            })}
          </Heading>
        </div>
        <BtnCreateListItem
          hideButton={!brandsWrite}
          text={intl.formatMessage({ id: "brandList.createBrand" })}
          onClick={() => setIsCreateBrandOpen(true)}
          iconName="brand-add"
        />
      </div>
      <BrandTable
        isCreateBrandOpen={isCreateBrandOpen}
        setIsCreateBrandOpen={setIsCreateBrandOpen}
        companySettings={companySettings}
      />
    </Container>
  );
};

export default BrandList;
