import { defineMessages } from "react-intl";

export const askAppAccessMessages = defineMessages({
  loggedInAs: {
    id: "shared.business.CompanySwitcher.loggedInAs",
    defaultMessage: "Logged on",
  },
  logBackAs: {
    id: "shared.business.CompanySwitcher.logBackAs",
    defaultMessage: "Log back on",
  },
  linkedTo: {
    id: "shared.business.CompanySwitcher.linkedTo",
    defaultMessage: "Part of",
  },
});
