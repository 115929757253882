import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { CertificateCard, toaster } from "@trace-one/design-system";
import moment from "moment/moment";

import { CertificationAPI, DmsAPI } from "../../../../../../../apis";
import { selectUserLanguageCode } from "../../../../../../../reduxStore/user/selectors";
import { downloadBlobAsFile } from "../../../../../../../shared/utils/downloadBlobAsFile";
import styles from "../styles.module.less";
interface CertificatesSectionProps {
  productData?: any;
}

interface CertificateInfo {
  id?: string;
  fileId?: string;
  certificationId?: string;
  companyId?: string;
  comment?: string;
  grade?: string;
  validity?: {
    validFrom?: string;
    validTo?: string;
    state?: string;
    stateLabel?: string;
  };
  certification?: {
    id?: string;
    name?: string;
    logoFileId?: string;
    owner?: string;
    domains?: string[];
  };
}
const CertificatesSection: React.FC<CertificatesSectionProps> = ({
  productData,
}) => {
  const intl = useIntl();
  const languageCode = useSelector(selectUserLanguageCode);

  const [certificates, setCertificates] = useState<CertificateInfo[]>([]);
  const certificateIds = productData?.certificateIds;
  const [thumbnails, setThumbnails] = useState<string[]>([]);

  const handleFetchCertificateDetails = async () => {
    try {
      const fetchedCertificates = await Promise.all(
        certificateIds.map(async (certificateId: string) => {
          const response = await CertificationAPI.getCertificateDetails(
            certificateId,
            { languageCode, includeCertification: true }
          );
          return response.data;
        })
      );

      const sortedCertificates = fetchedCertificates.sort((a, b) => {
        const dateA = new Date(a.validity?.validTo);
        const dateB = new Date(b.validity?.validTo);

        return dateB.getTime() - dateA.getTime();
      });

      setCertificates(sortedCertificates);
    } catch (error) {
      toaster.open({
        description: intl.formatMessage({
          id: "supplierListPage.table.disabledErrorText",
        }),
        type: "alert",
      });
    }
  };

  useEffect(() => {
    if (certificateIds?.length > 0) {
      handleFetchCertificateDetails();
    }
  }, [certificateIds]);

  const fetchThumbnails = async (certificates: CertificateInfo[]) => {
    try {
      const thumbnailsArray: string[] = await Promise.all(
        certificates.map(async certificate => {
          const fileId = certificate?.certification?.logoFileId;

          if (fileId) {
            try {
              const response = await DmsAPI.downloadThumbnail(
                fileId,
                {},
                {
                  responseType: "blob",
                }
              );

              if (response && response.data && response.data instanceof Blob) {
                const thumbnailUrl = window.URL.createObjectURL(response.data);

                return thumbnailUrl;
              } else {
                return null;
              }
            } catch (error) {
              console.error(error);
              return null;
            }
          }
        })
      );

      setThumbnails(thumbnailsArray);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (certificates.length > 0) {
      fetchThumbnails(certificates);
    }
  }, [certificates]);
  const downloadDocument = async certificate => {
    const fileId = certificate?.fileId;
    const fileName = certificate?.certification?.name;

    try {
      const { data } = await DmsAPI.downloadDocument(
        fileId,
        {
          fileName: fileName,
        },
        {
          responseType: "blob",
        }
      );

      downloadBlobAsFile(data, fileName);
    } catch (error) {
      toaster.open({
        message: intl.formatMessage({ id: "toast.alert" }),
        description: intl.formatMessage({
          id: "general.uploadDragger.messages.download",
        }),
        type: "alert",
      });
    }
  };

  return (
    <div>
      <div className={styles.certificateCardsWrapper}>
        {certificates?.length !== 0 &&
          certificates?.map((certificate, index) => (
            <CertificateCard
              size="large"
              key={certificate?.id}
              ownerTitle={intl.formatMessage({ id: "general.owner" })}
              domainTitle={intl.formatMessage({ id: "general.domain" })}
              gradeTitle={intl.formatMessage({ id: "general.grade" })}
              commentTitle={intl.formatMessage({ id: "general.comment" })}
              certificationName={certificate?.certification?.name}
              certificationLogoFileId={thumbnails[index]}
              certificationOwner={certificate?.certification?.owner}
              certificationDomains={certificate?.certification?.domains}
              certificateGrade={certificate?.grade}
              certificateComment={certificate?.comment}
              validTo={moment(certificate?.validity?.validTo).format(
                "DD/MM/YYYY"
              )}
              state={certificate?.validity?.state}
              stateLabel={certificate?.validity?.stateLabel}
              hasActions={false}
              onDownload={() => downloadDocument(certificate)}
            />
          ))}
      </div>
    </div>
  );
};

export default CertificatesSection;
