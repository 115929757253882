/* eslint-disable no-loop-func */
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Heading, TagV1 as Tag, Tooltip } from "@trace-one/design-system";
import { usePreviousLocation } from "@trace-one/react-components";
import { SiteIdentification } from "pages/Sites/constants";
import { MenuItemType } from "rc-menu/lib/interface";

import { SmdAPI, RlmdAPI } from "apis";

import { useAppDispatch } from "reduxStore";
import { selectContactUserError } from "reduxStore/shared/selectors";
import {
  clearContactUserError,
  setContactUserError,
} from "reduxStore/shared/slice";
import {
  selectSiteSupplierFormData,
  selectSiteSupplierFormError,
} from "reduxStore/siteSupplierDetails/selectors";
import {
  setSiteSupplierFormData,
  clearSiteSupplierFormData,
  setSiteSupplierFormError,
  setSiteCertificateFormData,
} from "reduxStore/siteSupplierDetails/slice";
import { selectSiteRelations } from "reduxStore/siteSupplierForm/selectors";
import { selectUserLanguageCode } from "reduxStore/user/selectors";

import LeavingFormPrompt from "components/LeavingFormPrompt";
import NewFormWrapper from "components/NewFormWrapper";
import { ErrorCode } from "shared/errors";
import useAntForm from "shared/hooks/useAntForm";
import useToast from "shared/hooks/useToast";
import { FormItemFeedback } from "shared/typings";

import CertificateSection from "./components/CertificateSection";
import SupplierSiteContact from "./components/SupplierSiteContact";
import SupplierSiteInformation from "./components/SupplierSiteInformation";
import getFailedSubmitResult from "./utils/getFailedSubmitResult";
import prepareFormData from "./utils/prepareFormData";
import prepareInitialValues from "./utils/prepareInitialValues";

interface SiteFormProps {
  initialValues: any;
  isEditForm: boolean;
  isReadOnly: boolean;
}

const SiteForm: React.FC<SiteFormProps> = props => {
  const { initialValues = {}, isReadOnly, isEditForm } = props;

  const intl = useIntl();
  const history = useHistory();
  const toast = useToast();
  const dispatch = useAppDispatch();
  // Form handler.
  const { form, isFormDirty, setIsFormDirty } = useAntForm();

  const languageCode = useSelector(selectUserLanguageCode);
  const siteSupplierFormData = useSelector(selectSiteSupplierFormData);
  const siteSupplierFormError = useSelector(selectSiteSupplierFormError);
  const userContactError = useSelector(selectContactUserError);

  const siteRelationsSelector = useSelector(selectSiteRelations);
  const defaultTitle = intl.formatMessage({
    id: "siteForm.siteNamePlaceholder",
  });
  const [formTitle, setFormTitle] = useState(initialValues.siteName ?? "");
  const [activeTab, setActiveTab] = useState(null);
  const [relations, setRelations] = useState(useSelector(selectSiteRelations));
  const [certificateSublists, setCertificateSublists] = useState({});
  const [siteStatus, setSiteStatus] = useState(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [isArchiving, setIsArchiving] = useState(false);
  const [submitResult, setSubmitResult] = useState<{
    internalCode?: FormItemFeedback;
  }>({});

  let siteSuccessfullyCreated = false;
  let formSiteId = initialValues.id;
  let headerTitle: React.ReactNode;

  if (isEditForm) {
    headerTitle = formTitle || initialValues.siteName;
  } else {
    headerTitle = formTitle || defaultTitle;
  }

  const getUpdateData = async () => {
    const { data } = await SmdAPI.getSiteById(initialValues.id);
    dispatch(setSiteCertificateFormData(data.siteCertificates));
  };
  useEffect(() => {
    dispatch(setSiteSupplierFormData(initialValues));
    dispatch(setSiteSupplierFormError({ siteName: false, certificate: null }));
    if (initialValues.id) {
      getUpdateData();
    }
    return () => {
      dispatch(clearSiteSupplierFormData());
      dispatch(clearContactUserError({ userContact: null }));
    };
  }, []);

  useEffect(() => {
    const ids = initialValues.siteCertificates?.map(c => c.typeId) ?? [];
    fetchCertificateSubLists(ids);
    setSiteStatus(initialValues.siteActive);
  }, [initialValues]);

  const fetchCertificateSubLists = async (ids = []) => {
    const result = await Promise.all(
      ids
        .filter(
          (val, idx, arr) =>
            arr.indexOf(val) === idx && !certificateSublists[val]
        )
        .map(parentItemId =>
          RlmdAPI.getReferenceListItemsByReferenceListName("certification", {
            languageCode,
            parentItemId,
          }).then(({ data }) => ({
            parentItemId,
            data,
          }))
        )
    );

    if (result.length) {
      const sublist = certificateSublists;

      result.forEach(({ parentItemId, data }) => {
        sublist[parentItemId] = data;
      });

      const newSubList = { ...certificateSublists, ...sublist };
      setCertificateSublists(newSubList);
      return newSubList;
    }

    return { ...certificateSublists };
  };

  // Careful here, we mutate the data.
  const handleUploadFilesAndMutate = async values => {
    for (const index in values.siteCertificates) {
      const { fileManager } = values.siteCertificates[index];
      let { filesToAdd, attachments } = fileManager;

      if (
        filesToAdd.length ||
        attachments.length !== values.siteCertificates[index].attachments.length
      ) {
        attachments = [
          ...attachments,
          ...filesToAdd.map((data, i) => ({
            fileId: data.id,
            fileSize: data.size,
            fileName: data.name,
          })),
        ];
        const { siteCertificates } = values;
        const copyOfSiteCertificates = [...siteCertificates];
        let fields = {
          ...values,
          siteCertificates: copyOfSiteCertificates,
        };

        fields.siteCertificates[index] = {
          ...fields.siteCertificates[index],
          fileManager: {
            ...fileManager,
            filesToAdd: [],
            attachments,
          },
        };

        form.setFieldsValue(fields);
        fields.siteCertificates[index].attachments = attachments;
        let updatedSiteCertificates = fields.siteCertificates;
        values.siteCertificates = [...updatedSiteCertificates];
      }
    }
  };

  const handleSiteRelations = async (siteId, { siteRelations }) => {
    const { relationsToAdd, relationsToRemove } = siteRelations;

    const newRelations = relationsToAdd.filter(
      ({ associatedCompanyId }) =>
        relations.findIndex(
          r => r.associatedCompanyId === associatedCompanyId
        ) === -1
    );

    if (newRelations.length) {
      for (const { associatedCompanyId } of newRelations) {
        await SmdAPI.createSiteRelation(siteId, { associatedCompanyId });
      }
      // await Promise.all([
      //   ...newRelations.map(({ associatedCompanyId }) =>
      //     SmdAPI.createSiteRelation(siteId, { associatedCompanyId })
      //   ),
      // ]);

      setRelations([...relations, ...newRelations]);
    }

    await Promise.all([
      ...relationsToAdd.map(({ associatedCompanyId }) =>
        SmdAPI.addSiteRelation(siteId, associatedCompanyId)
      ),
      ...relationsToRemove
        .filter(r =>
          siteRelationsSelector.some(
            s =>
              s.associatedCompanyId === r.associatedCompanyId &&
              s.ownerCompanyRelationStatus === true
          )
        )
        .map(({ associatedCompanyId }) =>
          SmdAPI.deleteSiteRelation(siteId, associatedCompanyId)
        ),
    ]);
  };

  const handleCreationSite = async ({ fields }) => {
    const siteCertificates = form.getFieldValue("siteCertificates");
    const filterSiteCertificates = siteCertificates.filter(
      sc => sc.typeId || sc.certificationId || sc.certificateLabel
    );
    fields = {
      ...fields,
      siteCertificates: filterSiteCertificates,
      allergenData: undefined,
    };
    const {
      data: { siteId },
    } = await SmdAPI.createSite(prepareFormData({ fields }));

    formSiteId = siteId;
    siteSuccessfullyCreated = true;

    setIsFormDirty(false);
    const currentkey = ["siteRelations"];
    const siteRelations = form.getFieldValue(currentkey);
    const newFields = { ...fields, siteRelations };
    await handleSiteRelations(siteId, newFields);

    await handleUploadFilesAndMutate(fields);
    // We have to prepareFormData, because
    // handleUploadFilesAndMutate mutate values.
    await SmdAPI.updateSiteById(siteId, prepareFormData({ fields }));

    history.replace(`/sites/edit/${siteId}`, {
      hasPreviousLocation: !!previousLocation,
    });
  };

  const handleEditSite = async ({ fields }) => {
    const siteCertificates = form.getFieldValue("siteCertificates");
    const filterSiteCertificates = siteCertificates.filter(
      sc => sc.typeId || sc.certificationId || sc.certificateLabel
    );
    const newFields = { ...fields, siteCertificates: filterSiteCertificates };
    fields = { ...newFields, allergenData: undefined };
    await handleSiteRelations(formSiteId, fields);
    await handleUploadFilesAndMutate(fields);

    const formData = prepareFormData({ fields });
    await SmdAPI.updateSiteById(formSiteId, formData);

    setIsFormDirty(false);

    setSiteStatus(fields.siteActive);

    dispatch(setSiteCertificateFormData(fields?.siteCertificates));
    form.resetFields();
    history.replace(`/`);
    history.replace(`/sites/edit/${formSiteId}`);
  };

  const setInternalCode = (code: string) => {
    let newInternalCode = {
      typeId: SiteIdentification.INTERNAL_CODE,
      value: code,
    };
    setSubmitResult(({ internalCode, ...rest }) => rest);
    let newIdentifierCodes = siteSupplierFormData.identifierCodes || [];
    let dublicateIdentifier = [...newIdentifierCodes];
    let identifierCodes = [];
    const existingData = newIdentifierCodes.filter(
      item => item.typeId === newInternalCode.typeId
    );

    if (existingData.length) {
      dublicateIdentifier.forEach(item => {
        if (item.typeId === newInternalCode.typeId) {
          if (newInternalCode.value !== "") {
            identifierCodes.push({
              typeId: item.typeId,
              value: newInternalCode.value,
            });
          }
        } else {
          identifierCodes.push(item);
        }
      });
    } else {
      if (newInternalCode.value !== undefined && newInternalCode.value !== "")
        identifierCodes = [...newIdentifierCodes, newInternalCode];
      else identifierCodes = [...newIdentifierCodes];
    }
    return identifierCodes;
  };

  // Handle General submit form.
  const handleSubmit = async values => {
    const newFields = { ...siteSupplierFormData, ...values };
    let identifierCodes = [...siteSupplierFormData.identifierCodes];
    const fields = {
      ...newFields,
      ...{ identifierCodes },
    };
    try {
      isEditForm || siteSuccessfullyCreated
        ? await handleEditSite({ fields })
        : await handleCreationSite({ fields });
      toast.saveSuccess();
      setIsFormDirty(false);
    } catch (error) {
      if (fields.siteName === undefined || fields.siteName === "") {
        dispatch(
          setSiteSupplierFormError({ ...siteSupplierFormError, siteName: true })
        );
        setActiveTab(0);
        toast.saveError({ checkErrorResponse: false });
        return;
      }

      const result = await getFailedSubmitResult({ error });
      if (result?.internalCode) {
        setSubmitResult(prev => ({ ...prev, ...result }));
        setActiveTab(0);
        toast.saveError({ checkErrorResponse: false });
        return;
      }
      if (
        error?.response?.status === 400 &&
        error?.response?.data?.errorCode === ErrorCode.USER_NOT_ACTIVE
      ) {
        dispatch(
          setContactUserError({
            ...userContactError,
            userContact: error.response.data.error.split(":")[1],
          })
        );
        setActiveTab(1);
        setSubmitResult(prev => ({ ...prev, ...result }));

        toast.saveError({ checkErrorResponse: false });
        return;
      }

      if (
        error?.response?.status === 400 ||
        error.response?.data?.errorCode ===
          ErrorCode.CERTIFICATE_TYPE_ID_NOT_FOUND
      ) {
        const idx = siteSupplierFormData.siteCertificates.findIndex(
          ce => !ce.typeId
        );
        if (idx > -1) {
          dispatch(
            setSiteSupplierFormError({
              certificate: ["siteCertificates", idx, "typeId"],
            })
          );
          setActiveTab(2);
          toast.saveError({ checkErrorResponse: false });
        }
      }

      if (error?.response?.data?.error?.includes("SiteCertificates")) {
        const err = error.response.data.error.split("--")[1].split(":")[0];
        dispatch(
          setSiteSupplierFormError({
            certificate: [
              "siteCertificates",
              err.split("[")[1].split("]")[0],
              err.split(".")[1],
            ],
          })
        );
        setActiveTab(2);
        toast.saveError({ checkErrorResponse: false });
      }
    }
  };

  const handleChange = (value: boolean) => {
    const newData = form.getFieldsValue();
    let dataToSave = { ...siteSupplierFormData };
    const newFormData = newData;
    if (newFormData?.siteCertificates?.length) {
      const siteCertificates = newFormData?.siteCertificates.filter(
        sc => sc.typeId || sc.certificationId || sc.certificateLabel
      );
      dataToSave = { ...dataToSave, siteCertificates: siteCertificates };
    }

    const identifierCodes = setInternalCode(siteSupplierFormData.internalCode);

    dataToSave = {
      ...dataToSave,
      identifierCodes: identifierCodes,
    };
    if (dataToSave.siteName === "")
      dispatch(
        setSiteSupplierFormError({ ...siteSupplierFormError, siteName: true })
      );
    dispatch(setSiteSupplierFormData(dataToSave));
    setActiveTab(null);
  };
  const tabList = [
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "siteDetails.siteInformation" })}
        </Heading>
      ),
      content: (
        <SupplierSiteInformation
          form={form}
          disabled={isReadOnly}
          submitResult={submitResult}
        />
      ),
    },
    {
      label: (
        <Heading size="xs">
          {" "}
          {intl.formatMessage({ id: "siteForm.contactsTitle" })}
        </Heading>
      ),
      content: <SupplierSiteContact disabled={isReadOnly} />,
    },
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "general.classificates" })}
        </Heading>
      ),
      content: (
        <CertificateSection
          form={form}
          fetchCertificateSubLists={fetchCertificateSubLists}
          certificateSublists={certificateSublists}
          disabled={isReadOnly}
          isEditForm={isEditForm}
        />
      ),
    },
  ];
  const SiteStatusTag = () => {
    const statusTagData = siteStatus
      ? {
          label: intl.formatMessage({ id: "general.active" }),
          color: "green",
        }
      : {
          label: intl.formatMessage({ id: "general.inactive" }),
          color: "grey",
        };
    return <Tag mode="light" {...statusTagData} />;
  };

  const previousLocation = usePreviousLocation();

  const setExpandData = (errors: any) => {
    let siteCertificatesError = [];
    errors.forEach(error => {
      if (error.name[0] === "siteCertificates")
        siteCertificatesError.push(error.name);
    });
    dispatch(
      setSiteSupplierFormError({
        ...siteSupplierFormError,
        certificate: siteCertificatesError,
      })
    );
    toast.saveError({ checkErrorResponse: false });
  };

  const deactivateSite = async () => {
    try {
      siteStatus
        ? await SmdAPI.disableSite(formSiteId)
        : await SmdAPI.enableSite(formSiteId);
      toast.success({
        message: intl.formatMessage({
          id: siteStatus
            ? "sitesListPage.inactiveSuccessTitle"
            : "sitesListPage.reActiveSuccessTitle",
        }),
        description: intl.formatMessage({
          id: siteStatus
            ? "sitesListPage.inactiveSuccessText"
            : "sitesListPage.reActiveSuccessText",
        }),
      });
    } catch (error) {
      toast.error({
        message: intl.formatMessage({
          id: siteStatus
            ? "sitesListPage.inactiveFailedTitle"
            : "sitesListPage.reActiveFailedTitle",
        }),
        description: intl.formatMessage({
          id: siteStatus
            ? "sitesListPage.inactiveFailedText"
            : "sitesListPage.reActiveFailedText",
        }),
        checkErrorResponse: false,
      });
    }
  };

  const saveActions: MenuItemType[] = [
    {
      label: (
        <Tooltip
          onOpenChange={() => {
            setVisible(true);
          }}
          trigger="click"
          className="withNoArrow"
          placement="left"
          text={intl.formatMessage(
            {
              id: "sitesListPage.tooltip.deActivate.title",
            },
            { Name: "" }
          )}
          actions={[
            {
              text: intl.formatMessage({
                id: "general.cancel",
              }),
              onClick: (event: React.FocusEvent<HTMLElement>) => {
                event.target.blur();
                setVisible(false);
              },
            },
            {
              text: intl.formatMessage({
                id: "general.confirm",
              }),
              onClick: async (event: React.FocusEvent<HTMLElement>) => {
                setIsArchiving(true);
                event.target.blur();
                setVisible(false);
                await deactivateSite();
                setIsArchiving(false);
                history.push(`/sites/${formSiteId}`);
              },
            },
          ]}
          visible={visible}
        >
          <span style={{ display: "inline-block", width: "100%" }}>
            {intl.formatMessage({ id: "siteSupplier.deActivate" })}
          </span>
        </Tooltip>
      ),
      onClick: () => {},
      key: "site-archive",
    },
  ];

  return (
    <NewFormWrapper
      disabled={isReadOnly}
      title={headerTitle}
      status={<SiteStatusTag />}
      submitText={intl.formatMessage({ id: "general.save" })}
      cancelText={intl.formatMessage({ id: "general.cancel" })}
      withBackNav
      onBackClick={() => {
        if (!isFormDirty) {
          dispatch(
            setSiteSupplierFormError({ siteName: false, certificate: null })
          );
          dispatch(clearSiteSupplierFormData());
        }
        history.goBack();
      }}
      form={form}
      activeTab={activeTab}
      onFinish={handleSubmit}
      onValuesChange={changedValues => {
        let dataToSave: any;
        if (Object.keys(changedValues).includes("internalCode")) {
          const identifierCodes = setInternalCode(changedValues.internalCode);
          dataToSave = {
            ...siteSupplierFormData,
            ...changedValues,
            identifierCodes: identifierCodes,
          };
        }
        if (changedValues.identifierCodes) {
          var identifierCodes = [...changedValues.identifierCodes];
          if (
            siteSupplierFormData.internalCode === undefined ||
            siteSupplierFormData.internalCode === ""
          ) {
            identifierCodes = identifierCodes.filter(
              code => code.typeId !== SiteIdentification.INTERNAL_CODE
            );
          } else {
            if (
              !identifierCodes
                .map(code => code.typeId)
                .includes(SiteIdentification.INTERNAL_CODE)
            )
              identifierCodes.push({
                typeId: SiteIdentification.INTERNAL_CODE,
                value: siteSupplierFormData.internalCode,
              });
          }
          dataToSave = {
            ...siteSupplierFormData,
            identifierCodes: identifierCodes,
          };
        }

        let siteCertificate = [];
        if (changedValues.siteCertificates) {
          changedValues.siteCertificates.forEach(item => {
            if (item.typeId !== undefined) siteCertificate.push(item);
          });
        }
        const changedData = changedValues.siteCertificates
          ? { siteCertificates: siteCertificate }
          : changedValues;
        if (changedValues.siteCertificates) {
          dataToSave = {
            ...siteSupplierFormData,
          };
        } else if (
          !changedValues.identifierCodes &&
          !Object.keys(changedValues).includes("internalCode")
        ) {
          dataToSave = {
            ...siteSupplierFormData,
            ...changedValues,
            ...changedData,
          };
        }
        dispatch(setSiteSupplierFormData(dataToSave));
        if (!isFormDirty) setIsFormDirty(true);

        if ("siteName" in changedValues) {
          dispatch(setSiteSupplierFormError({ siteName: false }));
          setFormTitle(changedValues.siteName);
        }
      }}
      name="site-supplier-form"
      onFinishFailed={({ errorFields }) => {
        if (errorFields[0].name[0] === "siteName")
          dispatch(setSiteSupplierFormError({ siteName: true }));
        else setExpandData(errorFields);
      }}
      initialValues={prepareInitialValues({ initialValues })}
      tabs={tabList}
      handleChange={handleChange}
      hasSaveActions={isEditForm}
      saveActions={isEditForm && saveActions}
      isArchiving={isArchiving}
    >
      <LeavingFormPrompt when={isFormDirty} />
    </NewFormWrapper>
  );
};

export default SiteForm;
