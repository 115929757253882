import { useSelector } from "react-redux";

import { selectSupplierProductDetailsData } from "../../../../../../reduxStore/productDetails/selectors";
import styles from "../ProductCertificatesTab/styles.module.less";

import CertificatesSection from "./components/CertificatesSection";

interface ProductCertificatesTabProps {}
const ProductCertificatesTab: React.FC<ProductCertificatesTabProps> = () => {
  const {
    productSupplierData: { data: productData },
  } = useSelector(selectSupplierProductDetailsData);

  return (
    <div className={styles.root}>
      <CertificatesSection productData={productData} />
    </div>
  );
};

export default ProductCertificatesTab;
