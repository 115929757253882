import React from "react";
import { useSelector } from "react-redux";

import { CompanySwitcher } from "@trace-one/business-components";
import { Heading } from "@trace-one/design-system";
import { Popover } from "antd";

import useLogout from "core/oidc/useLogout";
import { CompanyGroupList } from "models";

import { selectOidcCompanyGroupId } from "reduxStore/oidc/selectors";
import {
  selectUserId,
  selectUserOwningCompanyName,
  selectUserOwningCompanyId,
  selectUserSelectedCompany,
} from "reduxStore/user/selectors";

import Icon from "components/Icon";
import { ApplicationTypes } from "shared/constants";
import { selectedCompanyStorage } from "shared/webStorage";

import styles from "./CompanyHeader.module.less";

const CompanyHeader: React.FC = () => {
  const {
    companyId: selectedCompanyId,
    companyDisplayName: selectedCompanyName,
  } = useSelector(selectUserSelectedCompany) ?? {};
  const owningCompanyName = useSelector(selectUserOwningCompanyName);
  const userId = useSelector(selectUserId);
  const owningCompanyId = useSelector(selectUserOwningCompanyId);
  const companyGroupId = useSelector(selectOidcCompanyGroupId);
  const { cleanupData } = useLogout();

  const onCompanySwitch = (company: CompanyGroupList) => {
    cleanupData();
    if (owningCompanyId !== company.companyId) {
      selectedCompanyStorage.set(company);
    }
    window.location.href = `${window.env.BASE_URL}/products/list`;
  };

  return (
    <div className={styles.selectedCompanyHeading}>
      <Popover
        placement="bottomRight"
        trigger="click"
        content={
          <CompanySwitcher
            selectedCompanyId={selectedCompanyId || owningCompanyId}
            owningCompanyId={owningCompanyId}
            companyGroupId={companyGroupId}
            owningCompanyName={owningCompanyName}
            applicationTypeId={ApplicationTypes.MASTER_DATA}
            userId={userId}
            onCompanySwitch={onCompanySwitch}
          />
        }
        overlayClassName="company-switcher-overlay"
      >
        <Icon
          name="company-switcher"
          size="large"
          data-test-id="company-header-icon"
          color="primary"
          className={styles.companySwitcherIcon}
          useTOLibrary
        />
      </Popover>
      <Heading data-test-id="company-header-name" color="grey-4" size="xs">
        {selectedCompanyName || owningCompanyName}
      </Heading>
    </div>
  );
};

export default CompanyHeader;
