import { createSlice } from "@reduxjs/toolkit";
import { SiteIdentification } from "pages/Sites/constants";

import { fetchSite, fetchSiteRelations } from "./asyncActions";
import { initialState } from "./initialState";

export const slice = createSlice({
  name: "siteSupplierDetails",
  initialState,
  reducers: {
    clearSiteSupplierDetails: () => initialState,
    setSiteSupplierFormData: (state, action) => {
      const dupIdentifierCodes = [...(action.payload?.identifierCodes || [])];
      let identifierCodes = [];
      if (action.payload?.internalCode) {
        const exists = dupIdentifierCodes.findIndex(
          code => code.typeId === SiteIdentification.INTERNAL_CODE
        );
        if (exists > -1) {
          dupIdentifierCodes.forEach(code => {
            if (code.typeId === SiteIdentification.INTERNAL_CODE) {
              identifierCodes.splice(exists, 1);
              identifierCodes.push({
                typeId: code.typeId,
                value: action.payload.internalCode,
              });
            } else {
              identifierCodes.push(code);
            }
          });
        } else {
          identifierCodes = [...dupIdentifierCodes];
          identifierCodes.push({
            typeId: SiteIdentification.INTERNAL_CODE,
            value: action.payload.internalCode,
          });
        }
      } else {
        identifierCodes = [...dupIdentifierCodes];
      }

      state.siteSupplierFormData.data = {
        ...action.payload,
        identifierCodes: identifierCodes,
      };
    },
    setSiteSupplierFormError: (state, action) => {
      state.siteSupplierFormError = {
        ...state.siteSupplierFormError,
        ...action.payload,
      };
    },
    setSiteCertificateFormData: (state, action) => {
      state.siteCertificateFormData.data = action.payload;
    },
    clearSiteSupplierFormData: state => {
      state.siteSupplierFormData.data = {};
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSite.pending, (state, action) => {
        state.site.isLoading = true;
        state.site.hasError = false;
      })
      .addCase(fetchSite.fulfilled, (state, { payload }) => {
        state.site.data = payload.site;
        state.supplier.data = payload.supplier;
        state.allergenData = payload.allergenNames;
        state.contactUsers.data = payload.contactUsers;
        state.site.isLoading = false;
      })
      .addCase(fetchSite.rejected, (state, action) => {
        state.site.data = {};
        state.allergenData = [];
        state.site.isLoading = false;
        state.site.hasError = true;
        state.site.errorStatus = action.payload.status;
      })

      .addCase(fetchSiteRelations.pending, state => {
        state.siteRelation.isLoading = true;
        state.siteRelation.hasError = false;
      })
      .addCase(fetchSiteRelations.fulfilled, (state, action) => {
        state.siteRelation.data = action.payload;
        state.siteRelation.isLoading = false;
      })
      .addCase(fetchSiteRelations.rejected, state => {
        state.siteRelation.data = [];
        state.siteRelation.isLoading = false;
        state.siteRelation.hasError = true;
      });
  },
});

export const {
  clearSiteSupplierDetails,
  setSiteCertificateFormData,
  setSiteSupplierFormError,
  setSiteSupplierFormData,
  clearSiteSupplierFormData,
} = slice.actions;
export default slice.reducer;
