import { CertificationItem, CertificationsListData } from "../../../apis/certification/types";
import { useEffect, useState } from "react";
import { fetchCertificationsList } from "../../../apis/certification";
const useCertifications = (languageCode, isAIUsed, objectLink) => {
  const [certificationsListData, setCertificationsListData] = useState<CertificationsListData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getCertificationsList = async () => {
    try {
      setIsLoading(true);

      let allCertifications: CertificationItem[] = [];
      let currentPage = 1;
      let totalPages = 1;
      const pageSize = 50;

      while (currentPage <= totalPages) {
        const { data } = await fetchCertificationsList(languageCode, objectLink, undefined, currentPage, pageSize);

        if (data.items) {
          allCertifications = [...allCertifications, ...data.items];
        }

        currentPage++;
        totalPages = data.totalPages || 1;
      }

      setCertificationsListData({
        items: allCertifications,
        totalCount: allCertifications?.length,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCertificationsList();
  }, [languageCode, isAIUsed, objectLink]);

  return {
    isLoading,
    certificationsListData,
  };
};
export default useCertifications;
