import { SharedRoles } from "@trace-one/api-clients.cumd/dist/models/roles";

import {
  UserApplicationData,
  UserData,
  CompanyFromCollectionData,
  CompanyGroupList,
} from "models";
import { DEFAULT_LANGUAGE } from "translations";

type State = {
  applications: UserApplicationData[];
  sharedRoles: SharedRoles;
  companyActivityNameInEn: string;
  userSelectedCompany: CompanyGroupList;
  isApplicationsLoading: boolean;
  hasApplicationsError: boolean;
  applicationsCompanies: CompanyFromCollectionData[];
  isUserDetailsLoading: boolean;
  hasUserDetailsError: boolean;
  hasSharedRolesError: boolean;
  isSharedRolesLoading: boolean;
  details: UserData;
  isUserCompanyGroupListLoading: boolean;
  userCompanyGroupList: CompanyGroupList[];
};

export const initialState: State = {
  applications: [],
  sharedRoles: null,
  companyActivityNameInEn: "",
  userSelectedCompany: null,
  isApplicationsLoading: true,
  isSharedRolesLoading: false,
  hasSharedRolesError: false,
  hasApplicationsError: false,
  applicationsCompanies: [],
  isUserDetailsLoading: true,
  hasUserDetailsError: false,
  isUserCompanyGroupListLoading: false,
  userCompanyGroupList: [],
  details: {
    isAccountAdministrator: false,
    owningCompanyId: "",
    userFirstName: "",
    userId: "",
    userJobTitle: "",
    userLanguagePreference: DEFAULT_LANGUAGE,
    userLastName: "",
    userLocked: false,
    userLockedDate: "",
    userLogin: "",
    userNotificationEmail: "",
    userPhone: "",
    userPhotoUrl: "",
    userStatus: "",
    userUnlockedDate: "",
  },
};
