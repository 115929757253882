import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "reduxStore";
import { fetchTeamMemberResponsibilities } from "reduxStore/shared/asyncActions";
import { selectPointOfSalesData } from "reduxStore/shared/selectors";
import { clearTeamMemberResponsibilities } from "reduxStore/shared/slice";
import {
  selectUserLanguageCode,
  selectUserOwningCompanyId,
  selectUserSelectedCompany,
} from "reduxStore/user/selectors";

import getDisplayName from "shared/utils/getDisplayName";

import { BaseHocProps, renderHocMiddleware } from "../_helpers";

const withTeamMemberResponsibilities =
  ({
    parentItemId,
    includeParentItems,
  }: {
    parentItemId?: string;
    includeParentItems?: boolean;
  }) =>
  <P extends BaseHocProps>(WrappedComponent: React.ComponentType<P>) => {
    const Component: React.FC<P> = props => {
      const dispatch = useAppDispatch();
      const languageCode = useSelector(selectUserLanguageCode);
      const { companyId: selectedCompanyId } =
        useSelector(selectUserSelectedCompany) ?? {};
      const ownerCompanyId = useSelector(selectUserOwningCompanyId);
      const { hasError, isLoading } = useSelector(selectPointOfSalesData);

      useEffect(() => {
        return () => {
          dispatch(clearTeamMemberResponsibilities(null));
        };
      }, []);

      useEffect(() => {
        dispatch(
          fetchTeamMemberResponsibilities({
            languageCode,
            parentItemId,
            includeParentItems,
            companyId: selectedCompanyId || ownerCompanyId,
          })
        );
      }, [languageCode]);

      return renderHocMiddleware({
        WrappedComponent,
        _hasError: hasError,
        _isInitiated: true,
        _isLoading: isLoading,
        ...props,
      });
    };

    Component.displayName = getDisplayName(
      withTeamMemberResponsibilities,
      WrappedComponent
    );

    return Component;
  };

export default withTeamMemberResponsibilities;
