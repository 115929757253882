import styles from "../ProductCertificatesTab/styles.module.less";

import CertificatesList from "./components/CertificatesList";

interface ProductCertificatesTabProps {
  productData?: any;
}
const ProductCertificatesTab: React.FC<ProductCertificatesTabProps> = ({
  productData,
}) => {
  return (
    <div className={styles.root}>
      <CertificatesList productData={productData} />
    </div>
  );
};

export default ProductCertificatesTab;
