import { useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { TagV1 as Tag } from "@trace-one/design-system";

import { CumdAPI } from "apis";

import {
  selectUserOwningCompanyId,
  selectUserSelectedCompany,
} from "reduxStore/user/selectors";

import Select from "components/Select";
import UserAsyncSearchSelect from "components/UserAsyncSearchSelect";
import { UserStatus, dropdownFilterCount } from "shared/constants";
import { SelectOption } from "shared/typings";

import styles from "./ContactInputGroupFilter.module.less";
import { ContactFilter } from "./models";

import CustomInputGroup from ".";

type Contact = {
  userId?: string;
  responsibilityId?: string;
  userName?: string;
  userStatus?: string;
  responsibilityName?: string;
};

type UserError = {
  userId: string;
  responsibilityId?: string;
};

interface ContactsInputGroupProps {
  value?: Contact[];
  onChange?: (value: Contact[]) => void;
  disabled?: boolean;
  teamMemberUsers: {
    value: string;
    name: string;
    userStatus: string;
  }[];
  withUserStatusMention?: boolean;
  filterObj: any;
  withErrors?: UserError[];
  mergeFilters: (
    value:
      | Partial<ContactFilter>
      | ((prevState: ContactFilter) => ContactFilter)
  ) => void;
  teamMemberResponsibilitiesOption?: any;
  setSelectedKpi?: (kpi: string) => void;
}

const ContactsInputGroup: React.FC<ContactsInputGroupProps> = ({
  filterObj,
  mergeFilters,
  disabled,
  teamMemberUsers,
  teamMemberResponsibilitiesOption,
  setSelectedKpi,
}) => {
  const intl = useIntl();
  const companyId = useSelector(selectUserOwningCompanyId);
  const { companyId: selectedCompanyId } =
    useSelector(selectUserSelectedCompany) ?? {};
  const [usersDefaultOptions, setUsersDefaultOptions] = useState<
    SelectOption[]
  >([]);
  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const totalCount = useRef(dropdownFilterCount);

  useEffect(() => {
    const options = teamMemberUsers
      .filter(
        item => item.name !== " " && item.userStatus !== UserStatus.DISABLED
      )
      .map(({ value, name, userStatus }) => ({
        name: getTagName(userStatus, name),
        value: value,
        title: name,
      }));
    setUsersDefaultOptions(options);
  }, [teamMemberUsers]);

  const getTagName = (userStatus: string, name: string) => {
    let inActive = userStatus !== UserStatus.ENABLED;
    const tagName = inActive && (
      <Tag
        size="small"
        label={intl.formatMessage({ id: "general.inactive" })}
        color="grey"
        mode="light"
      />
    );
    return (
      <div className={styles.brandTag}>
        <div className={styles.contactNameTitle}>
          <span className={inActive && styles.contactName}>{name} </span>
        </div>

        {tagName}
      </div>
    );
  };

  return (
    <CustomInputGroup
      leftInput={
        <UserAsyncSearchSelect
          defaultOptions={usersDefaultOptions}
          value={filterObj?.userId ? filterObj.userId : undefined}
          showTag
          onChange={(_value, option) => {
            if (setSelectedKpi) {
              setSelectedKpi(null);
            }
            mergeFilters({
              userId: option?.value,
              responsibilityId: undefined,
            });
          }}
          disabled={filterObj.checked}
          placeholder={intl.formatMessage({
            id: "productDetails.selectContactName",
          })}
          data-test-id="md-contact-user"
          onPopupScroll={async e => {
            e.persist();
            let target = e.target;
            if (
              Math.round(target.scrollTop + target.offsetHeight) >
              target.scrollHeight - 3
            ) {
              if (usersLoading) {
                return;
              }

              setUsersLoading(true);
              totalCount.current = totalCount.current + dropdownFilterCount;
              const { data } = await CumdAPI.getUsersByUserIdsAndFilters(
                {},
                {
                  skip: 0,
                  take: totalCount.current,
                  owningCompanyId: selectedCompanyId || companyId,
                  isDeactivated: false,
                }
              );
              const userOptions = data.users
                .filter(
                  ({ userFirstName, userLastName, userStatus }) =>
                    `${userFirstName} ${userLastName}` !== " " &&
                    userStatus !== UserStatus.DISABLED
                )
                .map(({ userFirstName, userLastName, userId, userStatus }) => ({
                  name: getTagName(
                    userStatus,
                    `${userFirstName} ${userLastName}`
                  ),
                  value: userId,
                  title: `${userFirstName} ${userLastName}`,
                }));
              setUsersDefaultOptions(userOptions);
              setUsersLoading(false);
            }
          }}
        />
      }
      rightInput={
        <Select
          options={teamMemberResponsibilitiesOption.map(({ id, text }) => ({
            name: text,
            value: id,
          }))}
          value={
            filterObj?.responsibilityId
              ? filterObj?.responsibilityId
              : undefined
          }
          disabled={!filterObj.userId || disabled}
          onChange={responsibilityId => {
            if (setSelectedKpi) {
              setSelectedKpi(null);
            }
            mergeFilters({
              responsibilityId: responsibilityId,
            });
          }}
          placeholder={intl.formatMessage({
            id: "productDetails.selectResponsibility",
          })}
          id="md-form-contact-responsibility"
          allowClear
          data-test-id="md-contact-responsibility"
        />
      }
    ></CustomInputGroup>
  );
};

export default ContactsInputGroup;
