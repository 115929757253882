import React, { useRef } from "react";
import { Button, Paragraph } from "@trace-one/design-system";
import { Upload as AntUpload } from "antd";
import classnames from "classnames";
import { useIntl } from "react-intl";
import { DMS } from "IndexComponents";
import { withLanguageData } from "src/hocs";
import { useModalVisibility } from "src/hooks";
import Errors from "./components/Errors";
import { useDocumentConfiguration, useUploadActions } from "./hooks";
import { getUploadColor, prepareAllowedFileExtensions } from "./utils";
import List from "../List";
import { DraggerProps } from "./types";
import enUs from "../../translations/en-US.json";
import frFr from "../../translations/fr-FR.json";
import { LangList } from "../../../../../utils/const";
import { draggerMessages } from "../../translations/messages";
import "../styles.less";

const DEFAULT_LANG = enUs;

const enhance = withLanguageData({
  translations: {
    [LangList.EN_US]: enUs,
    [LangList.FR_FR]: { ...DEFAULT_LANG, ...frFr },
  },
});

const Dragger: React.FC<DraggerProps> = props => {
  const {
    className,
    errorMessage,
    disabled,
    hasError,
    onRemove: defaultOnRemove,
    onUploadFile,
    fileList: defaultFileList,
    size = "large",
    onSelect,
    onUploadFiles,
    fileType,
    multiple = true,
    showFullToolTip = false,
    ownerCompanyId,
    dmsControl = true,
    metaDataSearches,
    responsibility,
    showResponsibility,
    draggerText,
    uploadText,
    showFileSize,
    showUploadTime,
    showUploadUserName,
    showUploadDate,
    showFileDescription = true,
    disabledFileIds = [],
    showListItems = true,
    messages,
    shouldAvoidDMSUpload = false,
    showDropdown = false,
    maxCount = null,
    ...rest
  } = props;

  const { formatMessage } = useIntl();

  const { allowedFileExtensions, maxFileSizeInBytes } =
    useDocumentConfiguration({
      fileType,
    });

  const {
    fileList,
    typeErrorUids,
    sizeErrorUids,
    onInputFileChange,
    onSelectButtonClick,
    beforeUploadHandler,
    customRequestHandler,
    onRemove,
  } = useUploadActions({
    defaultFileList,
    fileType,
    onUploadFile,
    onSelect,
    onUploadFiles,
    defaultOnRemove,
    allowedFileExtensions,
    maxFileSizeInBytes,
    shouldAvoidDMSUpload,
    messages,
    maxCount,
  });

  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility();

  const inputFile = useRef(null);

  const componentSize = showDropdown ? "large" : size;

  const uploadDraggerPrefixCls = "business-components-upload-dragger";
  const uploadDraggerContainerPrefixCls = `${uploadDraggerPrefixCls}-container`;

  const items = [
    {
      key: "1",
      label:
        messages?.uploadButtonFromComputer ??
        formatMessage(draggerMessages.uploadButtonFromComputer),
      onClick: () => inputFile.current.click(),
    },
    {
      key: "2",
      label:
        messages?.uploadButtonFromLibrary ??
        formatMessage(draggerMessages.uploadButtonFromLibrary),
      onClick: () => onOpenModal(),
    },
  ];

  const uploadFileProps = {
    accept: prepareAllowedFileExtensions(allowedFileExtensions),
    ...rest,
  };

  const allowUpload = maxCount ? fileList?.length < maxCount : true;

  return (
    <div className="business-components-upload-wrapper">
      <div className={`business-components-upload-${componentSize}`}>
        {allowUpload && (
          <div className="business-components-upload-section">
            {/* @ts-ignore */}
            <AntUpload.Dragger
              className={classnames(uploadDraggerPrefixCls, className, {
                disabled,
                "has-error": hasError,
              })}
              fileList={[]}
              disabled={disabled}
              multiple={multiple}
              beforeUpload={beforeUploadHandler}
              customRequest={customRequestHandler}
              maxCount={maxCount}
              {...uploadFileProps}
            >
              <div
                className={classnames(
                  uploadDraggerContainerPrefixCls,
                  `${uploadDraggerContainerPrefixCls}-${componentSize}`
                )}
              >
                <Paragraph
                  className={`${uploadDraggerContainerPrefixCls}-text`}
                  color={getUploadColor({
                    isDraggerDisabled: disabled,
                    hasError,
                    defaultColor: "primary",
                  })}
                  size="s"
                >
                  {draggerText ??
                    formatMessage(
                      draggerMessages[
                        componentSize === "large"
                          ? "draggerTextLarge"
                          : "draggerText"
                      ]
                    )}
                </Paragraph>
              </div>
            </AntUpload.Dragger>

            <Button
              type="secondary"
              items={dmsControl && items}
              iconPlacement="right"
              iconName="upload"
              disabled={disabled}
              onClick={!dmsControl && (() => inputFile.current.click())}
            >
              {uploadText ??
                (componentSize === "large"
                  ? formatMessage(draggerMessages.uploadButton)
                  : "")}
            </Button>

            <input
              style={{ display: "none" }}
              ref={inputFile}
              type="file"
              /* @ts-expect-error */
              onChange={onInputFileChange}
              multiple={multiple}
              {...uploadFileProps}
            />

            {isModalOpen && (
              <DMS
                visible={isModalOpen}
                onSelectButtonClick={onSelectButtonClick}
                onClose={onCloseModal}
                fileType={fileType}
                multiple={multiple}
                showFullToolTip={showFullToolTip}
                ownerCompanyId={ownerCompanyId}
                metaDataSearches={metaDataSearches}
                disabledFileIds={disabledFileIds}
                maxCount={maxCount}
                fileList={fileList}
              />
            )}
          </div>
        )}

        <Errors
          allowedFileExtensions={allowedFileExtensions}
          maxFileSizeInBytes={maxFileSizeInBytes}
          typeErrorUids={typeErrorUids}
          sizeErrorUids={sizeErrorUids}
          messages={messages}
          error={hasError ? errorMessage : null}
        />
        {showListItems && (
          <List
            fileList={fileList}
            size={componentSize}
            showResponsibility={showResponsibility}
            showFileSize={showFileSize}
            showUploadTime={showUploadTime}
            showUploadUserName={showUploadUserName}
            showUploadDate={showUploadDate}
            showFileDescription={showFileDescription}
            showDropdown={showDropdown}
            messages={messages}
            {...(!disabled && {
              onRemove,
            })}
          />
        )}
      </div>
    </div>
  );
};

export default enhance(Dragger);
