import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading, Modal, Tooltip, Paragraph } from "@trace-one/design-system";

import { selectTeamMemberResponsibilities } from "reduxStore/shared/selectors";

import Table from "components/Table";

import styles from "./ContactsModal.module.less";

const ContactsModal = ({
  contactsData,
  open,
  title,
  contactColumn,
  onCancel,
  onPrimaryButtonClick,
}) => {
  const teamMemberResponsibilitiesOption = useSelector(
    selectTeamMemberResponsibilities
  );
  const contactData =
    contactsData?.contacts?.length > 0 &&
    contactsData?.contacts?.map(item => {
      const { text } =
        teamMemberResponsibilitiesOption.find(
          ({ id }) => id === item.responsibilityId
        ) ?? {};
      return {
        responsibility: text,
        contactName: `${item.userFirstName} ${item.userLastName}`,
        status: item.userStatus,
      };
    });
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({ id: "productsListPage.table.modal.title" })}
      headerChildren={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Heading size="xxs">
            {intl.formatMessage({
              id: title,
            })}
          </Heading>

          <div className={styles.modelTitleName}>
            <Tooltip showFullText text={contactsData?.itemName} placement="top">
              <Paragraph>{contactsData?.itemName}</Paragraph>
            </Tooltip>
          </div>
        </div>
      }
      size="l"
      open={open}
      primaryButtonText={intl.formatMessage({ id: "general.close" })}
      onCancel={onCancel}
      onPrimaryButtonClick={onPrimaryButtonClick}
    >
      <Table columns={contactColumn} dataSource={contactData}></Table>
    </Modal>
  );
};

export default ContactsModal;
