import React, { FC, useContext } from "react";
import { Button, Divider } from "@trace-one/design-system";
import { FooterProps } from "./types";
import { SidebarContext } from "../../context/SidebarContextProvider";
import "./styles.less";

const Footer: FC<FooterProps> = ({ buttonOneProps, buttonTwoProps, buttonOneLabel, buttonTwoLabel }) => {
  const { isMinified } = useContext(SidebarContext);

  return (
    <div className={isMinified ? "footer-minified" : "footer"}>
      <Divider />
      <div className="footer-buttons">
        {typeof buttonOneProps?.onClick === "function" && (
          <Button {...buttonOneProps}>{buttonOneLabel}</Button>
        )}
        {typeof buttonTwoProps?.onClick === "function" && (
          <Button {...buttonTwoProps}>{buttonTwoLabel}</Button>
        )}
      </div>
    </div>
  );
};

export default Footer;
