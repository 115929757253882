import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "reduxStore";

import { ApplicationTypes } from "shared/constants";

/* istanbul ignore next */
export const _selectUser = (state: RootState) => state.user;

export const selectIsUserApplicationsLoading = createSelector(
  _selectUser,
  ({ isApplicationsLoading }) => isApplicationsLoading
);

export const selecthasApplicationsError = createSelector(
  _selectUser,
  ({ hasApplicationsError }) => hasApplicationsError
);

export const selectUserApplications = createSelector(
  _selectUser,
  ({ applications }) => applications
);
export const selectNotificationUserApplications = createSelector(
  selectUserApplications,
  userApplications => {
    const REQUIRED_NOTIFICATION_USER_APPS = [
      ApplicationTypes.INSIGHT,
      ApplicationTypes.TON,
      ApplicationTypes.PROJECT,
      ApplicationTypes.QMS,
      ApplicationTypes.SPEC,
      ApplicationTypes.RFX,
      ApplicationTypes.MASTER_DATA,
      ApplicationTypes.NEW_INSIGHT,
      ApplicationTypes.ADMINISTRATION,
    ];
    return userApplications.filter(app =>
      REQUIRED_NOTIFICATION_USER_APPS.includes(app.applicationTypeId)
    );
  }
);

export const selectUserInsightStsURL = createSelector(
  _selectUser,
  ({ applications }) =>
    applications.find(
      ({ applicationTypeId }) => applicationTypeId === ApplicationTypes.INSIGHT
    )?.stsEndpointUrl
);

export const selectUserInsightGetURL = createSelector(
  _selectUser,
  ({ applications }) =>
    applications.find(
      ({ applicationTypeId }) =>
        applicationTypeId === ApplicationTypes.NEW_INSIGHT
    )?.getEndpointUrl
);

export const selectHasUserAccessToMd = createSelector(
  selectUserApplications,
  applications =>
    applications?.findIndex(
      ({ applicationTypeId }) => applicationTypeId === 100
    ) !== -1
);

export const selectHasUserAccessToProject = createSelector(
  selectUserApplications,
  applications =>
    applications?.findIndex(
      ({ applicationTypeId }) => applicationTypeId === 50
    ) !== -1
);

export const selectUserApplicationsCompanies = createSelector(
  _selectUser,
  ({ applicationsCompanies }) => applicationsCompanies
);

export const selectUserSharedRoles = createSelector(
  _selectUser,
  ({ sharedRoles }) => sharedRoles
);

export const selectIsUserSharedRolesLoading = createSelector(
  _selectUser,
  ({ isSharedRolesLoading }) => isSharedRolesLoading
);

export const selectUserSelectedCompany = createSelector(
  _selectUser,
  ({ userSelectedCompany }) => userSelectedCompany
);

export const selectHasUserSharedRolesError = createSelector(
  _selectUser,
  ({ hasSharedRolesError }) => hasSharedRolesError
);

export const selecthasUserDetailsError = createSelector(
  _selectUser,
  ({ hasUserDetailsError }) => hasUserDetailsError
);

export const selectIsUserDetailsLoading = createSelector(
  _selectUser,
  ({ isUserDetailsLoading }) => isUserDetailsLoading
);
const selectUserDetails = createSelector(_selectUser, ({ details }) => details);

export const selectUserCompanyGroupList = createSelector(
  _selectUser,
  ({ userCompanyGroupList }) => userCompanyGroupList
);

export const selectUserCompanyGroupListLoading = createSelector(
  _selectUser,
  ({ isUserCompanyGroupListLoading }) => isUserCompanyGroupListLoading
);

export const selectUserId = createSelector(
  selectUserDetails,
  ({ userId }) => userId
);
export const selectUserLogin = createSelector(
  selectUserDetails,
  ({ userLogin }) => userLogin
);
export const selectUserLanguageCode = createSelector(
  selectUserDetails,
  ({ userLanguagePreference }) => userLanguagePreference
);
export const selectUserOwningCompanyId = createSelector(
  selectUserDetails,
  ({ owningCompanyId }) => owningCompanyId
);
export const selectUserPhotoUrl = createSelector(
  selectUserDetails,
  ({ userPhotoUrl }) => userPhotoUrl
);
export const selectUserName = createSelector(
  selectUserDetails,
  ({ userFirstName, userLastName }) =>
    [userFirstName, userLastName].filter(item => item).join(" ")
);
export const selectUserJobTitle = createSelector(
  selectUserDetails,
  ({ userJobTitle }) => userJobTitle
);
export const selectUserCompanyActivityNameInEng = createSelector(
  _selectUser,
  ({ companyActivityNameInEn }) => companyActivityNameInEn
);
export const selectUserOwningCompanyName = createSelector(
  _selectUser,
  ({ details, applicationsCompanies }) => {
    return applicationsCompanies.find(
      c => c.companyId === details.owningCompanyId
    )?.companyDisplayName;
  }
);
