import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Tooltip, Graphic } from "@trace-one/design-system";
import { Menu } from "antd";
import { SectionsIds } from "pages/Products/components/ProductForm/constants";

import { PmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import { setProductSelectedSupplier } from "reduxStore/productForm/slice";
import {
  selectUserApplications,
  selectUserOwningCompanyId,
} from "reduxStore/user/selectors";

import ActionDropdown from "components/ActionDropdown";
import Icon from "components/Icon";
import Spinner from "components/Spinner";
import {
  ManufacturingItemStatus,
  pdmDetails as pdmIds,
} from "shared/constants";
import useAsyncHandler from "shared/hooks/useAsyncHandler";
import useToast from "shared/hooks/useToast";

import { ManuItemsColumn } from "../models";

interface ManufacturedItemActionProps {
  productId: string;
  refetchProduct: () => void;
  certificateIds?: string[];
  productData?: any;
  record: ManuItemsColumn;
}

const ManufacturedItemAction: React.FC<ManufacturedItemActionProps> = ({
  productId,
  refetchProduct,
  certificateIds,
  productData,
  record,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { productsEnable } = usePermissions();
  const [visible, setVisible] = useState<boolean>(false);
  const toast = useToast();
  const isArchived =
    record.manufacturedItemStatus === ManufacturingItemStatus.INACTIVE;
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const userApplications = useSelector(selectUserApplications);
  const userAppIds = userApplications.map(app => app.applicationTypeId);
  const pdmApplication = pdmIds.find(c => userAppIds.includes(c.id));

  const handleEditManufacturedItem = () => {
    dispatch(setProductSelectedSupplier(record.key));
    history.push({
      pathname: `/products/edit/${productId}`,
      hash: SectionsIds.MANUFACTURED_ITEMS_SUPPLIER,
    });
  };

  const [handleProductStatus, isArchiveProductLoading] = useAsyncHandler(
    async () => {
      try {
        await PmdAPI.patchManufacturedItem(record.key, {
          manufacturedItemStatusId: isArchived
            ? ManufacturingItemStatus.ACTIVE
            : ManufacturingItemStatus.INACTIVE,
        });
        toast.success({
          message: intl.formatMessage({
            id: isArchived
              ? "productDetails.manufacturedItem.unArchivedSuccessTitle"
              : "productDetails.manufacturedItem.archivedSuccessTitle",
          }),
          description: intl.formatMessage({
            id: isArchived
              ? "productDetails.manufacturedItem.unArchivedSuccessText"
              : "productDetails.manufacturedItem.archivedSuccessText",
          }),
        });
        history.push({
          pathname: `/products/${productId}`,
          hash: SectionsIds.MANUFACTURED_ITEMS,
        });
        refetchProduct();
      } catch (error) {
        toast.error({
          message: intl.formatMessage({
            id: isArchived
              ? "productDetails.manufacturedItem.unArchivedFailedTitle"
              : "productDetails.manufacturedItem.archivedFailedTitle",
          }),
          description: intl.formatMessage({
            id: isArchived
              ? "productDetails.manufacturedItem.unArchivedFailedText"
              : "productDetails.manufacturedItem.archivedFailedText",
          }),
          checkErrorResponse: false,
        });
      }
    }
  );

  const handleOpenManufacturedItem = () => {
    history.push({
      pathname: `/products/manufacturedItem/${productId}/${record?.key}`,
    });
  };

  const menuItems = [
    {
      key: "editManufacturedItem",
      onClick: handleEditManufacturedItem,
      icon: <Icon name="edit" size="small" useTOLibrary />,
      spinning: false,
      label: intl.formatMessage({ id: "general.editManufacturedItem" }),
    },
    ...(pdmApplication
      ? [
          {
            key: "openProductInPdm",
            onClick: () => {
              if (pdmApplication?.url) {
                window.location.href =
                  pdmApplication.url +
                  `?MasterDataCompanyGuid=${ownerCompanyId}&MasterDataEntityGuid=${record.key}`;
              }
            },
            icon: <Graphic name={pdmApplication.iconName} size="small" />,
            spinning: false,
            label: intl.formatMessage(
              {
                id: "productDetails.pdmLinkOpen",
              },
              {
                Name: pdmApplication.name,
              }
            ),
          },
        ]
      : []),
  ];

  return (
    <ActionDropdown
      data-test-id="md-manufacturedItem-action"
      disabled={!menuItems.length}
      overlay={
        <Menu>
          {certificateIds?.length !== 0 &&
            ownerCompanyId === productData?.ownerCompanyId && (
              <Menu.Item
                key="open-manufactured-item"
                onClick={handleOpenManufacturedItem}
                icon={<Graphic name="certificates" size="small" />}
              >
                {intl.formatMessage({
                  id: "general.openManufItem",
                })}
              </Menu.Item>
            )}

          {menuItems.map(item => (
            <Menu.Item key={item.key} onClick={item.onClick} icon={item.icon}>
              {item.label}
            </Menu.Item>
          ))}
          {isArchived ? (
            productsEnable && (
              <Menu.Item
                key="archive-product"
                disabled={isArchiveProductLoading}
              >
                {
                  <Tooltip
                    className="withNoArrow"
                    trigger="click"
                    onOpenChange={() => setVisible(true)}
                    placement="top"
                    text={intl.formatMessage(
                      {
                        id: "productForm.manufactured.card.unarchiveTooltipTitle",
                      },
                      {
                        Name: " " + record.manufacturedItemName,
                      }
                    )}
                    actions={[
                      {
                        text: intl.formatMessage({ id: "general.cancel" }),
                        onClick: (event: React.FocusEvent<HTMLElement>) => {
                          event.target.blur();
                          setVisible(false);
                        },
                      },
                      {
                        text: intl.formatMessage({ id: "general.confirm" }),
                        onClick: () => {
                          handleProductStatus();
                          setVisible(false);
                        },
                      },
                    ]}
                    visible={visible}
                  >
                    <Spinner
                      spinning={isArchiveProductLoading}
                      hideChildrenVisibilityOnLoading
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Icon name={"unarchive"} size="small" useTOLibrary />
                        <span style={{ marginLeft: "10px" }}>
                          {intl.formatMessage({
                            id: "general.unArchive",
                          })}
                        </span>
                      </div>
                    </Spinner>
                  </Tooltip>
                }
              </Menu.Item>
            )
          ) : (
            <Menu.Item key="archive-product" disabled={isArchiveProductLoading}>
              {
                <Tooltip
                  className="withNoArrow"
                  trigger="click"
                  onOpenChange={() => setVisible(true)}
                  placement="top"
                  text={intl.formatMessage(
                    {
                      id: "productForm.manufactured.card.archiveTooltipTitle",
                    },
                    {
                      Name: " " + record.manufacturedItemName,
                    }
                  )}
                  actions={[
                    {
                      text: intl.formatMessage({ id: "general.cancel" }),
                      onClick: (event: React.FocusEvent<HTMLElement>) => {
                        event.target.blur();
                        setVisible(false);
                      },
                    },
                    {
                      text: intl.formatMessage({ id: "general.confirm" }),
                      onClick: () => {
                        handleProductStatus();
                        setVisible(false);
                      },
                    },
                  ]}
                  visible={visible}
                >
                  <Spinner
                    spinning={isArchiveProductLoading}
                    hideChildrenVisibilityOnLoading
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Icon name={"archive"} size="small" useTOLibrary />
                      <span style={{ marginLeft: "10px" }}>
                        {intl.formatMessage({
                          id: "general.archive",
                        })}
                      </span>
                    </div>
                  </Spinner>
                </Tooltip>
              }
            </Menu.Item>
          )}
        </Menu>
      }
    />
  );
};

export default ManufacturedItemAction;
