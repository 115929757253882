export const SectionsIds = Object.freeze({
  IDENTIFICATION: "site-identification-section",
  PRIVATE: "site-private-section",
  VISIBILITY: "site-visibility-section",
  ADDRESS: "site-address-section",
  CONTACTS: "site-contacts-section",
  ACTIVITIES: "site-activities-section",
  CERTIFICATES: "site-certificates-section",
  ALLERGENS: "site-allergens-section",
  COMMENTS: "site-comments-section",
});
export const SupplierSectionIds = Object.freeze({
  CONTACTS: "site-supplier-contacts-section",
});

export const SiteIdentification = Object.freeze({
  INTERNAL_CODE: "8b83df2d-bfd1-4a6f-a2d0-3a671383e710",
});

export const PageCol = {
  HALF: { xs: 24, lg: 12 },
  FULL: { xs: 24 },
};

export const AddressModel = Object.freeze({
  ADDRESS_1: "siteAddress1",
  ADDRESS_2: "siteAddress2",
  CITY: "siteCity",
  POSTAL_CODE: "sitePostalCode",
  REGION: "siteRegion",
  COUNTRY_CODE: "siteCountry",
});

export const ALLERGENS = [
  {
    id: "b085c52d-bd03-47fd-a35c-7c55338b0508",
    name: "milk",
  },
  {
    id: "2588271c-baca-4d57-a805-b66635b6a6b6",
    name: "nuts",
  },
  {
    id: "857a5b05-c8fa-41e8-a446-5356e50e6df7",
    name: "wheat",
  },
  {
    id: "58fcadd2-d5ff-4e34-af6d-7de3f52de568",
    name: "egg",
  },
  {
    id: "51b09443-9587-4a12-a3f8-ffd250fcd85d",
    name: "soy",
  },
  {
    id: "b1705138-933c-45c2-a15d-9cc263387531",
    name: "sesame",
  },
  {
    id: "0b8d7304-2d37-47a0-be9b-f94f5303c90c",
    name: "sulphite",
  },
  {
    id: "5e7e2a1f-f4b7-4d8a-adbd-c9b8d07d7397",
    name: "mustard",
  },
  {
    id: "e9c6c296-d205-496a-a971-b5922175134f",
    name: "peanuts",
  },
  {
    id: "beae650b-bd6d-4d77-baab-6c0e004d65f4",
    name: "celery",
  },
  {
    id: "e10503c1-947e-4a6d-9f26-700ace255eb1",
    name: "fish",
  },
  {
    id: "30565e30-ebc8-4a71-8c70-9176cfde7fe5",
    name: "mollusc",
  },
  {
    id: "ce5547d4-625f-4b10-aa1b-97fc8ed465e6",
    name: "lupin",
  },
  {
    id: "5c3446c5-d5d5-4ac3-a5f3-e20d7ae8dc5a",
    name: "crustacean",
  },
];

export const DaysToExpiredCertificates = 90;
