/* istanbul ignore file */
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Typography, Icon, Button } from "@trace-one/design-system";

import { TradeItemData } from "models";

import { getManufacturedItems } from "reduxStore/productDetails/asyncActions";
import { ManufacturedItemState } from "reduxStore/productDetails/models";
import {
  selectUserOwningCompanyId,
  selectUserSelectedCompany,
} from "reduxStore/user/selectors";

import { ManufacturingItemStatus } from "shared/constants";

import styles from "../../ProductList.module.less";

interface ProductExpandedRowProps {
  tradeItem?: TradeItemData;
  refresh?: number;
}

const ProductExpandedRow: React.FC<ProductExpandedRowProps> = ({
  tradeItem,
  refresh,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const { companyId: selectedCompanyId } =
    useSelector(selectUserSelectedCompany) ?? {};
  const [manufacturedItems, setManufacturedItems] =
    useState<ManufacturedItemState[]>(null);
  const fetchManufacturedItems = async () => {
    setManufacturedItems(null);
    const result = await getManufacturedItems({
      tradeItemId: tradeItem.id,
      ownerCompanyId: selectedCompanyId || ownerCompanyId,
    });
    setManufacturedItems(
      result?.filter(
        m => m.manufacturedItemStatus !== ManufacturingItemStatus.INACTIVE
      )
    );
  };

  useEffect(() => {
    fetchManufacturedItems();
  }, [refresh]);

  if (!manufacturedItems) {
    return null;
  }

  return (
    <div className={styles.expandedRowSection}>
      {manufacturedItems.length ? (
        <>
          <Typography color="grey-5" variant="heading-xxs">
            {manufacturedItems.length > 1
              ? intl.formatMessage(
                  {
                    id: "productsListPage..expandedRow.activeManufacturedItems",
                  },
                  {
                    count: manufacturedItems.length,
                  }
                )
              : intl.formatMessage({
                  id: "productsListPage..expandedRow.activeManufacturedItem",
                })}
          </Typography>
          {manufacturedItems.map((manuItem, i) => {
            return (
              <div
                key={manuItem.id}
                className={styles.expandedManufacturedItem}
              >
                <Icon name="hierarchy-link" size="medium" />
                <div>
                  <Typography
                    color="grey-5"
                    data-test-id={`${tradeItem.itemName}-${manuItem.manufacturedItemName}-${i}`}
                    variant="heading-xxs"
                  >
                    {manuItem.manufacturedItemName || tradeItem.itemName}
                  </Typography>
                  {manuItem?.certificateIds.length !== 0 &&
                  ownerCompanyId === tradeItem?.ownerCompanyId ? (
                    <div className={styles.certificateLink}>
                      <Typography variant="body-s">
                        {manuItem.supplierCompanyId?.companyDisplayName}{" "}
                        {!!manuItem.certificateIds.length &&
                          intl.formatMessage(
                            {
                              id: "productsListPage..expandedRow.addedCertificates.shortMsg",
                            },
                            {
                              count: manuItem.certificateIds.length,
                            }
                          )}
                      </Typography>
                      {!!manuItem.certificateIds.length && (
                        <Button
                          type="link"
                          onClick={() =>
                            history.push({
                              pathname: `/products/manufacturedItem/${manuItem?.tradeItemId}/${manuItem?.id}`,
                            })
                          }
                        >
                          {manuItem?.certificateIds?.length}{" "}
                          {intl
                            .formatMessage({
                              id:
                                manuItem.certificateIds.length > 1
                                  ? "general.certificates"
                                  : "general.certificate",
                            })
                            .toLowerCase()}
                        </Button>
                      )}
                    </div>
                  ) : (
                    <Typography variant="body-s">
                      {manuItem.supplierCompanyId?.companyDisplayName}{" "}
                      {!!manuItem.certificateIds.length && (
                        <>
                          {intl.formatMessage(
                            {
                              id: "productsListPage..expandedRow.addedCertificates.shortMsg",
                            },
                            {
                              count: manuItem.certificateIds.length,
                            }
                          )}{" "}
                          {manuItem?.certificateIds?.length}{" "}
                          {intl
                            .formatMessage({
                              id:
                                manuItem.certificateIds.length > 1
                                  ? "general.certificates"
                                  : "general.certificate",
                            })
                            .toLowerCase()}
                        </>
                      )}
                    </Typography>
                  )}
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <Typography variant="heading-xxs">
          {intl.formatMessage({
            id: "productsListPage.expandedRow.noManufacturedItems",
          })}
        </Typography>
      )}
    </div>
  );
};

export default ProductExpandedRow;
