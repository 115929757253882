import { FormattedMessage } from "react-intl";

import { CompanySettings } from "@trace-one/api-clients.pmd/dist/models/company-settings";
import { Typography } from "@trace-one/design-system";
import { FormInstance } from "antd";

import { PmdAPI } from "apis";

import { getUserHasNotResponsabilityError } from "components/ContactsInputGroup";
import { ErrorCode, hasErrorOccurred } from "shared/errors";
import { FormItemFeedback } from "shared/typings";

import IdentificationStyles from "../components/IdentificationSection/IdentificationSection.module.less";
import { ProductFormValues } from "../models";

interface GetErrorProps {
  ownerCompanyId?: string;
  values?: ProductFormValues;
  errorMessage?: string;
  companySettings?: CompanySettings;
}

async function getGtinAlreadyUsedError({
  ownerCompanyId,
  values,
}: GetErrorProps): Promise<{ gtin: FormItemFeedback }> {
  let productName;
  try {
    const { data } = await PmdAPI.getTradeItemsByFilters(
      {},
      { GTIN: values.gtin, ownerCompanyId }
    );
    productName = data?.tradeItems[0]?.itemName;
  } catch (err) {}

  return {
    gtin: {
      hasFeedback: true,
      validateStatus: "warning",
      help: (
        <span data-test-id="md-warning-gtin-is-not-unique">
          <FormattedMessage id="productForm.productIdentification.GTIN.errors.usedByAnotherProduct" />{" "}
          {productName}
        </span>
      ),
    },
  };
}

async function getGtinInvalidError(): Promise<{ gtin: FormItemFeedback }> {
  return {
    gtin: {
      hasFeedback: true,
      validateStatus: "error",
      help: (
        <span data-test-id="md-warning-gtin-is-not-valid">
          <FormattedMessage
            id="productDetails.GTIN.invalid.description"
            values={{
              b: chunks => (
                <Typography
                  variant="link-s"
                  component="a"
                  target="_blank"
                  className={IdentificationStyles.gtinLink}
                  href="https://www.gs1.org/services/how-calculate-check-digit-manually"
                >
                  {chunks}
                </Typography>
              ),
            }}
          />
        </span>
      ),
    },
  };
}

async function getParticularProductCategoryLevelError(companySettings: {
  minimumLevelProductCategoryRequired: any;
}): Promise<{
  productCategories: FormItemFeedback;
}> {
  return {
    productCategories: {
      validateStatus: "error",
      help: (
        <span data-test-id="md-warning-product-category-level-is-not-valid">
          <FormattedMessage
            id="productForm.productIdentification.productCategory.errorMsg"
            values={{
              minimumLevelProductCategoryRequired:
                companySettings?.minimumLevelProductCategoryRequired,
            }}
          />
        </span>
      ),
    },
  };
}

async function getBrandArchivedUsedError(): Promise<{
  brandId: FormItemFeedback;
}> {
  return {
    brandId: {
      hasFeedback: true,
      validateStatus: "warning",
      help: (
        <span data-test-id="md-warning-brand-archived-used">
          <FormattedMessage id="brandNotification.archivedSuccess" />
        </span>
      ),
    },
  };
}

async function getCategoryNotBelongError(): Promise<{
  productCategories: FormItemFeedback;
}> {
  return {
    productCategories: {
      hasFeedback: false,
      validateStatus: "error",
      help: (
        <span data-test-id="md-warning-category-not-belong">
          <FormattedMessage id="productDetails.invalid.createProduct" />
        </span>
      ),
    },
  };
}

async function getHarmonizedCodeIsNumericValue(): Promise<{
  harmonizedCode: FormItemFeedback;
}> {
  return {
    harmonizedCode: {
      hasFeedback: false,
      validateStatus: "error",
      help: (
        <span data-test-id="md-warning-harmonizedCode-is-numeric-value">
          <FormattedMessage id="productForm.tradeItem.harmonizedCode.toast.error.msg" />
        </span>
      ),
    },
  };
}

async function getBrandIdRequired(): Promise<{
  brandId: FormItemFeedback;
}> {
  return {
    brandId: {
      hasFeedback: false,
      validateStatus: "error",
      help: (
        <span data-test-id="md-error-brandId-is-required">
          <FormattedMessage id="general.valueIsRequired" />
        </span>
      ),
    },
  };
}

async function getMultiPackRequired(): Promise<{
  multipack: FormItemFeedback;
}> {
  return {
    multipack: {
      hasFeedback: false,
      validateStatus: "error",
      help: (
        <span data-test-id="md-error-multipack-is-required">
          <FormattedMessage id="general.valueIsRequired" />
        </span>
      ),
    },
  };
}

export default async function getFailedSubmitResult({
  error,
  values,
  ownerCompanyId,
  setSubmitResult,
  setActiveTab,
  form,
  companySettings,
}: {
  error: any;
  values: ProductFormValues;
  setActiveTab: (value: number) => void;
  setSubmitResult: (value: {}) => void;
  ownerCompanyId: string;
  form: FormInstance<ProductFormValues>;
  companySettings: any;
}) {
  if (
    hasErrorOccurred({
      error,
      errorCode: ErrorCode.GTIN_INVALID,
    })
  ) {
    form.scrollToField("gtin", { behavior: "smooth", block: "center" });
    return await getGtinInvalidError();
  }

  if (
    hasErrorOccurred({
      error,
      errorCode: ErrorCode.PARTICULAR_CATEGORY_LEVEL_REQUIRED,
    })
  ) {
    form.scrollToField("productCategories", {
      behavior: "smooth",
      block: "center",
    });
    return await getParticularProductCategoryLevelError(companySettings);
  }

  if (
    hasErrorOccurred({
      error,
      errorCode: ErrorCode.GTIN_ALREADY_DEFINED_FOR_TRADE_ITEM,
    })
  ) {
    form.scrollToField("gtin", { behavior: "smooth", block: "center" });
    return await getGtinAlreadyUsedError({
      values,
      ownerCompanyId,
    });
  }

  if (
    hasErrorOccurred({
      error,
      errorCode: ErrorCode.ADD_PRODUCT_IN_ARCHIVE_BRAND,
    })
  ) {
    form.scrollToField("brandId", { behavior: "smooth", block: "center" });
    return getBrandArchivedUsedError();
  }

  if (
    hasErrorOccurred({
      error,
      errorCode: ErrorCode.USER_WITH_NO_RESPONSABILITY,
    })
  ) {
    setActiveTab(0);
    setSubmitResult(prev => ({ ...prev, ...error?.response?.data?.error }));
    document.getElementById("contact-scroller").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });

    return await getUserHasNotResponsabilityError(error?.response?.data?.error);
  }

  if (
    hasErrorOccurred({
      error,
      errorCode: ErrorCode.PRODUCT_CATEGORY_NOT_BELONG,
    })
  ) {
    form.scrollToField("productCategories", {
      behavior: "smooth",
      block: "center",
    });
    return getCategoryNotBelongError();
  }

  if (
    hasErrorOccurred({
      error,
      errorCode: ErrorCode.HARMONIZED_CODE_IS_NUMERIC_VALUE,
    })
  ) {
    form.scrollToField("harmonizedCode", {
      behavior: "smooth",
      block: "center",
    });
    return getHarmonizedCodeIsNumericValue();
  }

  if (
    hasErrorOccurred({
      error,
      errorCode: ErrorCode.BRAND_REQUIRED,
    })
  ) {
    form.scrollToField("brandId", {
      behavior: "smooth",
      block: "center",
    });
    return getBrandIdRequired();
  }

  if (
    hasErrorOccurred({
      error,
      errorCode: ErrorCode.MULTIPACK_REQUIRED,
    })
  ) {
    alert("ss");
    form.scrollToField("multipack", {
      behavior: "smooth",
      block: "center",
    });
    return getMultiPackRequired();
  }
}
