import React from "react";
import { CompanySwitcherProps } from "./types";
import classnames from "classnames";
import {
  ConditionalEllipsisWithTooltip,
  Typography,
  Divider,
  Icon,
  Spinner,
  Illustration,
} from "@trace-one/design-system";
import "./styles.less";

import { withLanguageData } from "IndexComponents";
import { getPrefixClassName } from "src/utils/general";
import { FormattedMessage } from "react-intl";
import { askAppAccessMessages } from "./translations/messages";
import useCompanySwitcher from "./hooks";
import frFr from "./translations/fr-FR.json";
import enUs from "./translations/en-US.json";
import deDe from "./translations/de-DE.json";
import esEs from "./translations/es-ES.json";
import ptPt from "./translations/pt-PT.json";
import itIt from "./translations/it-IT.json";
import nlBe from "./translations/nl-BE.json";

const DEFAULT_LANG = { ...enUs };
const enhance = withLanguageData({
  translations: {
    "en-US": DEFAULT_LANG,
    "fr-FR": { ...DEFAULT_LANG, ...frFr },
    "de-DE": { ...DEFAULT_LANG, ...deDe },
    "es-ES": { ...DEFAULT_LANG, ...esEs },
    "pt-PT": { ...DEFAULT_LANG, ...ptPt },
    "it-IT": { ...DEFAULT_LANG, ...itIt },
    "nl-BE": { ...DEFAULT_LANG, ...nlBe },
  },
});

const CompanySwitcher: React.FC<CompanySwitcherProps> = ({
  selectedCompanyId,
  userId,
  applicationTypeId,
  owningCompanyId,
  companyGroupId,
  owningCompanyName,
  onCompanySwitch,
}) => {
  const prefixClassName = getPrefixClassName("company-switcher");
  const { loading, companyGroupDetails, groupList } = useCompanySwitcher({
    companyGroupId,
    userId,
    applicationTypeId,
  });
  const companyDisplayName =
    owningCompanyId === selectedCompanyId
      ? owningCompanyName
      : groupList.find(({ companyId }) => companyId === selectedCompanyId)
        ?.companyDisplayName;

  if (loading) {
    return (
      <div className={`${prefixClassName}-spinner`}>
        <Spinner size="medium" />
      </div>
    );
  }

  return (
    <div className={`${prefixClassName}`}>
      <div className={`${prefixClassName}-heading`}>
        <div
          data-test-id="companySwitcher-heading-logo"
          className={`${prefixClassName}-heading-logo`}
        >
          <Illustration size={32} name="supplier" />
        </div>
        <div
          className={`${prefixClassName}-heading-info`}
          data-test-id="companySwitcher-heading-info"
        >
          <ConditionalEllipsisWithTooltip text={companyDisplayName}>
            <Typography variant="body-s">
              <FormattedMessage {...askAppAccessMessages.loggedInAs} />{" "}
              {companyDisplayName}
            </Typography>
          </ConditionalEllipsisWithTooltip>
          <ConditionalEllipsisWithTooltip text={companyGroupDetails?.groupName}>
            <Typography variant="body-xs">
              <FormattedMessage {...askAppAccessMessages.linkedTo} />{" "}
              {companyGroupDetails?.groupName}
            </Typography>
          </ConditionalEllipsisWithTooltip>
        </div>
      </div>
      <Divider color="grey-3" height={16} />
      <ul className={`${prefixClassName}-companylist`}>
        {groupList.map(company => (
          <li
            key={company.companyId}
            className={classnames(
              `${prefixClassName}-companylist-item`,
              {
                selectedItem: company.companyId === selectedCompanyId,
              }
            )}
          >
            <Typography
              {...(onCompanySwitch ? { onClick: () => onCompanySwitch(company) } : {})}
              data-test-id={`companyList-item-${company.companyDisplayName}`}
              variant="link-m"
            >
              {company.companyDisplayName}
            </Typography>
            {company.companyId === selectedCompanyId && (
              <Icon name="check" size="small" color="primary" />
            )}
          </li>
        ))}
      </ul>
      {selectedCompanyId !== owningCompanyId && (
        <>
          <Divider color="grey-3" dashed />
          <div className={`${prefixClassName}-footer`}>
            <Icon name="exit" size="small" color="primary" />
            <Typography
              {...(onCompanySwitch ? { onClick: () => onCompanySwitch({ companyId: owningCompanyId }) } : {})}
              data-test-id="companyList-log-back"
              variant="link-m"
            >
              <FormattedMessage {...askAppAccessMessages.logBackAs} />{" "}
              {owningCompanyName}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

export default enhance(CompanySwitcher);
