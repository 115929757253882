import React, { useEffect, useState } from "react";
import { Modal, Typography, Spinner } from "@trace-one/design-system";
import { withLanguageData } from "IndexComponents";
import { getPrefixClassName } from "src/utils/general";
import "./styles.less";
import { CertificateModalProps } from "./types";
import frFr from "./translations/fr-FR.json";
import enUs from "./translations/en-US.json";
import deDe from "./translations/de-DE.json";
import esEs from "./translations/es-ES.json";
import ptPt from "./translations/pt-PT.json";
import itIt from "./translations/it-IT.json";
import nlBe from "./translations/nl-BE.json";
import { useIntl } from "react-intl";
import { certificateModalMessages } from "./translations/messages";
import CertificateForm from "./components/CertificateForm";
import CertificationsList from "./components/CertificationsList";
import { createCertificate } from "../../apis/certification";
import useExtractedFromAIFileData from "./hooks/useExtractedFromAIFileData";
import { useModalVisibility } from "src/hooks";

const DEFAULT_LANG = { ...enUs };
const enhance = withLanguageData({
  translations: {
    "en-US": DEFAULT_LANG,
    "fr-FR": { ...DEFAULT_LANG, ...frFr },
    "de-DE": { ...DEFAULT_LANG, ...deDe },
    "es-ES": { ...DEFAULT_LANG, ...esEs },
    "pt-PT": { ...DEFAULT_LANG, ...ptPt },
    "it-IT": { ...DEFAULT_LANG, ...itIt },
    "nl-BE": { ...DEFAULT_LANG, ...nlBe },
  },
});

const CertificateModal: React.FC<CertificateModalProps> = ({ useAI, onClose, open, objectLink, onCertificateCreated }) => {
  const intl = useIntl();
  const prefixClassName = getPrefixClassName("certificate-modal");
  const [isModalClosing, setIsModalClosing] = useState(false);
  const [notFoundCertificationError, setNotFoundCertificationError] = useState<boolean>();

  const { onCloseModal, isModalOpen } = useModalVisibility({
    defaultVisibility: open,
    onClose,
  });

  const [formData, setFormData] = useState({
    fileId: null,
    fileExtension: null,
    validFrom: null,
    validTo: null,
    grade: null,
    comments: null,
  });
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [selectedCertificationId, setSelectedCertificationId] = useState(null);

  const [isAIUsed, setIsAIUsed] = useState(() => {
    if (typeof window !== "undefined") {
      const saved = window.localStorage.getItem("useAI");
      return saved === "true";
    }
    return false;
  });

  const {
    extractedFileDataFromAI,
    isLoading,
  } = useExtractedFromAIFileData(formData.fileId, isAIUsed, setNotFoundCertificationError);

  const messages = [
    intl.formatMessage(certificateModalMessages.aiSpinnerExtraText1),
    intl.formatMessage(certificateModalMessages.aiSpinnerExtraText2),
    intl.formatMessage(certificateModalMessages.aiSpinnerExtraText3),
    intl.formatMessage(certificateModalMessages.aiSpinnerExtraText4),
  ];
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    if (isModalOpen) {
      setMessageIndex(0);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setMessageIndex((prevIndex) => {
          if (prevIndex >= messages.length - 1) {
            clearInterval(interval);
            return prevIndex;
          }
          return prevIndex + 1;
        });
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [isLoading, messages.length]);

  useEffect(() => {
    if (extractedFileDataFromAI) {
      const { id, grade, validFrom, validTo, comment } = extractedFileDataFromAI;

      setFormData({
        ...formData,
        grade,
        validFrom,
        validTo,
        comments: comment,
      });

      if (id) {
        setSelectedCertificationId(id);
      }
    }
  }, [extractedFileDataFromAI, setFormData, setSelectedCertificationId]);
  const handleAIChange = (e) => {
    const useAI = e.target.checked;
    setIsAIUsed(useAI);

    if (typeof window !== "undefined") {
      localStorage.setItem("useAI", useAI);
    }
  };

  const handleCreateCertificate = async () => {
    try {
      const certificateId = await createCertificate({
        certificationId: selectedCertificationId,
        fileId: formData.fileId,
        grade: formData.grade,
        validFrom: formData.validFrom,
        validTo: formData.validTo,
        comment: formData.comments,
      });

      if (onCertificateCreated) {
        onCertificateCreated(certificateId?.data);
      }

      handleModalClose();
    } catch (error) {
      console.log("error");
    }
  };
  const handleModalClose = () => {
    setIsModalClosing(true);

    setFormData({
      fileId: null,
      fileExtension: null,
      validFrom: null,
      validTo: null,
      grade: null,
      comments: null,
    });

    setSelectedCertificationId(null);
    setNotFoundCertificationError(false);

    onCloseModal();
  };

  return (
    <Modal.Double
      title={intl.formatMessage(certificateModalMessages.title)}
      subtitleRight={intl.formatMessage(certificateModalMessages.certificationTitle)}
      size="xl"
      className={prefixClassName}
      open={isModalOpen}
      onCancel={handleModalClose}
      leftContent={
          <div className={`${prefixClassName}-spin-wrapper`}>
            <CertificateForm
                useAI={useAI}
                setButtonDisabled={setButtonDisabled}
                formData={formData}
                setFormData={setFormData}
                isAIUsed={isAIUsed}
                handleAIChange={handleAIChange}
                extractedFileDataFromAI={extractedFileDataFromAI}
                setIsModalClosing={setIsModalClosing}
                isModalClosing={isModalClosing}
            />
            {isLoading && (
                <div className={`${prefixClassName}-spin-wrapper-spinner`}>
                  <Spinner size="medium" />
                  <Typography variant="body-l" style={{ marginTop: "12px", color: "black" }}>
                    {messages[messageIndex]}
                  </Typography>
                </div>
            )}
          </div>
      }
      rightContent={<CertificationsList
          setSelectedCertificationId={setSelectedCertificationId}
          selectedCertificationId={selectedCertificationId}
          extractedFileDataFromAI={extractedFileDataFromAI}
          isAIUsed={isAIUsed}
          objectLink={objectLink}
          isModalClosing={isModalClosing}
          aiIsLoading={isLoading}
          notFoundCertificationError={notFoundCertificationError}
          setNotFoundCertificationError={setNotFoundCertificationError}
      />}
      primaryButtonText={intl.formatMessage(certificateModalMessages.addCertificateBtn)}
      primaryButtonProps={{
        disabled: (buttonDisabled || selectedCertificationId === null),
      }}
      onPrimaryButtonClick={handleCreateCertificate}
      data-test-id={`${prefixClassName}`}
    />
  );
};

export default enhance(CertificateModal);
