import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Heading,
  Button,
  Paragraph,
  Tooltip,
  TagV1 as Tag,
  Typography,
} from "@trace-one/design-system";
import { SectionsIds } from "pages/Products/components/ProductForm/constants";

import usePermissions from "core/oidc/usePermissions";

import { selectProductDetailsData } from "reduxStore/productDetails/selectors";
import {
  selectBrandsData,
  selectLanguagesData,
  selectNetContentMeasuresData,
  selectCustomProductTypes,
} from "reduxStore/shared/selectors";

import Label from "components/Label";
import Panel from "components/Panel";
import TabPage from "components/TabPage";
import { TradeItemStatus } from "shared/constants";
import { capitalizeText } from "shared/utils/capitalizeFullText";

import { selectIsRetailer } from "../../../../../../../reduxStore/oidc/selectors";

import styles from "./ProductIdentification.module.less";

const CategoryLine = category => {
  const categoryLine = [
    category.parent4CategoryItemName &&
      `${category.parent4CategoryItemName} > `,
    category.parent3CategoryItemName &&
      `${category.parent3CategoryItemName} > `,
    category.parent2CategoryItemName &&
      `${category.parent2CategoryItemName} > `,
    category.parent1CategoryItemName &&
      `${category.parent1CategoryItemName} > `,
    category.categoryItemName,
  ].join("");

  return categoryLine;
};

const MainInformation = () => {
  const intl = useIntl();
  const history = useHistory();
  const isRetailer = useSelector(selectIsRetailer);
  const { productData } = useSelector(selectProductDetailsData);
  const { data: customProductTypes } = useSelector(selectCustomProductTypes);
  const { productsWrite } = usePermissions();
  const { data: brands } = useSelector(selectBrandsData);
  const { data: netContentMeasures } = useSelector(
    selectNetContentMeasuresData
  );
  const productIsDisabled =
    productData.tradeItemStatusId === TradeItemStatus.INACTIVE;
  const brandData = brands.filter(item => item.id === productData.brandId);
  const languagesData = useSelector(selectLanguagesData)?.data;

  const languagesMap = languagesData?.reduce<{ [itemCode: string]: string }>(
    (prev, current) => ({
      ...prev,
      [current.itemCode]: current.text,
    }),
    {}
  );
  const netContentLabel = ({ value, unitOfMeasure }) => {
    const text = netContentMeasures?.find(
      ({ id }) => id === unitOfMeasure
    )?.text;
    return `${value} ${text}`;
  };

  const ProductArchivedLabel = ({ tradeItemStatusId }) => {
    if (tradeItemStatusId === TradeItemStatus.ACTIVE) {
      return (
        <Paragraph size="m">
          <FormattedMessage id="general.no" />
        </Paragraph>
      );
    }

    if (tradeItemStatusId === TradeItemStatus.INACTIVE) {
      return (
        <Paragraph size="m">
          <FormattedMessage id="general.yes" />
        </Paragraph>
      );
    }
    return <>-</>;
  };

  const GetTagName = () => {
    return (
      <div className={styles.brandTag}>
        <Paragraph size="m">{brandData[0]?.brandName} </Paragraph>
        {brandData[0] && !brandData[0]?.isActive && (
          <Tag
            label={intl.formatMessage({ id: "general.archived" })}
            color="grey"
            mode="light"
          />
        )}
      </div>
    );
  };
  return (
    <Panel>
      <TabPage.SecondaryTitle
        label={
          <Heading size="xs">
            {capitalizeText(
              intl.formatMessage({ id: "productDetails.productIdentification" })
            )}
          </Heading>
        }
      />
      {!productIsDisabled && productsWrite && (
        <Button
          type="link"
          className={styles.iconBtn}
          iconName="edit"
          iconPlacement="left"
          data-test-id="product-identification-edit"
          onClick={() => {
            history.push({
              pathname: `/products/edit/${productData.id}`,
              hash: SectionsIds.IDENTIFICATION,
            });
          }}
        >
          {intl.formatMessage({ id: "general.edit" })}
        </Button>
      )}
      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "general.name" })}
          </Heading>
        }
        testId="prodDetails-name"
      >
        <Paragraph size="m">
          <Tooltip
            text={productData.tradeItemName}
            showFullText
            placement="top"
          >
            <div className={styles.productDetailsParagraph}>
              {productData.tradeItemName}
            </div>
          </Tooltip>
        </Paragraph>
      </Label>

      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "general.brand" })}
          </Heading>
        }
        testId="prodDetails-brand"
      >
        <GetTagName />
      </Label>

      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "productDetails.netContents" })}
          </Heading>
        }
        testId="prodDetails-netContents"
      >
        <Paragraph size="m">
          {productData.netContents?.map(({ value, unitOfMeasure }, idx) => {
            const label = netContentLabel({ value, unitOfMeasure });
            return <div key={idx}>{label}</div>;
          })}
        </Paragraph>
      </Label>

      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "productDetails.GTIN" })}
          </Heading>
        }
        testId="prodDetails-GTIN"
      >
        <Paragraph size="m">{productData.gtin} </Paragraph>
      </Label>

      {productData.customProductTypeId && (
        <Label
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "productDetails.customProductType" })}
            </Heading>
          }
          testId="prodDetails-customProductType"
        >
          <Paragraph size="m">
            {
              customProductTypes?.find(
                c => c.id === productData.customProductTypeId
              )?.text
            }{" "}
          </Paragraph>
        </Label>
      )}

      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "productDetails.productCategory" })}
          </Heading>
        }
        testId="prodDetails-productCategory"
      >
        <Paragraph size="m">
          {(() => {
            const categoryLines = productData.categories
              ?.map(category => CategoryLine(category))
              .sort(function (a, b) {
                return a.localeCompare(b);
              });
            return categoryLines?.map(category => (
              <Typography variant="body-regular">{category}</Typography>
            ));
          })()}
        </Paragraph>
      </Label>
      {isRetailer && (
        <Label
          label={
            <Heading size="xxs">
              {intl.formatMessage({ id: "general.harmonizedCode" })}
            </Heading>
          }
          testId="prodDetails-harmonizedCode"
        >
          <Paragraph size="m">
            <div className={styles.productDetailsParagraph}>
              {productData?.harmonizedCode}
            </div>
          </Paragraph>
        </Label>
      )}

      {productData.productLanguage !== null && (
        <Label
          label={
            <Heading size="xxs">
              {intl.formatMessage({
                id: "productForm.productIdentification.productLanguage",
              })}
            </Heading>
          }
          testId="prodDetails-productLanguage"
        >
          <Paragraph size="m">
            <Paragraph size="m">
              {languagesMap[productData.productLanguage]}
            </Paragraph>
          </Paragraph>
        </Label>
      )}

      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "productDetails.baseUnit" })}
          </Heading>
        }
        testId="prodDetails-baseUnit"
      >
        <Paragraph size="m">
          {productData.isTradeItemBaseUnit !== null && (
            <FormattedMessage
              id={
                productData.isTradeItemBaseUnit ? "general.yes" : "general.no"
              }
            />
          )}
        </Paragraph>
      </Label>

      {productData.multipack !== null && (
        <Label
          label={
            <Heading size="xxs">
              {intl.formatMessage({
                id: "productForm.productIdentification.multiPack",
              })}
            </Heading>
          }
          testId="prodDetails-multipack"
        >
          <Paragraph size="m">
            {
              <FormattedMessage
                id={productData.multipack ? "general.yes" : "general.no"}
              />
            }
          </Paragraph>
        </Label>
      )}

      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "productDetails.consumerUnit" })}
          </Heading>
        }
        testId="prodDetails-consumerUnit"
      >
        <Paragraph size="m">
          {productData.isConsumerUnit !== null && (
            <FormattedMessage
              id={productData.isConsumerUnit ? "general.yes" : "general.no"}
            />
          )}
        </Paragraph>
      </Label>

      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "productDetails.isProductArchived" })}
          </Heading>
        }
        testId="prodDetails-isProductArchived"
      >
        <ProductArchivedLabel
          tradeItemStatusId={productData.tradeItemStatusId}
        />
      </Label>
    </Panel>
  );
};

export default MainInformation;
