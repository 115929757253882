import { AxiosInstancesManager } from "../../configs/AxiosInstancesManager";
import { CartificateCreate, CertificationDetails, CertificationsListData } from "./types";
export const fetchCertificationsList = (
  languageCode?: string,
  objectLink?: string,
  searchTerm?: string,
  pageNumber?: number,
  pageSize?: number
) => {
  const catalogueInstance = AxiosInstancesManager.getCertification();

  return catalogueInstance.get<CertificationsListData>(
      `/api/certifications`,
      {
        params: { languageCode, objectLink, searchTerm, pageNumber, pageSize },
      }
  );
};
export const fetchCertificationDetails = (certificationId: string, languageCode?: string) => {
  const catalogueInstance = AxiosInstancesManager.getCertification();

  return catalogueInstance.get<CertificationDetails>(
      `/api/certifications/${certificationId}`,
      {
        params: { languageCode },
      }
  );
};

export const createCertificate = (data?: CartificateCreate) => {
  const catalogueInstance = AxiosInstancesManager.getCertification();

  return catalogueInstance.post(`/api/certificates`, data);
};
