import React, { useContext } from "react";
import SectionName from "./SectionName";
import { SectionProps } from "./types";
import "./styles.less";
import { SidebarContext } from "../../../../context/SidebarContextProvider";
import { Badge, Icon } from "@trace-one/design-system";
import Status from "../../../Status";

const Section: React.FC<SectionProps> = ({
  section,
  isSectionNameDisabled,
}) => {
  const {
    onClickSubSection,
    selectedSubSectionId,
    showStatusDot,
    showSubectionLink,
  } = useContext(SidebarContext);

  const sectionName = section?.sectionName;
  const isLinked = section?.isLinked;

  return (
    <>
      <div className="section">
        <div data-test-id="statusDot">
          {showStatusDot && <Status.Dot/>}
        </div>
        <div data-test-id="sectionName">
          <SectionName
            sectionName={sectionName}
            onClick={() => onClickSubSection(
              section.sectionId
            )}
            isSelected={section?.sectionId === selectedSubSectionId}
            disabled={isSectionNameDisabled}
          />
        </div>
        {showSubectionLink && (
                <div data-test-id="menu-item-link-icon">
                  <Icon
                    name={isLinked ? "linked" : "not-linked"}
                    size="medium"
                  />
                </div>
        )}
        {
          section?.badge?.showBadge &&
          <div data-test-id="sideMenuBadgeContainer">
            <Badge count={section?.badge?.count } overflowCount={section?.badge?.overflowCount} />
          </div>
        }
    </div>
    </>

  );
};

export default Section;
