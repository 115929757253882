import React, { useContext } from "react";
import { Button, Heading } from "@trace-one/design-system";
import "./styles.less";
import { SidebarContext } from "../../context/SidebarContextProvider";
import { SidebarHeaderProps } from "./types";

const SidebarHeader: React.FC<SidebarHeaderProps> = ({ sideBarHeaderText, showHeaderText }) => {
  const { isMinified, toggleMinified } = useContext(SidebarContext);
  const buttonIcon = isMinified ? "arrow-double-right" : "arrow-double-left";
  const ariaLabel = isMinified ? "Expand sidebar" : "Minimize sidebar";

  return (
    <div className={isMinified ? "moduleSidebarNavigatorHeaderMinified" : "moduleSidebarNavigatorHeader"} data-test-id="moduleSidebarNavigatorHeader">
      {isMinified || !showHeaderText
        ? null
        : (
        <Heading size="xs">{sideBarHeaderText}</Heading>
          )}

      <div className="headerIcon" data-test-id = "moduleSidebarNavigatorHeaderIcon">
        <Button
          type="tertiary"
          iconName={buttonIcon}
          color="primary"
          onClick={toggleMinified}
          data-test-id="minify-menu-button"
          aria-label={ariaLabel}
        />
      </div>
    </div>
  );
};

export default SidebarHeader;
