import { useEffect, useState } from "react";
import { getCompanyGroupById, getCompanyGroupList } from "src/apis/cumd";
import { CompanyGroup, CompanyGroupList } from "src/models/companyDetails";

const useCompanySwitcher = ({
  companyGroupId,
  userId,
  applicationTypeId,
}: {
  companyGroupId: string;
  userId: string;
  applicationTypeId: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [companyGroupDetails, setCompanyGroupDetails] = useState<CompanyGroup>(null);
  const [groupList, setGroupList] = useState<CompanyGroupList[]>([]);

  const fetchDetails = async () => {
    try {
      setLoading(true);

      if (userId && applicationTypeId) {
        const {
          data: groupList,
        } = await getCompanyGroupList({ userId, applicationTypeId });
        setGroupList(groupList);
      }
      const {
        data: groupDetails,
      } = await getCompanyGroupById(companyGroupId);

      setCompanyGroupDetails(groupDetails);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!companyGroupId) {
      return;
    }

    fetchDetails();
  }, [companyGroupId, userId, applicationTypeId]);

  return {
    loading,
    companyGroupDetails,
    groupList,
  };
};

export default useCompanySwitcher;
