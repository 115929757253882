import React, { useContext } from "react";
import classNames from "classnames";
import { Button, Icon, Tooltip, Badge } from "@trace-one/design-system";
import { ItemProps } from "./types";
import "./styles.less";
import { SidebarContext } from "../../context/SidebarContextProvider";
import Sections from "./Sections";
import ConditionalWrapper from "src/components/ConditionalWrapper";

const Item: React.FC<ItemProps> = ({
  section,
}) => {
  const {
    selectedSectionId,
    isMinified,
    setSelectedSectionId,
    showSectionIcons,
    shouldSectiontoggle,
  } = useContext(SidebarContext);

  const prefixCls = "menuItem";

  const onClickSection = (sectionId: string) => {
    setSelectedSectionId((prev) => {
      if (shouldSectiontoggle) {
        return prev === sectionId ? null : sectionId;
      } else {
        return sectionId;
      }
    });
  };

  const {
    sectionId,
    sectionName,
    showSectionLink,
    isLinked,
    sections,
    badge,
    sectionIcon,
  } = section;

  const isSelected = selectedSectionId === sectionId;
  const color = isSelected ? "secondary-dark" : "grey-5";

  return (
    <>
      <div
        className={classNames(prefixCls, {
          [`${prefixCls}selected`]: isSelected,
          [`${prefixCls}linked`]: isLinked,
        })}
        data-test-id={`menu-item-${sectionId}`}
      >
        {showSectionIcons && (
          <ConditionalWrapper
            condition={isMinified}
            wrapper={(children) => (
              <Tooltip
                text={sectionName}
                zIndex={6}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {children}
              </Tooltip>
            )}
          >
            <div data-test-id="menu-item-icon">
              <Icon
                name={sectionIcon?.iconName}
                size={sectionIcon?.iconSize}
                color={color}
                onClick={isMinified ? () => onClickSection(sectionId) : undefined}
              />
            </div>

          </ConditionalWrapper>
        )}

        {!isMinified && (
          <div className={`${prefixCls}details`}>
            <Button
              type="link"
              linkColor={color}
              onClick={() => onClickSection(sectionId)}
              data-test-id="menu-item-name"
            >
            <div className={`${prefixCls}sectionName`}>{sectionName}</div>
            </Button>
            <div className={`${prefixCls}sectionEnd`}>
              {showSectionLink && (
                <div data-test-id="menu-item-link-icon">
                  <Icon
                    name={isLinked ? "linked" : "not-linked"}
                    size="medium"
                    color={color}
                  />
                </div>
              )}
              {badge?.showBadge && (
                <div data-test-id="menu-item-badge">
                  <Badge
                    count={badge.count}
                    overflowCount={badge.overflowCount}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {(!isMinified && isSelected) || section.expand
        ? (
            <Sections sections={sections} />
          )
        : null}
    </>
  );
};

export default Item;
