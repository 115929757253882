import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Typography } from "@trace-one/design-system";
import moment from "moment";

import { selectSupplierProductDetailsData } from "reduxStore/productDetails/selectors";

import styles from "./bannerDetails.module.less";

const BannerDetails = () => {
  const { formatMessage } = useIntl();
  const {
    productSupplierData: { data: productData },
  } = useSelector(selectSupplierProductDetailsData);

  return (
    <div className={styles.root}>
      <div className={styles.detailsBanner}>
        <div
          data-test-id="product-retailer-name"
          className={styles.mainDetailLabel}
        >
          <Typography variant="heading-xxs">
            {formatMessage({
              id: "general.retailerName",
            })}
          </Typography>
          <Typography variant="body-regular">
            {productData.companyData?.companyDisplayName}
          </Typography>
        </div>
        <div
          data-test-id="product-creation-date"
          className={styles.mainDetailLabel}
        >
          <Typography variant="heading-xxs">
            {formatMessage({
              id: "general.creationDate",
            })}
          </Typography>
          <Typography variant="body-regular">
            {productData.createdAt
              ? moment(productData.createdAt).format("DD/MM/YYYY")
              : "-"}
          </Typography>
        </div>

        <div data-test-id="product-name" className={styles.mainDetailLabel}>
          <Typography variant="heading-xxs">
            {formatMessage({
              id: "general.name",
            })}
          </Typography>
          <Typography variant="body-regular">
            {productData.tradeItemData?.itemName
              ? productData.tradeItemData?.itemName
              : "-"}
          </Typography>
        </div>

        <div data-test-id="product-brand" className={styles.mainDetailLabel}>
          <Typography variant="heading-xxs">
            {formatMessage({
              id: "general.brand",
            })}
          </Typography>
          <Typography variant="body-regular">
            {productData.brandData?.brandName
              ? productData.brandData?.brandName
              : "-"}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default BannerDetails;
