import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from "./initialState";

export const slice = createSlice({
  name: "oidc",
  initialState,
  reducers: {
    setOidcAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setOidcCompanyGroupId: (state, action) => {
      state.companyGroupId = action.payload;
    },
    setOidcPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setOidcCompanyActivityToStore: (state, action: PayloadAction<string>) => {
      state.companyActivity = parseInt(action.payload);
    },
    setOidcGlobalUserId: (state, action) => {
      state.globalUserId = action.payload;
    },
  },
});

export const {
  setOidcAccessToken,
  setOidcPermissions,
  setOidcCompanyActivityToStore,
  setOidcGlobalUserId,
  setOidcCompanyGroupId,
} = slice.actions;

export default slice.reducer;
