import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Icon,
  Heading,
  Button,
  Toggle,
  TagV1 as Tag,
} from "@trace-one/design-system";
import { Tree, Empty } from "@trace-one/react-components";

import { RlmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import { fetchCategoryItemDetails } from "reduxStore/categoryItemForm/asyncActions";
import { selectCategoryItemFormData } from "reduxStore/categoryItemForm/selectors";
import { clearCategoryItemForm } from "reduxStore/categoryItemForm/slice";
import {
  selectClassificationCompanyId,
  selectGenericCategory,
} from "reduxStore/classificationDetails/selectors";
import { fetchClassificationDirectChilds } from "reduxStore/classificatonDirectChilds/asyncActions";
import { selectClassificationDirectChildsItems } from "reduxStore/classificatonDirectChilds/selectors";
import { fetchLanguages } from "reduxStore/shared/asyncActions";
import {
  selectUserLanguageCode,
  selectUserOwningCompanyId,
  selectUserSelectedCompany,
} from "reduxStore/user/selectors";

import Spinner from "components/Spinner";

import styles from "./ClassificationDirectChilds.module.less";

interface ClassificationDirectChildsProps {
  handleCategoryDetail: any;
  categoryValue: string;
  showCategory: boolean;
  isFormChangedValue: boolean;
  handleCategoryValue: any;
  handleEditModal: any;
  handleCategoryData: any;
  editModalData: EditModalData;
  handleFormChanged: any;
  categoryActive: boolean;
}

interface CategoryOptions {
  title: React.ReactNode;
  key: string;
  children?: any;
  isActive?: boolean;
}

interface EditModalData {
  isEditModal: boolean;
  selectedKey: Array<string>;
}

interface List {
  title?: React.ReactNode;
  key: string;
  children?: Array<CategoryOptions>;
  active?: boolean;
  checked?: boolean;
  dragOver?: boolean;
  dragOverGapBottom?: boolean;
  dragOverGapTop?: boolean;
  expanded?: boolean;
  halfChecked?: boolean;
  loaded?: boolean;
  loading?: boolean;
  pos?: string;
  selected?: boolean;
  icon?: any;
  isLeaf?: boolean;
}

interface SelectedNode {
  expanded: boolean;
  key: string;
}

const ClassificationDirectChilds: React.FC<ClassificationDirectChildsProps> = ({
  categoryActive,
  handleCategoryDetail,
  categoryValue,
  showCategory,
  isFormChangedValue,
  handleCategoryValue,
  handleEditModal,
  handleCategoryData,
  editModalData,
  handleFormChanged,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { categoryListsWrite } = usePermissions();
  const { classificationId } = useParams<{ classificationId?: string }>();
  const languageCode = useSelector(selectUserLanguageCode);
  const { companyId: selectedCompanyId } =
    useSelector(selectUserSelectedCompany) ?? {};
  const { classificationChildList, isClassificationChildLoading } = useSelector(
    selectClassificationDirectChildsItems
  );
  const { categoryItemData } = useSelector(selectCategoryItemFormData);
  const userCompanyId = useSelector(selectUserOwningCompanyId);
  const classificationCompanyId = useSelector(selectClassificationCompanyId);
  const isGenericCategory = useSelector(selectGenericCategory);
  const isSameCompanyID: boolean = userCompanyId === classificationCompanyId;
  const isDisabled =
    !categoryListsWrite || isGenericCategory || !isSameCompanyID;
  const showPopup = isFormChangedValue && showCategory;
  const [toggleActive, setToggleActive] = useState<boolean>(true);
  const inactiveTag = (
    <Tag
      className={styles.treeInactiveTag}
      label={intl.formatMessage({ id: "general.archived" })}
      size="small"
      color="grey"
      mode="light"
    />
  );

  const [treeOptions, setTreeOptions] = useState<Array<CategoryOptions>>([]);
  const [expandedKeys, setExpandedKeys] = useState<Array<string>>();
  const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);
  const [loadedKeys, setLoadedKeys] = useState<Array<string>>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(true);
  const [selectedNode, setSelectedNode] = useState<SelectedNode>({
    expanded: false,
    key: "",
  });
  const [showSubCategory, setShowSubCategory] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchLanguages({ languageCode }));
  }, []);

  let oldChildren: Array<CategoryOptions> = [];
  /* istanbul ignore next */
  const getOldChildrenData = (item: CategoryOptions) => {
    if (item.key === selectedNode.key) {
      oldChildren.push(item);
      return item;
    } else {
      if (item.children && item.key !== selectedNode.key) {
        item.children.forEach(data => {
          getOldChildrenData(data);
        });
      }
    }
  };

  useEffect(() => {
    if (categoryItemData) handleCategoryData(categoryItemData);
  }, [categoryItemData]);

  useEffect(() => {
    updateTreeOptions();
  }, [categoryValue]);

  const updateTreeOptions = async () => {
    let result = [
      {
        title: (
          <Button data-test-id={categoryValue} type="link">
            <div className={styles.classificationDetailsList}>
              {categoryValue}{" "}
            </div>
          </Button>
        ),
        key: "abc",
        isActive: true,
      },
    ];
    if (selectedNode.key !== "") {
      treeOptions.forEach(item => {
        getOldChildrenData(item);
      });
      if (oldChildren.length) {
        if (editModalData.isEditModal) {
          oldChildren[0].title = (
            <Button data-test-id={categoryValue} type="link">
              <div className={styles.classificationDetailsList}>
                {categoryValue}{" "}
              </div>
              {!oldChildren[0].isActive && inactiveTag}
            </Button>
          );
          setTreeOptions(origin =>
            updateTreeData(origin, selectedNode.key, oldChildren[0].children)
          );
        } else {
          let index = oldChildren[0]?.children?.findIndex(
            key => key.key === "abc"
          );
          if (index !== -1) {
            oldChildren[0].children[index].title = (
              <Button data-test-id={categoryValue} type="link">
                <div className={styles.classificationDetailsList}>
                  {categoryValue}{" "}
                </div>
                {!oldChildren[0].children[index].isActive && inactiveTag}
              </Button>
            );
            setTreeOptions(origin =>
              updateTreeData(origin, selectedNode.key, oldChildren[0].children)
            );
          } else {
            if (oldChildren[0].children.length > 0) {
              setTreeOptions(origin =>
                updateTreeData(origin, selectedNode.key, [
                  ...oldChildren[0].children,
                  result[0],
                ])
              );
            } else {
              setTreeOptions(origin =>
                updateTreeData(origin, selectedNode.key, result)
              );
            }
          }
        }
      } else {
        setTreeOptions(origin =>
          updateTreeData(origin, selectedNode.key, result)
        );
      }
    } else {
      if (categoryValue !== "") {
        let result = [
          {
            title: (
              <Button data-test-id={categoryValue} type="link">
                <div className={styles.classificationDetailsList}>
                  {categoryValue}{" "}
                </div>
              </Button>
            ),
            key: "abc",
            isActive: true,
          },
        ];
        let indexOptions = treeOptions.findIndex(key => key.key === "abc");
        if (indexOptions !== -1) {
          treeOptions[indexOptions].title = (
            <Button data-test-id={categoryValue} type="link">
              <div className={styles.classificationDetailsList}>
                {categoryValue}{" "}
              </div>
              {!treeOptions[indexOptions].isActive && inactiveTag}
            </Button>
          );
          setTreeOptions([...treeOptions]);
        } else {
          setTreeOptions(() => [...treeOptions, ...result]);
        }
      }
    }
  };
  useEffect(() => {
    if (!isClassificationChildLoading) setTreeData();
  }, [classificationChildList]);

  useEffect(() => {
    if (languageCode) {
      setTreeInitialData(null);
    }
  }, [languageCode]);

  useEffect(() => {
    if (!showCategory && languageCode && isDataLoaded) {
      dispatch(
        fetchClassificationDirectChilds({
          classificationId,
          languageCode: languageCode,
          includeInactive: !toggleActive,
        })
      );
      setExpandedKeys([]);
      setSelectedKeys([]);
      setSelectedNode({ expanded: false, key: "" });
    }
    setIsDataLoaded(true);
  }, [showCategory, toggleActive]);

  const setTreeData = () => {
    let categoryOptions: Array<CategoryOptions> = [];
    classificationChildList.forEach(item => {
      categoryOptions.push({
        title: (
          <Button data-test-id={item.categoryItemName} type="link">
            <div className={styles.classificationDetailsList}>
              {item.categoryItemName}{" "}
            </div>
            {!item.isActive && inactiveTag}
          </Button>
        ),
        key: item.categoryItemId,
        isActive: item.isActive,
      });
    });
    //categoryOptions.sort((a, b) => a.title.localeCompare(b.title));

    setTreeOptions(categoryOptions);
  };

  const setTreeInitialData = async (value: string) => {
    handleCategoryValue("");
    handleCategoryDetail(false, []);
    handleFormChanged(false);
    if (languageCode) {
      dispatch(
        fetchClassificationDirectChilds({
          classificationId,
          languageCode: languageCode,
        })
      );
      await setExpandedKeys([]);
      await setSelectedKeys([]);
      await setSelectedNode({ expanded: false, key: "" });
    }
  };

  /* istanbul ignore next */
  const updateTreeData = (
    list: Array<List>,
    key: string,
    children: Array<CategoryOptions>
  ) => {
    return list.map(node => {
      if (node.key === key) {
        if (children && children.length === 0) {
          node.isLeaf = true;
          node.icon = <Icon name="hierarchy-link" size="small" useTOLibrary />;
        } else {
          node.isLeaf = false;
          node.icon = null;
        }
        return { ...node, children };
      }

      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });
  };

  const onLoadData = async (key: List, children?: Array<CategoryOptions>) => {
    if (!children) {
      try {
        const { data } = await RlmdAPI.getCategoryItemsByIdInDirectChildIds(
          classificationId,
          {
            languageCode: languageCode,
            parentId: key.key,
            includeInactive: !toggleActive,
          }
        );
        let result: any;
        result = data.map(item => ({
          title: (
            <Button data-test-id={item.categoryItemName} type="link">
              <div className={styles.classificationDetailsList}>
                {item.categoryItemName}{" "}
              </div>
              {!item.isActive && inactiveTag}
            </Button>
          ),
          key: item.categoryItemId,
          isActive: item.isActive,
        }));

        // result.sort((a, b) => a.title.localeCompare(b.title));
        await setTreeOptions(origin => updateTreeData(origin, key.key, result));
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onExpand = (expandedKeys: string[], { node }) => {
    setExpandedKeys(() => expandedKeys);
    node.children?.forEach(child => {
      if (loadedKeys.includes(child.key) && !child.children) {
        onLoadData(child, null);
      }
    });
  };

  const onSelect = async (selectedKeys: string[], { node }) => {
    setSelectedNode(node);
    if (showPopup) {
      window.confirm(
        intl.formatMessage({ id: "category.form.error.anotherItem" })
      ) && setTreeInitialData(null);
    } else {
      if (selectedKeys.length > 0) {
        await dispatch(
          fetchCategoryItemDetails({
            selectedKeys: selectedKeys[0],
            isCompanySelected: !!selectedCompanyId,
          })
        );
        handleEditModal({ isEditModal: true, selectedKey: selectedKeys });
        handleCategoryDetail(true);
      } else {
        setIsDataLoaded(false);
        handleCategoryDetail(false, []);
      }
      setSelectedKeys(selectedKeys);
      node.children?.forEach(child => {
        if (loadedKeys.includes(child.key) && !child.children) {
          onLoadData(child, null);
        }
      });
      node.isActive ? setShowSubCategory(true) : setShowSubCategory(false);
    }
  };

  const onLoad = (loadedKeys: string[]) => {
    setLoadedKeys(loadedKeys);
  };

  const handleAddButton = async type => {
    if (showPopup) {
      window.confirm(
        intl.formatMessage({ id: "category.form.error.anotherItem" })
      ) && setTreeInitialData(null);
    } else {
      await dispatch(clearCategoryItemForm());
      if (type === "addCategory") {
        handleCategoryDetail(false);
        setSelectedKeys(() => []);
        setExpandedKeys(() => []);
        setSelectedNode({ expanded: false, key: "" });
        await onLoadData(selectedNode, null);
        await handleEditModal({ isEditModal: false, selectedKey: [] });
        await handleCategoryDetail(true, []);
        await setSelectedKeys(["abc"]);
      } else {
        await setExpandedKeys(() => expandedKeys.concat(selectedNode.key));

        await onLoadData(selectedNode, null);
        await setSelectedKeys(["abc"]);
        await handleEditModal({ isEditModal: false, selectedKey: [] });
        await handleCategoryDetail(true, [selectedNode.key]);
      }
    }
  };
  const actionList = [
    {
      label: intl.formatMessage({ id: "classification.category.addCategory" }),
      "data-test-id": "md-classification-addCategory",
      disabled: !categoryActive,
      onClick: () => handleAddButton("addCategory"),
    },
    {
      label: intl.formatMessage({
        id: "classification.category.addSubCategory",
      }),
      "data-test-id": "md-classification-addSubCategory",
      disabled:
        (selectedKeys.includes("abc") && !expandedKeys.length) ||
        !selectedKeys.length ||
        !showSubCategory ||
        categoryItemData.parent4CategoryItemId !== null,
      onClick: () => handleAddButton("addSubCategory"),
    },
  ];
  return (
    <div className={styles.categoriesList}>
      <div className={styles.flexBetween}>
        <div className={styles.dFlex}>
          <Heading size="xs">
            {intl.formatMessage({
              id: "category.form.categoriesList",
            })}
          </Heading>
          <div style={{ marginLeft: "16px" }}>
            {!isDisabled && categoryActive && (
              <Button
                type="primary"
                iconPlacement="right"
                items={actionList}
                data-test-id="add-category-item"
              >
                {intl.formatMessage({
                  id: "general.create",
                })}
              </Button>
            )}
          </div>
        </div>
      </div>
      {
        <div className={styles.flexBetween}>
          <Toggle
            disabled={showCategory}
            data-test-id="classifications-childs-toggle"
            defaultChecked={toggleActive}
            text={intl.formatMessage({ id: "general.displayActiveOnly" })}
            size="small"
            onChange={switchStatus => setToggleActive(switchStatus)}
          />
        </div>
      }
      {classificationChildList.length > 0 || treeOptions.length > 0 ? (
        isClassificationChildLoading ? (
          <div className={styles.treeEmptyData}>
            <Spinner />
          </div>
        ) : (
          <div className={styles.treeDatalist} data-test-id="category-tree">
            <Tree
              className={showPopup ? styles.treeDataListDisbaled : ""}
              checkable={false}
              treeData={treeOptions}
              loadData={onLoadData}
              selectable={true}
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              selectedKeys={selectedKeys}
              onSelect={onSelect}
              onLoad={onLoad}
              onCheck={() => {}}
              showIcon
            />
          </div>
        )
      ) : (
        <div className={styles.emptyData}>
          <Empty
            title={intl.formatMessage({ id: "general.noData" })}
            imageType={"simple"}
            subTitle={""}
          />
        </div>
      )}
    </div>
  );
};

export default ClassificationDirectChilds;
