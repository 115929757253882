import { SiteData } from "models";

import { FormInstanceType } from "shared/hooks/useAntForm";

import ActivitySection from "../ActivitySection";
import AllergenSection from "../AllergenSection";
import IdentificationSection from "../IdentitySection";
import VisibilitySection from "../VisibilitySection";

interface SupplierSiteInformationProps {
  disabled: boolean;
  submitResult: any;
  form: FormInstanceType<SiteData>;
}
const SupplierSiteInformation: React.FC<SupplierSiteInformationProps> = ({
  disabled,
  form,
  submitResult,
}) => {
  return (
    <>
      <IdentificationSection
        disabled={disabled}
        form={form}
        submitResult={submitResult}
      />
      <VisibilitySection disabled={disabled} />
      <ActivitySection disabled={disabled} />
      <AllergenSection disabled={disabled} form={form} />
    </>
  );
};

export default SupplierSiteInformation;
