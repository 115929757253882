import { SessionKeys, buildSessionStorage } from "./storage";

export const backToAppStorage = buildSessionStorage(SessionKeys.BACK_TO_APP);

export const selectedCompanyStorage = buildSessionStorage(
  SessionKeys.SELECTED_COMPANY
);

export const SearchFiltersStorage = Object.freeze({
  PRODUCT: buildSessionStorage(SessionKeys.PRODUCT_FILTERS),
  SUPPLIER: buildSessionStorage(SessionKeys.SUPPLIER_FILTERS),
  RETAILER_SITE: buildSessionStorage(SessionKeys.RETAILER_SITE_FILTERS),
  SUPPLIER_SITE: buildSessionStorage(SessionKeys.SUPPLIER_SITE_FILTERS),
  DOCUMENT: buildSessionStorage(SessionKeys.DOCUMENT_LIB_FILTERS),
  BRAND: buildSessionStorage(SessionKeys.BRAND_FILTERS),
});

export const TablePaginationStorage = Object.freeze({
  PRODUCT: buildSessionStorage(SessionKeys.PRODUCT_PAGINATION),
  SUPPLIER: buildSessionStorage(SessionKeys.SUPPLIER_PAGINATION),
  RETAILER_SITE: buildSessionStorage(SessionKeys.RETAILER_SITE_PAGINATION),
  SUPPLIER_SITE: buildSessionStorage(SessionKeys.SUPPLIER_SITE_PAGINATION),
  BRAND: buildSessionStorage(SessionKeys.BRAND_PAGINATION),
  DOCUMENTLIB: buildSessionStorage(SessionKeys.DOCUMENT_LIB_PAGINATION),
});
