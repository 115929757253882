import React, { ReactNode } from "react";
import { Section } from "../types";
export interface SidebarContextType {
    isSidebarExpanded?: boolean;
    setSidebarExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
    onClickSubSection?: (subSectionId: string) => void;
    selectedSubSectionId?: string;
    setSelectedSubSectionId?: React.Dispatch<React.SetStateAction<string>>;
    setIsMinified?: React.Dispatch<React.SetStateAction<boolean>>;
    isMinified?: boolean;
    selectedSectionId?: string;
    setSelectedSectionId?: React.Dispatch<React.SetStateAction<string>>;
    toggleMinified?: () => void;
    showSectionIcons?: boolean;
    shouldSectiontoggle?: boolean;
    showStatusDot? : boolean;
    setSearchBoxFocus? : React.Dispatch<React.SetStateAction<boolean>>;
    isSearchBoxFocus?: boolean;
    showSubectionLink?: boolean;
  }

export const defaultContextValue: SidebarContextType = {
  isSidebarExpanded: true,
  setSidebarExpanded: () => {},
  onClickSubSection: () => {},
  setSelectedSubSectionId: () => {},
  selectedSubSectionId: "",
  setIsMinified: () => {},
  isMinified: false,
  selectedSectionId: "",
  setSelectedSectionId: () => {},
  toggleMinified: () => {},
  showSectionIcons: true,
  shouldSectiontoggle: true,
  showStatusDot: true,
};

export interface SidebarContextProviderProps {
  children: ReactNode;
  sidebarSections?: any;
  showSectionIcons?: boolean;
  shouldSectiontoggle?:boolean;
  showStatusDot?: boolean;
  minified?: boolean;
  onSectionClick?: Function;
  onSubSectionClick?: Function;
  showSubectionLink?: boolean;
  setSidebarSectionData?: React.Dispatch<React.SetStateAction<Section[]>>;
}
