import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { Heading, TagV1 as Tag } from "@trace-one/design-system";
import moment from "moment";
import { DaysToExpiredCertificates, SectionsIds } from "pages/Sites/constants";

import { SmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import { selectIsRelatedCompanyLoading } from "reduxStore/shared/selectors";
import { setBackButtonData } from "reduxStore/shared/slice";
import {
  fetchSite,
  fetchSiteRelations,
} from "reduxStore/siteSupplierDetails/asyncActions";
import {
  selectSite,
  selectIsSiteLoading,
  selectHasSiteEditError,
  selectSiteErrorStatus,
  selectSiteInitiated,
} from "reduxStore/siteSupplierDetails/selectors";
import { clearSiteSupplierDetails } from "reduxStore/siteSupplierDetails/slice";
import { selectUserLanguageCode } from "reduxStore/user/selectors";

import ErrorPage from "components/ErrorPage";
import HeaderDetails from "components/HeaderDetails";
import Spinner from "components/Spinner";
import useToast from "shared/hooks/useToast";

import {
  CertificationsTab,
  SiteInformationsTab,
} from "../../components/SiteSupplierDetails";
import withSiteShared, { SiteSharedProps } from "../../hocs/withSiteShared";

interface SiteSupplierEditProps extends SiteSharedProps {}

const SiteSupplierDetails: React.FC<SiteSupplierEditProps> = ({
  isSiteSharedInitiated,
}) => {
  const { siteId } = useParams<{ siteId?: string }>();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { sitesWrite } = usePermissions();
  const toast = useToast();
  const history = useHistory();

  const today = moment();
  const site = useSelector(selectSite);
  const languageCode = useSelector(selectUserLanguageCode);
  const isRelatedCompanyLoading = useSelector(selectIsRelatedCompanyLoading);
  const isLoading = useSelector(selectIsSiteLoading);
  const hasErrors = useSelector(selectHasSiteEditError);
  const errorStatus = useSelector(selectSiteErrorStatus);
  const siteInitiated = useSelector(selectSiteInitiated);
  // const hasCertificates = !!site?.siteCertificates?.length;
  const isActiveTitle = intl.formatMessage(
    {
      id: site?.siteActive
        ? "sitesListPage.tooltip.deActivate.title"
        : "sitesListPage.tooltip.activate.title",
    },
    { Name: "" }
  );
  const siteStatus = site.siteActive;

  useEffect(() => {
    dispatch(fetchSite({ siteId, languageCode }));
    dispatch(fetchSiteRelations(siteId));
    return () => {
      dispatch(clearSiteSupplierDetails());
    };
  }, [siteId]);

  useEffect(() => {
    if (!siteStatus) {
      dispatch(
        setBackButtonData({
          fromUrl: `/sites/list`,
        })
      );
    }
  }, [siteStatus]);

  const handleSiteStatus = async () => {
    try {
      site?.siteActive
        ? await SmdAPI.disableSite(site.id)
        : await SmdAPI.enableSite(site.id);
      toast.success({
        message: intl.formatMessage({
          id: site?.siteActive
            ? "sitesListPage.inactiveSuccessTitle"
            : "sitesListPage.reActiveSuccessTitle",
        }),
        description: intl.formatMessage({
          id: site?.siteActive
            ? "sitesListPage.inactiveSuccessText"
            : "sitesListPage.reActiveSuccessText",
        }),
      });
      dispatch(fetchSite({ siteId, languageCode }));
      dispatch(fetchSiteRelations(siteId));
    } catch (error) {
      toast.error({
        message: intl.formatMessage({
          id: site?.siteActive
            ? "sitesListPage.inactiveFailedTitle"
            : "sitesListPage.reActiveFailedTitle",
        }),
        description: intl.formatMessage({
          id: site?.siteActive
            ? "sitesListPage.inactiveFailedText"
            : "sitesListPage.reActiveFailedText",
        }),
        checkErrorResponse: false,
      });
    }
  };

  const tabList = [
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "siteDetails.siteInformation" })}
        </Heading>
      ),
      content: <SiteInformationsTab />,
    },
    {
      label: (
        <Heading size="xs">
          {" "}
          {intl.formatMessage({ id: "general.classificates" })}
        </Heading>
      ),
      count: site?.siteCertificates?.filter(certif => {
        return (
          certif.certificateEndAvailabilityDate &&
          moment(certif.certificateEndAvailabilityDate).isBetween(
            today,
            moment().add(DaysToExpiredCertificates, "day"),
            "day",
            "[]"
          )
        );
      }).length,
      content: <CertificationsTab />,
    },
  ];

  const buttonList = [
    {
      text: "general.actions",
      showBtn: sitesWrite,
      testId: "site-details-actions-btn",
      tab: 0,
      actionList: [
        {
          name: "general.edit",
          "data-test-id": "site-supplier-details-edit",
          disabled: !siteStatus,
          onClick: () => history.push(`/sites/edit/${siteId}`),
          showTooltip: false,
          showBtn: true,
        },
        {
          name: siteStatus
            ? "siteSupplier.deActivate"
            : "siteSupplier.activate",
          "data-test-id": "site-supplier-archive-status",
          disabled: false,
          showTooltip: true,
          showBtn: true,
        },
        {
          name: "siteForm.addCertificate",
          disabled: !siteStatus,
          "data-test-id": "site-supplier-add-certificate",
          onClick: () =>
            history.push(`/sites/edit/${siteId}#${SectionsIds.CERTIFICATES}`),
          showTooltip: false,
          showBtn: true,
        },
        {
          name: "general.viewVersionsHistory",
          disabled: false,
          "data-test-id": "viewVersionsHistory-button",
          onClick: () => history.push(`/sites/history/${siteId}`),
          showTooltip: false,
          showBtn: true,
        },
      ],
    },
  ];

  const SiteStatusTag = ({ status }) => {
    const statusTagData = status
      ? {
          label: intl.formatMessage({ id: "general.active" }),
          color: "green",
        }
      : {
          label: intl.formatMessage({ id: "general.inactive" }),
          color: "grey",
        };
    return <Tag mode="light" {...statusTagData} />;
  };

  if (
    isLoading ||
    isRelatedCompanyLoading ||
    !isSiteSharedInitiated ||
    !siteInitiated
  ) {
    return <Spinner underHeader />;
  }

  if (hasErrors) {
    return <ErrorPage status={errorStatus} />;
  }

  return (
    <div>
      <HeaderDetails
        withBackNav
        title={site?.siteName}
        status={<SiteStatusTag status={site?.siteActive} />}
        tabs={tabList}
        headerIcon="factory"
        buttons={buttonList}
        handleStatus={handleSiteStatus}
        tooltipTitle={isActiveTitle}
        haveActionItems
      />
    </div>
  );
};

export default withSiteShared(SiteSupplierDetails);
