import React from "react";
import Section from "./Section/Section";
import { SectionsProps } from "./types";
import "./styles.less";

const Sections: React.FC<SectionsProps> = ({
  sections,
}) => {
  return (
    <ul className="sections" data-test-id="menu-item-sections">
      {sections?.map((section, index) => (
        <li key={section.sectionId} data-test-id={`${section}${index}`}>
          <Section
            section={section}
          />
        </li>
      ))}
    </ul>
  );
};

export default Sections;
