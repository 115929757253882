import { createAsyncThunk } from "@reduxjs/toolkit";
import { SiteIdentification } from "pages/Sites/constants";

import { SmdAPI, CatalogueAPI } from "apis";
import { SiteData } from "models";

export const fetchSite = createAsyncThunk<
  SiteData,
  { siteId: string; languageCode: string },
  { rejectValue: { status: number } }
>("siteSupplierForm/fetchSite", async ({ siteId, languageCode }, thunkAPI) => {
  try {
    const { data: site } = await SmdAPI.getSiteById(siteId);
    const { data: allergenData } = await CatalogueAPI.getAllergensByIds(
      { allergenIds: site.allergenIds },
      {
        languageCode,
        includeParents: true,
      }
    );
    let internalCode = site.identifierCodes?.find(
      ({ typeId }) => typeId === SiteIdentification.INTERNAL_CODE
    )?.value;
    return { ...site, internalCode, allergenData };
  } catch (err) {
    const status: number = err?.response?.status ?? 500;
    return thunkAPI.rejectWithValue({ status });
  }
});

export const fetchSiteRelations = createAsyncThunk(
  "siteSupplierForm/fetchSiteRelations",
  async (siteId: string) => {
    const { data } = await SmdAPI.getSiteRelations(siteId);
    return data;
  }
);
