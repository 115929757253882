import { memo, useRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Filter } from "@trace-one/business-components";
import { Picker } from "@trace-one/design-system";

import CheckableTags from "components/CheckableTags";
import { adjustIntercomButton } from "shared/utils/toggleIntercom";

import { BrandFilter } from "../models";

interface BrandSearchProps {
  filterObj: BrandFilter;
  mergeFilters: (
    value: Partial<BrandFilter> | ((prevState: BrandFilter) => BrandFilter)
  ) => void;
  initialSearchValue: string;
  onSearch: (searchValue: string) => void;
  onClearFiltersClick: () => void;
}

const BrandSearch: React.FC<BrandSearchProps> = ({
  filterObj,
  mergeFilters,
  initialSearchValue,
  onSearch,
  onClearFiltersClick,
}) => {
  const intl = useIntl();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const showIntercom = useRef(true);

  useEffect(() => {
    const { archivedStatuses } = filterObj;
    const defaultSelectedFilters = [
      ...(archivedStatuses
        ? [
            {
              filterId: "1",
              values: archivedStatuses,
            },
          ]
        : []),
    ];
    setSelectedFilters(defaultSelectedFilters);
  }, [filterObj]);

  const filters = [
    {
      title: intl.formatMessage({
        id: "general.status",
      }),
      controlType: "single_selection",
      clearSelection: () => {
        mergeFilters({
          archivedStatuses: [],
        });
      },
      elementToRender: (
        <CheckableTags
          value={filterObj.archivedStatuses}
          onChange={archivedStatuses =>
            mergeFilters({
              archivedStatuses,
            })
          }
          options={[
            {
              value: "no",
              label: intl.formatMessage({ id: "general.notArchived" }),
            },
            {
              value: "yes",
              label: intl.formatMessage({ id: "general.archived" }),
            },
          ]}
        />
      ),
      quickFilter: (
        <Picker.Filter
          data-test-id="picker-filter-brand-status"
          title={intl.formatMessage({
            id: "general.status",
          })}
          clearBtnText={intl.formatMessage({ id: "general.clear" })}
          closeBtnText={intl.formatMessage({ id: "general.close" })}
          showBadgeCount
          items={[
            {
              value: "no",
              label: intl.formatMessage({ id: "general.notArchived" }),
              testId: "filter-notArchived",
            },
            {
              value: "yes",
              label: intl.formatMessage({ id: "general.archived" }),
              testId: "filter-archived",
            },
          ]}
          value={filterObj.archivedStatuses ?? []}
          selectionType="multi"
          onChange={({ value: archivedStatuses }) => {
            mergeFilters({
              archivedStatuses,
            });
          }}
          clearSelection={() =>
            mergeFilters({
              archivedStatuses: [],
            })
          }
        ></Picker.Filter>
      ),
      filterId: "1",
    },
  ];

  return (
    <Filter
      filters={filters}
      selectedFilters={selectedFilters}
      clearAllFilters={() => {
        onClearFiltersClick();
      }}
      search={{
        defaultValue: initialSearchValue,
        placeholder: intl.formatMessage({
          id: "brandList.searchPlaceholder",
        }),
        onSearch: query => {
          onSearch(query);
        },
      }}
      onAllFilterPanelToggle={() => {
        showIntercom.current = !showIntercom.current;
        adjustIntercomButton(showIntercom.current);
      }}
    />
  );
};

export default memo(BrandSearch);
