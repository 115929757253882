import React, { useEffect } from "react";
import { Popover } from "antd";
import { UserMenuProps } from "./types";
import { UserCard, toaster } from "@trace-one/design-system";
import "./styles.less";
import UserInfo from "./components/UserInfo";
import QuickLinks from "./components/QuickLinks";
import {
  useCompanyDetails,
  useLanguagePreference,
  useScrollHide,
  useUser,
} from "src/hooks";
import { withLanguageData } from "../../hocs";
import { LangList } from "../../../utils/const";
import enUs from "./translations/en-US.json";
import frFr from "./translations/fr-FR.json";
import deDe from "./translations/de-DE.json";
import esEs from "./translations/es-ES.json";
import ptPt from "./translations/pt-PT.json";
import itIt from "./translations/it-IT.json";
import nlBe from "./translations/nl-BE.json";
import useJobTitles from "../../hooks/useJobTitles";
import { getPrefixClassName } from "src/utils/general";
import { useIntl } from "react-intl";

const DEFAULT_LANG = enUs;

const enhance = withLanguageData({
  translations: {
    [LangList.EN_US]: enUs,
    [LangList.FR_FR]: { ...DEFAULT_LANG, ...frFr },
    [LangList.DE_DE]: { ...DEFAULT_LANG, ...deDe },
    [LangList.ES_ES]: { ...DEFAULT_LANG, ...esEs },
    [LangList.PT_PT]: { ...DEFAULT_LANG, ...ptPt },
    [LangList.IT_IT]: { ...DEFAULT_LANG, ...itIt },
    [LangList.NL_BE]: { ...DEFAULT_LANG, ...nlBe },
  },
});

const UserMenu: React.FC<UserMenuProps> = ({
  userId,
  onLogout,
  isTon,
  isMasquerade,
  isNameShown,
  size,
  onUnmasquerade,
}) => {
  const languageCode = useLanguagePreference();
  const { formatMessage } = useIntl();

  const { user, isLoading: userIsLoading } = useUser({ userId });
  const { companyDetails, isLoading: companyDetailsIsLoading } =
    useCompanyDetails(user?.owningCompanyId);
  const { jobTitles, isLoading: jobTitlesIsLoading } = useJobTitles({
    languageCode,
  });
  const { open, handleOpenChange, hideUserMenu } = useScrollHide(isTon);

  useEffect(() => {
    if (isMasquerade && user?.userFirstName) {
      toaster.open({
        message: formatMessage({
          id: "businessComponents.userProfilePage.toast.masquerade.activated",
        }),
        description: formatMessage(
          {
            id: "businessComponents.userProfilePage.toast.masquerade.activated.description",
          },
          {
            userName: `${user?.userFirstName} ${user?.userLastName}`,
          }
        ),
        type: "confirmation",
        duration: 10,
      });
    }
  }, [isMasquerade, user]);

  if (userIsLoading || companyDetailsIsLoading || jobTitlesIsLoading) {
    return null;
  }
  const prefixClassName = getPrefixClassName("user-menu");

  const popoverContent = (
    <>
      <UserInfo
        user={user}
        companyDetails={companyDetails}
        jobTitles={jobTitles}
        isMasquerade={isMasquerade}
      />
      <QuickLinks
        user={user}
        onLogout={onLogout}
        onUnmasquerade={onUnmasquerade}
        isTon={isTon}
        isMasquerade={isMasquerade}
        hideMenu={hideUserMenu}
        open={open}
      />
    </>
  );
  return (
    <div data-test-id={`${prefixClassName}`}>
      <Popover
        content={popoverContent}
        trigger="click"
        placement="bottomRight"
        overlayClassName="user-menu-popover"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <div
          className={`${prefixClassName}-avatar`}
          data-test-id={`${prefixClassName}-avatar`}
        >
          <UserCard.Information
            size={size || "m"}
            name={`${user?.userFirstName} ${user?.userLastName}`}
            photoUrl={user?.userPhotoUrl}
            icon={isMasquerade}
          />
          {isNameShown && <span>{`${user?.userFirstName}`}</span>}
        </div>
      </Popover>
    </div>
  );
};

export default enhance(UserMenu);
