import React, { useContext } from "react";
import { Search, Icon } from "@trace-one/design-system";
import { SidebarSearchProps } from "./types";
import { SidebarContext } from "../../context/SidebarContextProvider";
import "./styles.less";
const SidebarSearch: React.FC<SidebarSearchProps> = ({ handleChange }) => {
  const { isMinified, isSearchBoxFocus, setSearchBoxFocus, setIsMinified } = useContext(SidebarContext);

  return (
    <div className="moduleSidebarNavigatorSearch" data-testid="moduleSidebarNavigatorSearch">
      {isMinified
        ? (
        <div className="search-icon-wrapper" data-testid="minifiedSearchIcon">
          <Icon
            name="search"
            onClick={() => {
              setSearchBoxFocus(prev => !prev);
              setIsMinified(prev => !prev);
            }}
          />
        </div>
          )
        : (
        <div className="search-box-wrapper" data-testid="expandedSearchBox">
          <Search
            placeholder="Search here"
            onChange={handleChange}
            data-testid="searchInput"
            autoFocus={isSearchBoxFocus}
          />
        </div>
          )}
    </div>
  );
};

export default SidebarSearch;
