import { memo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { AllergensModal } from "@trace-one/business-components";
import {
  Heading,
  Illustration,
  Paragraph,
  Button,
  Card,
} from "@trace-one/design-system";
import { Form } from "antd";

import { SiteData } from "models";

import { useAppDispatch } from "reduxStore";
import { selectSiteSupplierFormData } from "reduxStore/siteSupplierDetails/selectors";
import { setSiteSupplierFormData } from "reduxStore/siteSupplierDetails/slice";

import FormWrapper from "components/FormWrapper";
import { FormInstanceType } from "shared/hooks/useAntForm";

import { ALLERGENS, SectionsIds } from "../../../../constants";
import styles from "../SiteSupplierForm.module.less";

interface AllergenSectionProps {
  disabled?: boolean;
  form: FormInstanceType<SiteData>;
}

const AllergenSection: React.FC<AllergenSectionProps> = ({
  disabled,
  form,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const siteSupplierFormData = useSelector(selectSiteSupplierFormData);

  return (
    <FormWrapper.Section
      title={
        <Heading data-test-id="site-allergenForm-heading" size="m">
          {" "}
          {intl.formatMessage({ id: "siteDetails.allergens.title" })}
        </Heading>
      }
      id={SectionsIds.ALLERGENS}
    >
      <Form.Item shouldUpdate wrapperCol={{ span: 24 }}>
        {() => {
          const allergenData = form.getFieldValue("allergenData");
          const { allergenIds } = siteSupplierFormData;
          return (
            <div className={styles.allergensSection}>
              {!allergenData?.length ? (
                <div className={styles.noAllergenSelected}>
                  <Illustration color="grey" name="no-data" />
                  <Paragraph>
                    {intl.formatMessage({
                      id: "siteDetails.allergens.noAllergen",
                    })}
                  </Paragraph>
                </div>
              ) : (
                <div className={styles.allergenList}>
                  {allergenData?.map((field, index) => {
                    if (allergenIds?.includes(field.id)) {
                      return (
                        <div
                          key={field.id}
                          data-test-id={`${field.name}-card`.toLowerCase()}
                        >
                          <Card
                            title={field.name}
                            headerIconName={
                              ALLERGENS.find(({ id }) => id === field.id)?.name
                            }
                            size="xxsmall"
                            className={styles.allergenCard}
                            headerActions={[
                              {
                                key: 1,
                                type: "button",
                                buttonType: "tertiary",
                                args: {
                                  disabled: disabled,
                                  iconName: "trash",
                                  "data-test-id":
                                    `delete-${field.name}`.toLowerCase(),
                                  onClick: () => {
                                    form.setFieldValue(
                                      "allergenData",
                                      allergenData.filter(
                                        ({ id }) => field.id !== id
                                      )
                                    );
                                    const { allergenIds } =
                                      siteSupplierFormData;
                                    let dataToSave = {
                                      ...siteSupplierFormData,
                                      allergenIds: allergenIds.filter(
                                        id => field.id !== id
                                      ),
                                    };
                                    dispatch(
                                      setSiteSupplierFormData(dataToSave)
                                    );
                                  },
                                },
                              },
                            ]}
                          ></Card>
                        </div>
                      );
                    }
                    return field.children.map(({ id, name }) => (
                      <div
                        key={id}
                        data-test-id={`${name}-${field.name}-card`.toLowerCase()}
                      >
                        <Card
                          headerIconName={
                            ALLERGENS.find(({ id }) => id === field.id)?.name
                          }
                          title={`${name} - ${field.name}`}
                          size="xxsmall"
                          className={styles.allergenCard}
                          headerActions={[
                            {
                              key: 1,
                              type: "button",
                              buttonType: "tertiary",
                              args: {
                                iconName: "trash",
                                disabled: disabled,
                                "data-test-id":
                                  `delete-${name}-${field.name}`.toLowerCase(),
                                onClick: () => {
                                  let editedData = [...allergenData];
                                  const fieldData = editedData.find(
                                    allergen => allergen.id === field.id
                                  );
                                  fieldData.children = field.children.filter(
                                    child => child.id !== id
                                  );
                                  if (!fieldData.children.length) {
                                    editedData = editedData.filter(
                                      ({ id }) => id !== fieldData.id
                                    );
                                  }
                                  form.setFieldValue(
                                    "allergenData",
                                    editedData
                                  );
                                  const { allergenIds } = siteSupplierFormData;
                                  if (allergenIds.includes(id)) {
                                    let dataToSave = {
                                      ...siteSupplierFormData,
                                      allergenIds: allergenIds.filter(
                                        childId => id !== childId
                                      ),
                                    };
                                    dispatch(
                                      setSiteSupplierFormData(dataToSave)
                                    );
                                  } else {
                                    const parentIds = allergenIds.filter(
                                      id => field.id !== id
                                    );
                                    const childIds = field.children
                                      .filter(child => child.id !== id)
                                      .map(({ id }) => id);
                                    let dataToSave = {
                                      ...siteSupplierFormData,
                                      allergenIds: [...parentIds, ...childIds],
                                    };
                                    dispatch(
                                      setSiteSupplierFormData(dataToSave)
                                    );
                                  }
                                },
                              },
                            },
                          ]}
                        ></Card>
                      </div>
                    ));
                  })}
                </div>
              )}

              <AllergensModal
                open={modalVisible}
                subtitle={intl.formatMessage({
                  id: "siteDetails.allergenModal.title",
                })}
                selectedAllergens={form.getFieldValue("allergenData") || []}
                onSubmit={params => {
                  form.setFieldValue("allergenData", params.selectedData);
                  let dataToSave = {
                    ...siteSupplierFormData,
                    allergenIds: params.selectedIds,
                  };
                  dispatch(setSiteSupplierFormData(dataToSave));
                  setModalVisible(false);
                }}
                onClose={() => setModalVisible(false)}
              />

              <Button
                size="m"
                onClick={() => setModalVisible(true)}
                className={styles.addAllergenBtn}
                disabled={disabled}
                data-test-id="md-add-allergen"
              >
                {intl.formatMessage({
                  id: "siteDetails.allergens.addAllergen",
                })}
              </Button>
            </div>
          );
        }}
      </Form.Item>
    </FormWrapper.Section>
  );
};

export default memo(AllergenSection);
