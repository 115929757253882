import { createAsyncThunk } from "@reduxjs/toolkit";

import { SmdAPI, CumdAPI, CatalogueAPI } from "apis";
import { SiteData, CompanyData, UserData } from "models";

export const fetchSite = createAsyncThunk<
  {
    site: SiteData;
    supplier: CompanyData;
    contactUsers: UserData[];
    allergenNames: string[];
  },
  { siteId: string; languageCode: string },
  { rejectValue: { status: number } }
>(
  "siteSupplierDetails/fetchSite",
  async ({ siteId, languageCode }, thunkAPI) => {
    try {
      const { data: site } = await SmdAPI.getSiteById(siteId);

      const [allergenData, supplier, contactUsers] = await Promise.all([
        await CatalogueAPI.getAllergensByIds(
          { allergenIds: site.allergenIds },
          {
            languageCode,
            includeParents: true,
          }
        ).then(({ data }) => data),
        await CumdAPI.getCompanyById(site.ownerCompanyId).then(
          ({ data }) => data
        ),
        site.contactUserIds.length
          ? await CumdAPI.getUsersByUserIds({
              userIds: site.contactUserIds,
            }).then(({ data }) => data)
          : [],
      ]);
      const allergenNames = allergenData
        .map(allergen => {
          if (!allergen.children.length) {
            return allergen.name;
          }
          return allergen.children.map(child => {
            return `${allergen.name} - ${child.name}`;
          });
        })
        .flatMap(c => c);

      return {
        site,
        allergenNames,
        supplier,
        contactUsers,
      };
    } catch (err) {
      const status: number = err?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({ status });
    }
  }
);

export const fetchSiteRelations = createAsyncThunk(
  "siteSupplierDetails/fetchSiteRelations",
  async (siteId: string) => {
    const { data } = await SmdAPI.getSiteRelations(siteId);
    return data;
  }
);
