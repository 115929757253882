import React, { useEffect, useState } from "react";
import { extractCertificateInformation } from "../../../apis/ai";
import { ExtractedFileInfoData } from "../../../apis/ai/types";
const useExtractedFromAIFileData = (
  DmsFileId: string,
  isAIUsed: boolean,
  setNotFoundCertificationError: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [extractedFileDataFromAI, setExtractedFileDataFromAI] = useState<ExtractedFileInfoData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const extractCertificateFileInformation = async () => {
    try {
      setIsLoading(true);
      const { data } = await extractCertificateInformation(DmsFileId);

      const allValuesNull = data && Object.values(data).every(value => value === null);

      if (allValuesNull) {
        setNotFoundCertificationError(true);
        setExtractedFileDataFromAI(null);
      } else {
        setNotFoundCertificationError(false);
        setExtractedFileDataFromAI(data);
      }
    } catch (error) {
      console.error(error);
      setExtractedFileDataFromAI(null);
      setNotFoundCertificationError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAIUsed && DmsFileId) {
      extractCertificateFileInformation();
    }
  }, [isAIUsed, DmsFileId]);

  return {
    isLoading,
    extractedFileDataFromAI,
  };
};
export default useExtractedFromAIFileData;
