export type StorageType = Readonly<{
  set: (value: object | string) => void;
  get: () => any;
  remove: () => void;
}>;

const buildStorage =
  (storage: Storage) =>
  (key: string): StorageType =>
    Object.freeze({
      set: (value: object | string) => {
        if (typeof value === "object") {
          const result = JSON.stringify(value);
          storage?.setItem(key, result);
        } else {
          storage?.setItem(key, value);
        }
      },
      get: () => {
        const result = storage?.getItem(key);
        try {
          return JSON.parse(result);
        } catch (error) {
          return result;
        }
      },
      remove: () => {
        storage?.removeItem(key);
      },
    });

export const buildSessionStorage = buildStorage(sessionStorage);
export const buildLocalStorage = buildStorage(localStorage);

export const SessionKeys = Object.freeze({
  SELECTED_COMPANY: "selected-company",
  BACK_TO_APP: "back-to-app",
  BRAND_PAGINATION: "brand-pagination",
  BRAND_FILTERS: "brand-filters",

  PRODUCT_FILTERS: "product-filters",
  PRODUCT_PAGINATION: "product-pagination",

  RETAILER_SITE_FILTERS: "retailer-site-filters",
  RETAILER_SITE_PAGINATION: "retailer-site-pagination",

  SUPPLIER_SITE_FILTERS: "supplier-site-filters",
  SUPPLIER_SITE_PAGINATION: "supplier-site-pagination",

  SUPPLIER_FILTERS: "supplier-filters",
  SUPPLIER_PAGINATION: "supplier-pagination",

  DOCUMENT_LIB_FILTERS: "document-lib-filters",
  DOCUMENT_LIB_PAGINATION: "document-lib-pagination",
});
