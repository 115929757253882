import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { CertificateModal } from "@trace-one/business-components";
import { Button, Tooltip, toaster } from "@trace-one/design-system";

import { PmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import {
  selectIsExportSelectedManufacturedItemsLoading,
  selectIsExportSelectedProductsLoading,
} from "reduxStore/productList/selectors";
import {
  setIsExportSelectedProductsLoading,
  setIsExportSelectedManufacturedItemsLoading,
} from "reduxStore/productList/slice";

import ShortMsg from "components/ShortMsg";
import Table from "components/Table";
import useAsyncFileDownload from "shared/hooks/useAsyncFileDownload";
import useAsyncHandler from "shared/hooks/useAsyncHandler";

import { ProductFilter } from "../models";

import styles from "./ProductHeader.module.less";

interface ProductHeaderProps {
  listingResult?: React.ReactNode;
  searchText: string;
  filterObj?: ProductFilter;
  selectedRowKeys: string[];
  ownerCompanyId: string;
  selectedCompanyId?: string;
  isAllSelected: boolean;
  totalCount: number;
  refetchProducts: () => void;
  getArchiveAllProducts: () => Promise<string[]>;
  setSelectedRowKeys?: (rowKeys: string[]) => void;
  setAllSelected?: (boolean) => void;
  isRetailer: boolean;
  supplierData?: any;
  doRefreshManufacturedItems: (prev) => void;
}

const ProductHeader: React.FC<ProductHeaderProps> = ({
  listingResult,
  searchText,
  filterObj,
  selectedRowKeys,
  ownerCompanyId,
  selectedCompanyId,
  isAllSelected,
  totalCount,
  refetchProducts,
  getArchiveAllProducts,
  setAllSelected,
  setSelectedRowKeys,
  isRetailer,
  supplierData,
  doRefreshManufacturedItems,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { productsWrite, productsUpdate, productsRead } = usePermissions();
  const [visible, setVisible] = useState<boolean>(false);
  const [isCertificationModalOpen, setIsCertificationModalOpen] =
    useState<boolean>(false);
  const handleModalClose = () => {
    setIsCertificationModalOpen(false);
  };

  const {
    brandIds,
    productCategory,
    tradeItemStatuses,
    associatedCompanyIds,
    HasNoBrand,
    HasNoGtin,
    HasNoProductCategory,
    responsibilityId,
    userId,
    checked,
  } = filterObj;

  const [handleArchiveSelectedTradeItems, isArchiveSelectedLoading] =
    useAsyncHandler(async () => {
      await PmdAPI.archiveTradeItems({
        idCollection: isAllSelected
          ? await getArchiveAllProducts()
          : selectedRowKeys,
      });
      doRefreshManufacturedItems(prev => prev + 1);
      refetchProducts();
      setAllSelected(false);
      setSelectedRowKeys([]);
    });

  const [handleExportAllTradeItems, isExportAllTradeItemsLoading] =
    useAsyncHandler(
      () =>
        PmdAPI.exportTradeItemsInBulk(
          {
            tradeItemStatuses,
            searchText,
            brandIds,
            supplierCompanyIds: associatedCompanyIds,
          },
          {
            categoryId,
            categoryItemId,
            ownerCompanyId: selectedCompanyId || ownerCompanyId,
            HasNoBrand,
            HasNoGtin,
            HasNoProductCategory,
            OnlyWithoutContacts: checked,
            ContactResponsibilityId: responsibilityId,
            ContactUserId: userId,
          }
        ),
      {
        successMessage: {
          type: "info",
          description: (
            <ShortMsg.FileRequestProcessed formatMessage={intl.formatMessage} />
          ),
        },
      }
    );
  const [
    handleExportAllManufacturedItems,
    isExportAllManufacturedItemsLoading,
  ] = useAsyncHandler(
    () =>
      PmdAPI.exportManufacturedItemsInBulk(
        {
          tradeItemStatuses,
          searchText,
          brandIds,
          supplierCompanyIds: associatedCompanyIds,
        },
        {
          categoryId,
          categoryItemId,
          ownerCompanyId: selectedCompanyId || ownerCompanyId,
          HasNoBrand,
          HasNoGtin,
          HasNoProductCategory,
          OnlyWithoutContacts: checked,
          ContactResponsibilityId: responsibilityId,
          ContactUserId: userId,
        }
      ),
    {
      successMessage: {
        type: "info",
        description: (
          <ShortMsg.FileRequestProcessed formatMessage={intl.formatMessage} />
        ),
      },
    }
  );

  const isExportSelectedTradeItemsLoading = useSelector(
    selectIsExportSelectedProductsLoading
  );
  const [handleExportSelectedTradeItems] = useAsyncFileDownload(
    () =>
      PmdAPI.exportTradeItems(
        { ids: selectedRowKeys },
        { ownerCompanyId: selectedCompanyId || ownerCompanyId }
      ),
    {
      displayLoadingMessage: true,
      loadingMessage: intl.formatMessage({
        id: "general.exportIsInProgress",
      }),
      setIsLoading: value =>
        dispatch(setIsExportSelectedProductsLoading(value)),
      fileExtension: "csv",
    }
  );

  const isExportSelectedManufacturedItemsLoading = useSelector(
    selectIsExportSelectedManufacturedItemsLoading
  );
  const [handleExportSelectedManufacturedItems] = useAsyncFileDownload(
    () =>
      PmdAPI.exportManufacturedItems(
        { tradeItemIds: selectedRowKeys },
        { ownerCompanyId: selectedCompanyId || ownerCompanyId }
      ),
    {
      displayLoadingMessage: true,
      loadingMessage: intl.formatMessage({
        id: "general.exportIsInProgress",
      }),
      setIsLoading: value =>
        dispatch(setIsExportSelectedManufacturedItemsLoading(value)),
      fileExtension: "csv",
    }
  );

  const { categoryId, categoryItemId } = productCategory ?? {};

  const isArchiveSelectedDisabled =
    (!selectedRowKeys.length && !isAllSelected) ||
    isArchiveSelectedLoading ||
    selectedRowKeys.length > 100 ||
    (isAllSelected && totalCount > 100);

  const buttonActions = [
    {
      label: intl.formatMessage({
        id: "general.exportAllProducts",
      }),
      key: "export-all-products",
      onClick: handleExportAllTradeItems,
    },

    {
      label: intl.formatMessage({
        id: "general.exportAllManufacturedItems",
      }),
      key: "export-all-manufactured-items",
      onClick: handleExportAllManufacturedItems,
    },

    ...(productsWrite || productsUpdate
      ? [
          {
            label: isArchiveSelectedLoading ? (
              intl.formatMessage({
                id: "productsListPage.archive",
              })
            ) : (
              <Tooltip
                className="withNoArrow"
                onOpenChange={() =>
                  isArchiveSelectedDisabled
                    ? setVisible(!visible)
                    : setVisible(true)
                }
                placement="top"
                text={intl.formatMessage({
                  id: isArchiveSelectedDisabled
                    ? "productsListPage.archiveDisabled.toolTipTitle"
                    : "productsListPage.archive.toolTipTitle",
                })}
                actions={
                  !isArchiveSelectedDisabled && [
                    {
                      text: intl.formatMessage({ id: "general.cancel" }),
                      onClick: (event: React.FocusEvent<HTMLElement>) => {
                        event.target.blur();
                        setVisible(false);
                      },
                    },
                    {
                      text: intl.formatMessage({ id: "general.confirm" }),
                      onClick: () => {
                        handleArchiveSelectedTradeItems();
                        setVisible(false);
                      },
                    },
                  ]
                }
                visible={visible}
              >
                {intl.formatMessage({
                  id: "productsListPage.archive",
                })}
              </Tooltip>
            ),
            key: "archive-products",
            disabled: isArchiveSelectedDisabled,
          },
        ]
      : []),
    {
      label: intl.formatMessage({
        id: "general.exportSelectedProducts",
      }),
      key: "export-products",
      disabled: !selectedRowKeys.length && !isAllSelected,
      onClick: isAllSelected
        ? handleExportAllTradeItems
        : handleExportSelectedTradeItems,
    },
    {
      label: intl.formatMessage({
        id: "general.exportSelectedManufacturedItems",
      }),
      key: "export-manufactured-items",
      disabled: !selectedRowKeys.length && !isAllSelected,
      onClick: isAllSelected
        ? handleExportAllManufacturedItems
        : handleExportSelectedManufacturedItems,
    },
  ];

  const supplierButtonActions = [
    {
      label: intl.formatMessage({
        id: "supplierListPage.table.actions.createCertificate",
      }),
      key: "supplier-products-add-certificate",
      disabled: !selectedRowKeys.length && !isAllSelected,
      onClick: () => {
        setIsCertificationModalOpen(true);
      },
    },
  ];
  const handleMultipleCertificatesCreated = async (
    certificateId: string,
    selectedRowKeys: string[],
    supplierData: Array<{ key: string; itemName: string }>
  ) => {
    for (const manufacturedItemId of selectedRowKeys) {
      const matchedItem = supplierData?.find(
        item => item.key === manufacturedItemId
      );

      const manufacturedItemName = matchedItem
        ? matchedItem.itemName
        : manufacturedItemId;

      try {
        await PmdAPI.addCertificateToManufaturedItem(manufacturedItemId, {
          certificateId,
        });

        toaster.open({
          message: intl.formatMessage({ id: "toast.confirmation" }),
          description: intl.formatMessage(
            { id: "productsSupplierListPage.successToast.text" },
            { manufacturedItemName }
          ),
          type: "confirmation",
        });
      } catch (error) {
        toaster.open({
          message: intl.formatMessage({ id: "toast.alert" }),
          description: intl.formatMessage(
            { id: "productsSupplierListPage.errorToast.text" },
            { manufacturedItemName }
          ),
          type: "alert",
        });
      }
    }

    setSelectedRowKeys([]);
  };

  useEffect(() => {
    setVisible(false);
  }, [isArchiveSelectedDisabled]);

  return (
    <Table.Header className={styles.root} listingResult={listingResult}>
      {productsRead && isRetailer && (
        <Button
          loading={
            isExportAllTradeItemsLoading ||
            isExportSelectedTradeItemsLoading ||
            isExportAllManufacturedItemsLoading ||
            isExportSelectedManufacturedItemsLoading ||
            isArchiveSelectedLoading
          }
          // disabled={!selectedRowKeys.length && !isAllSelected}
          type="tertiary"
          hasItems
          items={buttonActions}
          data-test-id="md-products-actions-btn"
        >
          {intl.formatMessage({
            id: "general.actions",
          })}
        </Button>
      )}

      {!isRetailer && (
        <Button
          type="tertiary"
          hasItems
          items={supplierButtonActions}
          data-test-id="md-products-supplier-actions-btn"
        >
          {intl.formatMessage({
            id: "general.actions",
          })}
        </Button>
      )}

      <CertificateModal
        open={isCertificationModalOpen}
        objectLink="product"
        useAI={true}
        onClose={handleModalClose}
        onCertificateCreated={certificateId =>
          handleMultipleCertificatesCreated(
            certificateId,
            selectedRowKeys,
            supplierData
          )
        }
      />
    </Table.Header>
  );
};

export default ProductHeader;
