import React, { useState, useEffect } from "react";
import { Button, Icon } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { quickLinksMessages } from "../../translations/messages";
import { QuicklinksProps } from "./types";
import { AUTH_TYPE } from "src/utils/constants";
import { useAuthType } from "src/hooks";
import useRedirects from "./useRedirects";
import Language from "../Language";
import classNames from "classnames";

const QuickLinks: React.FC<QuicklinksProps> = ({
  user,
  onLogout,
  isTon,
  isMasquerade,
  hideMenu,
  onUnmasquerade,
  open,
}) => {
  const { formatMessage } = useIntl();
  const [isLanguageSelectionMenuVisible, setIsLanguageSelectionMenuVisible] =
    useState(false);

  useEffect(() => {
    if (!open && isTon) {
      setIsLanguageSelectionMenuVisible(false);
    }
  }, [open, isTon]);
  const {
    redirectToMyProfile,
    redirectToMySettings,
    redirectToNotificationSettings,
    redirectToAdminSettings,
    redirectToCompanyProfile,
    redirectToChangePassword,
    redirectToPrivacy,
    redirectToMyBookmarks,
    redirectToUnmasquerade,
  } = useRedirects();

  const { authType } = useAuthType(user.owningCompanyId);

  const onLinkClick = redirectLink => () => {
    hideMenu();
    redirectLink();
  };

  const toggleLanguageMenu = () => {
    setIsLanguageSelectionMenuVisible(isVisible => !isVisible);
  };

  return (
    <div className="quick-links" data-test-id="user-menu-links">
      <div className="quick-link" data-test-id="user-menu-links-my-profile">
        <Button
          onClick={onLinkClick(redirectToMyProfile)}
          type="link"
          size="large"
          linkColor="grey-5"
        >
          {formatMessage(quickLinksMessages.myProfile)}
        </Button>
      </div>

      <div className="quick-link" data-test-id="user-menu-links-my-settings">
        <Button
          onClick={onLinkClick(redirectToMySettings)}
          type="link"
          size="large"
          linkColor="grey-5"
        >
          {formatMessage(quickLinksMessages.mySettings)}
        </Button>
      </div>

      <div
        className={classNames("quick-link language-selection", {
          "language-selection__visible": isLanguageSelectionMenuVisible,
        })}
        data-test-id="user-menu-links-languages"
      >
        <Button
          className="language-selection-menu-trigger"
          onClick={toggleLanguageMenu}
          type="link"
          size="large"
          linkColor="grey-5"
          data-test-id="user-menu-language-selection-menu-trigger"
        >
          <span>{formatMessage(quickLinksMessages.languages)} </span>
          <Icon
            name={isLanguageSelectionMenuVisible ? "arrow-up" : "arrow-down"}
            size="small"
            color="primary"
          />
        </Button>

        <Language
          user={user}
          onLanguageChange={() => {
            if (window.location && !isTon) {
              window.location.reload();
            }
          }}
        ></Language>
      </div>

      <div className="quick-link" data-test-id="user-menu-links-notif-settings">
        <Button
          onClick={onLinkClick(redirectToNotificationSettings)}
          type="link"
          size="large"
          linkColor="grey-5"
        >
          {formatMessage(quickLinksMessages.notificationSettings)}
        </Button>
      </div>

      {user?.isAccountAdministrator && (
        <div
          className="quick-link"
          data-test-id="user-menu-links-admin-settings"
        >
          <Button
            onClick={onLinkClick(redirectToAdminSettings)}
            type="link"
            size="large"
            linkColor="grey-5"
          >
            {formatMessage(quickLinksMessages.administrationSettings)}
          </Button>
        </div>
      )}

      <div
        className="quick-link"
        data-test-id="user-menu-links-company-profile"
      >
        <Button
          onClick={onLinkClick(redirectToCompanyProfile)}
          type="link"
          size="large"
          linkColor="grey-5"
        >
          {formatMessage(quickLinksMessages.companyProfile)}
        </Button>
      </div>

      {authType !== AUTH_TYPE.OAUTH && (
        <div className="quick-link" data-test-id="user-menu-links-password">
          <Button
            onClick={onLinkClick(redirectToChangePassword)}
            type="link"
            size="large"
            linkColor="grey-5"
          >
            {formatMessage(quickLinksMessages.changePassword)}
          </Button>
        </div>
      )}
      {isTon && (
        <>
          <div className="quick-link" data-test-id="user-menu-links-privacy">
            <Button
              onClick={onLinkClick(redirectToPrivacy)}
              type="link"
              size="large"
              linkColor="grey-5"
            >
              {formatMessage(quickLinksMessages.privacy)}
            </Button>
          </div>

          <div className="quick-link" data-test-id="user-menu-links-bookmarks">
            <Button
              onClick={onLinkClick(redirectToMyBookmarks)}
              type="link"
              size="large"
              linkColor="grey-5"
            >
              {formatMessage(quickLinksMessages.bookmarks)}
            </Button>
          </div>
        </>
      )}

      <div className="divider" />

      <div className="quick-link" data-test-id="user-menu-links-log-out">
        <Button onClick={onLinkClick(onLogout)} type="link" size="large">
          {formatMessage(quickLinksMessages.logOutButton)}
        </Button>
      </div>

      {isMasquerade && (
        <div className="quick-link" data-test-id="user-menu-links-unmasquerade">
          <Button
            onClick={() => {
              if (onUnmasquerade) {
                onUnmasquerade();
              }
              onLinkClick(redirectToUnmasquerade)();
            }}
            type="link"
            size="large"
          >
            {formatMessage(quickLinksMessages.unmasquerade)}
          </Button>
        </div>
      )}
    </div>
  );
};

export default QuickLinks;
