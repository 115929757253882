import React, { createContext, useState, FC, useEffect } from "react";
import { defaultContextValue, SidebarContextProviderProps, SidebarContextType } from "./types";
import { Section } from "../types";
import { findSubsectionWithParent } from "../utilities";

const SidebarContext = createContext<SidebarContextType>(defaultContextValue);

const SidebarContextProvider: FC<SidebarContextProviderProps> = ({
  sidebarSections, showSectionIcons, shouldSectiontoggle, showStatusDot, onSectionClick,
  onSubSectionClick, showSubectionLink, setSidebarSectionData, children,
}) => {
  const [isSidebarExpanded, setSidebarExpanded] = useState<boolean>(true);
  const [selectedSubSectionId, setSelectedSubSectionId] = useState<string>("");
  const [selectedSectionId, setSelectedSectionId] = useState<string>("");
  const [isMinified, setIsMinified] = useState<boolean>(false);
  const [isSearchBoxFocus, setSearchBoxFocus] = useState<boolean>(false);

  const toggleMinified = () => {
    setSidebarSectionData(sidebarSections);
    setIsMinified(prevState => !prevState);
    setSearchBoxFocus(prevState => {
      if (prevState) {
        return !prevState;
      }
    });
  };

  const onClickSubSection = (subSectionId: string) => setSelectedSubSectionId(subSectionId);

  useEffect(() => {
    const section = sidebarSections?.filter((section : Section) => section?.defaultOpen);
    if (section?.length > 0) {
      setSelectedSectionId(section[0]?.sectionId);
    }
  }, [sidebarSections]);

  useEffect(() => {
    if (selectedSectionId !== "") {
      const sectionData = sidebarSections?.filter((section : Section) => section.sectionId === selectedSectionId);
      onSectionClick(sectionData);
    }
  }, [selectedSectionId]);

  useEffect(() => {
    if (selectedSubSectionId !== "") {
      const section = findSubsectionWithParent(sidebarSections, selectedSubSectionId);
      onSubSectionClick(section);
    }
  }, [selectedSubSectionId]);

  return (
    <SidebarContext.Provider value={{
      isSidebarExpanded,
      setSidebarExpanded,
      onClickSubSection,
      selectedSubSectionId,
      setSelectedSubSectionId,
      setIsMinified,
      isMinified,
      selectedSectionId,
      setSelectedSectionId,
      toggleMinified,
      showSectionIcons,
      shouldSectiontoggle,
      showStatusDot,
      setSearchBoxFocus,
      isSearchBoxFocus,
      showSubectionLink,
    }}>
      {children}
    </SidebarContext.Provider>
  );
};

export { SidebarContext, SidebarContextProvider };
