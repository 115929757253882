import React, { useState, useMemo } from "react";
import { debounce } from "lodash";
import { Section, ModuleSidebarNavigatorProps } from "./types";
import SidebarContextProvider from "./context";
import { filterSidebarSections } from "./utilities";
import SidebarLayout from "./components";

const ModuleSidebarNavigator: React.FC<ModuleSidebarNavigatorProps> = ({
  sideBarHeaderText,
  sidebarSections,
  footerProps,
  showSearchBox,
  showHeaderText,
  onSectionClick,
  onSubSectionClick,
  showSectionIcons,
  shouldSectiontoggle,
  showStatusDot,
  showSubectionLink,
}) => {
  const [sidebarSectionData, setSidebarSectionData] = useState<Section[]>(sidebarSections);

  const debouncedSearchHandler = useMemo(
    () =>
      debounce((input: string) => {
        if (input.trim()) {
          const filteredData = filterSidebarSections(sidebarSections, input);
          setSidebarSectionData(filteredData);
        } else {
          setSidebarSectionData(sidebarSections);
        }
      }, 300),
    [sidebarSections]
  );

  const handleSearchChange = (event: React.ChangeEvent<any>) => {
    debouncedSearchHandler(event.target.value);
  };

  return (
    <div data-test-id = "moduleSideBarNavigator">
      <SidebarContextProvider
        sidebarSections={sidebarSections}
        showSectionIcons={showSectionIcons}
        shouldSectiontoggle={shouldSectiontoggle}
        onSectionClick={onSectionClick}
        onSubSectionClick={onSubSectionClick}
        showStatusDot = {showStatusDot}
        showSubectionLink={showSubectionLink}
        setSidebarSectionData = {setSidebarSectionData}
      >
        <SidebarLayout
          sideBarHeaderText={sideBarHeaderText}
          footerProps={footerProps}
          showSearchBox={showSearchBox}
          showHeaderText={showHeaderText}
          sidebarSectionData={sidebarSectionData}
          handleSearchChange={handleSearchChange}
        />
      </SidebarContextProvider>
    </div>

  );
};

export default ModuleSidebarNavigator;
