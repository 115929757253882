type State = {
  accessToken?: string;
  permissions: string;
  companyActivity?: number;
  globalUserId?: string;
  companyGroupId?: string;
};

export const initialState: State = {
  accessToken: null,
  permissions: "",
  companyActivity: null,
  globalUserId: "",
  companyGroupId: null,
};
