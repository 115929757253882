import React, { useContext } from "react";
import { AppsListContext } from "../../contexts";
import AppItem from "../AppItem";
import { AppListProps } from "./types";

const AppList: React.FC<AppListProps> = ({
  handleCreationPopUp,
  variant,
  collapsed,
  size = "extra-large",
}) => {
  const { appList } = useContext(AppsListContext);

  return (
    <>
      {appList.map(appItem => (
        <AppItem
          key={appItem.applicationTypeId}
          appItem={appItem}
          handleCreationPopUp={handleCreationPopUp}
          variant={variant}
          collapsed={collapsed}
          size={size}
        />
      ))}
    </>
  );
};

export default AppList;
