/* istanbul ignore file */
import { InitializeInstancesManagerProps } from "./types";

const AXIOS_INSTANCES = {
  CUMD: undefined,
  PMD: undefined,
  RLMD: undefined,
  SMD: undefined,
  TAGS: undefined,
  PKG: undefined,
  CLB: undefined,
  TON: undefined,
  DOCUMENT: undefined,
  NOTIF: undefined,
  DISCUSS: undefined,
  CATALOGUE: undefined,
  AI: undefined,
  CERTIFICATION: undefined,
};

export const getAxiosInstanceOrThrowError = (key: string) => {
  if (!AXIOS_INSTANCES[key]) {
    throw new Error(
      `${key} instance is not initialize. Please run initializeInstancesManager first`
    );
  }
  return AXIOS_INSTANCES[key];
};

export const AxiosInstancesManager = Object.freeze({
  getCumd: () => getAxiosInstanceOrThrowError("CUMD"),
  getPmd: () => getAxiosInstanceOrThrowError("PMD"),
  getRlmd: () => getAxiosInstanceOrThrowError("RLMD"),
  getSmd: () => getAxiosInstanceOrThrowError("SMD"),
  getTags: () => getAxiosInstanceOrThrowError("TAGS"),
  getPkg: () => getAxiosInstanceOrThrowError("PKG"),
  getClb: () => getAxiosInstanceOrThrowError("CLB"),
  getTon: () => getAxiosInstanceOrThrowError("TON"),
  getDocument: () => getAxiosInstanceOrThrowError("DOCUMENT"),
  getNotif: () => getAxiosInstanceOrThrowError("NOTIF"),
  getDiscuss: () => getAxiosInstanceOrThrowError("DISCUSS"),
  getCatalogue: () => getAxiosInstanceOrThrowError("CATALOGUE"),
  getAI: () => getAxiosInstanceOrThrowError("AI"),
  getCertification: () => getAxiosInstanceOrThrowError("CERTIFICATION"),
});

export const initializeInstancesManager = ({
  axiosInstanceCUMD,
  axiosInstancePMD,
  axiosInstanceRLMD,
  axiosInstanceSMD,
  axiosInstanceTAGS,
  axiosInstancePKG,
  axiosInstanceCLB,
  axiosInstanceTON,
  axiosInstanceDOCUMENT,
  axiosInstanceNOTIF,
  axiosInstanceDISCUSS,
  axiosInstanceCATALOGUE,
  axiosInstanceAI,
  axiosInstanceCertification,
}: InitializeInstancesManagerProps) => {
  if (!Object.isFrozen(AXIOS_INSTANCES)) {
    AXIOS_INSTANCES.CUMD = axiosInstanceCUMD;
    AXIOS_INSTANCES.PMD = axiosInstancePMD;
    AXIOS_INSTANCES.RLMD = axiosInstanceRLMD;
    AXIOS_INSTANCES.SMD = axiosInstanceSMD;
    AXIOS_INSTANCES.TAGS = axiosInstanceTAGS;
    AXIOS_INSTANCES.PKG = axiosInstancePKG;
    AXIOS_INSTANCES.CLB = axiosInstanceCLB;
    AXIOS_INSTANCES.TON = axiosInstanceTON;
    AXIOS_INSTANCES.DOCUMENT = axiosInstanceDOCUMENT;
    AXIOS_INSTANCES.NOTIF = axiosInstanceNOTIF;
    AXIOS_INSTANCES.DISCUSS = axiosInstanceDISCUSS;
    AXIOS_INSTANCES.CATALOGUE = axiosInstanceCATALOGUE;
    AXIOS_INSTANCES.AI = axiosInstanceAI;
    AXIOS_INSTANCES.CERTIFICATION = axiosInstanceCertification;

    Object.freeze(AXIOS_INSTANCES);
  } else {
    console.warn("AxiosInstancesManager is already initialize");
  }
};
