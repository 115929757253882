import { useEffect, useState } from "react";
import { fetchAllergensHierarchy } from "src/apis/catalogue";
import { AllergensHierarchyResponse } from "src/apis/catalogue/types";
import { useLanguagePreference } from "src/hooks";
import { isListEmpty } from "src/utils/general";

const useAllergens = ({ selectedAllergensProp }: { selectedAllergensProp: AllergensHierarchyResponse[] }) => {
  const languageCode = useLanguagePreference();
  const [allergensData, setAllergensData] = useState<AllergensHierarchyResponse[]>([]);
  const [selectedAllergens, setSelectedAllergens] = useState<AllergensHierarchyResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAllergens = async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchAllergensHierarchy(languageCode);
      setAllergensData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllergens();
  }, [languageCode]);

  useEffect(() => {
    if (selectedAllergensProp) {
      selectedAllergensProp.forEach(allergen => {
        if (allergen.hasChildren && isListEmpty(allergen.children)) {
          const childrenData = allergensData.find(({ id }) => allergen.id === id)?.children || [];
          allergen.children = [...childrenData];
        }
      });
      const newSelectedAllergens = JSON.parse(JSON.stringify(selectedAllergensProp));
      setSelectedAllergens(newSelectedAllergens);
    }
  }, [selectedAllergensProp, allergensData]);

  return {
    isLoading,
    allergensData,
    selectedAllergens,
    setSelectedAllergens,
  };
};

export default useAllergens;
