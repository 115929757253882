import { AxiosInstancesManager } from "../../configs/AxiosInstancesManager";
import { AllergensHierarchyResponse } from "./types";
export const fetchAllergensHierarchy = (
  languageCode: string
) => {
  const catalogueInstance = AxiosInstancesManager.getCatalogue();

  return catalogueInstance.get<AllergensHierarchyResponse[]>(
    `/api/Allergens/Hierarchy`,
    {
      params: { languageCode },
    }
  );
};
