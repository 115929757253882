import React, { useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { Heading, Button } from "@trace-one/design-system";
import { compose } from "redux";

import { useAppDispatch } from "reduxStore";
import {
  selectUserLanguageCode,
  selectUserOwningCompanyId,
  selectUserSelectedCompany,
} from "reduxStore/user/selectors";

import HeaderDetails from "components/HeaderDetails";
import { withLanguages } from "shared/hocs";

import { fetchDocumentsListGlobalSearch } from "../../../../reduxStore/documentList/asyncActions";
import { fetchProduct } from "../../../../reduxStore/productDetails/asyncActions";
import { selectProductDetailsData } from "../../../../reduxStore/productDetails/selectors";

import ProductCertificatesTab from "./components/ProductCertificatesTab";
import styles from "./ProductRetailerDetails.module.less";

const enhance = compose<React.FC>(withLanguages());

const ProductRetailerDetails = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const languageCode = useSelector(selectUserLanguageCode);

  const { productId, manufacturedItemId } = useParams<{
    productId?: string;
    manufacturedItemId?: string;
  }>();

  const ownerCompanyId = useSelector(selectUserOwningCompanyId);
  const { companyId: selectedCompanyId } =
    useSelector(selectUserSelectedCompany) ?? {};

  const { productData } = useSelector(selectProductDetailsData);
  const selectedManufacturedItem = productData?.manufacturedItems?.find(
    item => item?.id === manufacturedItemId
  );

  const refetchProduct = useCallback(() => {
    dispatch(
      fetchProduct({
        id: productId,
        ownerCompanyId: selectedCompanyId || ownerCompanyId,
        isCompanySelected: !!selectedCompanyId,
        languageCode,
      })
    ).then(() => {
      dispatch(
        fetchDocumentsListGlobalSearch({
          params: {
            languageCode,
          },
          body: {
            tradeItemId: productId,
          },
        })
      ).then(() => {});
    });
  }, [productId, selectedCompanyId, ownerCompanyId, dispatch]);

  useEffect(() => {
    refetchProduct();
  }, [refetchProduct]);

  const HeaderSubtitle = () => {
    return (
      <div className={styles.headerSubtitle}>
        <div>
          <Heading
            size="xxs"
            data-test-id="product-retailer-details-subheader-product-title"
          >
            {intl.formatMessage({ id: "general.product" })}:
          </Heading>
          <Button
            type="link"
            linkColor="primary-color"
            onClick={() => history.push(`/products/${productId}`)}
            data-test-id="product-retailer-details-subheader-product-info"
          >
            {productData?.tradeItemName}
          </Button>
        </div>
        <div>
          <Heading
            size="xxs"
            data-test-id="product-retailer-details-subheader-supplier-title"
          >
            {intl.formatMessage({ id: "general.supplier" })}:
          </Heading>
          <Button
            type="link"
            linkColor="primary-color"
            onClick={() =>
              history.push(
                `/suppliers/${selectedManufacturedItem?.supplierCompanyId?.value}`
              )
            }
            data-test-id="product-retailer-details-subheader-supplier-info"
          >
            {selectedManufacturedItem?.supplierCompanyId?.companyDisplayName}
          </Button>
        </div>
      </div>
    );
  };

  const tabList = [
    {
      label: (
        <Heading size="xs">
          {intl.formatMessage({ id: "general.classificates" })}
        </Heading>
      ),
      content: (
        <ProductCertificatesTab productData={selectedManufacturedItem} />
      ),
    },
  ];

  return (
    <div className={styles.root}>
      <HeaderDetails
        withBackNav
        redirectToCustomPage="/products/list"
        title={
          selectedManufacturedItem?.manufacturedItemName !== "" || undefined
            ? selectedManufacturedItem?.manufacturedItemName
            : productData?.tradeItemName
        }
        subtitle={HeaderSubtitle()}
        tabs={tabList}
      />
    </div>
  );
};

export default enhance(ProductRetailerDetails);
