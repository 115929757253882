import { CommentData } from "models";

import getUsersCollectionMap from "shared/utils/getUsersCollectionMap";

export type AuthorsCommentsType = (CommentData & {
  authorFullname: string;
})[];

export default async function getAuthorsComments({
  comments,
  isCompanySelected,
}: {
  comments: CommentData[];
  isCompanySelected?: boolean;
}): Promise<AuthorsCommentsType> {
  if (!comments.length) {
    return [];
  }
  const authorIds = comments.map(({ authorId }) => authorId);

  const usersMap = await getUsersCollectionMap({
    userIds: authorIds,
    isCompanySelected,
  });

  const sortedCommentsByDateDESC = [...comments].sort(
    (a, b) =>
      new Date(b.commentDate ?? 0).getTime() -
      new Date(a.commentDate ?? 0).getTime()
  );
  return sortedCommentsByDateDESC.map(comment => {
    const author = usersMap[comment.authorId];
    const authorFullname = [author?.userFirstName, author?.userLastName]
      .filter(item => item)
      .join(" ");
    return {
      ...comment,
      authorFullname,
    };
  });
}
