import React from "react";
import { Section } from "../../types";
import Item from "../Item";
import { SidebarProps } from "./types";

const Sidebar: React.FC<SidebarProps> = ({
  sidebarSectionData,
}) => {
  return (
    <div data-test-id = "module-sidebar-navigator-menu">
        {sidebarSectionData?.map((section: Section) => (
          <Item
            key={section.sectionId}
            section={section}
          />
        ))}
    </div>
  );
};

export default Sidebar;
