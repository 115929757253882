import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { compose } from "@reduxjs/toolkit";
import { Heading, Container, KPI as Kpi } from "@trace-one/design-system";
import { RcFile } from "antd/lib/upload";

import { PmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import { useAppDispatch } from "reduxStore";
import { selectIsRetailer } from "reduxStore/oidc/selectors";
import { fetchKpiIndicators } from "reduxStore/productList/asyncActions";
import {
  selectProductKpiData,
  selectIsKpiDataLoading,
} from "reduxStore/productList/selectors";
import { clearKpiData } from "reduxStore/productList/slice";
import {
  selectUserCompanyGroupList,
  selectUserOwningCompanyId,
  selectUserSelectedCompany,
} from "reduxStore/user/selectors";

import BtnCreateListItem from "components/BtnCreateListItem";
import CompanyHeader from "components/CompanyHeader";
import CsvImport from "components/CsvImport";
import Spinner from "components/Spinner";
import {
  TeamMemberResponsibilityParentItem,
  productKpiName,
} from "shared/constants";
import {
  BaseHocProps,
  withBrands,
  withCategoryLists,
  withNetContentMeasures,
  withTeamMemberResponsibilities,
  withTradeItemStatuses,
} from "shared/hocs";
import withUsers from "shared/hocs/withUsers";
import useToast from "shared/hooks/useToast";
import encodeFileAsBase64 from "shared/utils/encodeFileAsBase64";

import styles from "./ProductList.module.less";
import ProductTable from "./ProductTable";

interface ProductListProps extends BaseHocProps {}

const enhance = compose<React.FC<ProductListProps>>(
  withBrands(),
  withCategoryLists(),
  withNetContentMeasures(),
  withTradeItemStatuses(),
  withUsers(),
  withTeamMemberResponsibilities({
    includeParentItems: true,
    parentItemId: TeamMemberResponsibilityParentItem.RETAILER,
  })
);

export const ProductList: React.FC<ProductListProps> = ({ isInitiated }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { companyId: selectedCompanyId } =
    useSelector(selectUserSelectedCompany) ?? {};
  const history = useHistory();
  const { productsWrite, productsImport, productsRead } = usePermissions();
  const ownerCompanyId = useSelector(selectUserOwningCompanyId);

  const groupList = useSelector(selectUserCompanyGroupList);
  const kpiData = useSelector(selectProductKpiData);
  const kpiLoading = useSelector(selectIsKpiDataLoading);
  const isRetailer = useSelector(selectIsRetailer);
  const [selectedKpi, setSelectedKpi] = useState<productKpiName>(null);

  const toast = useToast();

  useEffect(() => {
    dispatch(
      fetchKpiIndicators({
        ownerCompanyId: selectedCompanyId || ownerCompanyId,
      })
    );
    return () => {
      dispatch(clearKpiData());
    };
  }, []);

  const handleImportCsv = async ({ file }: { file: RcFile }) => {
    const fileContentBase64Encoded = await encodeFileAsBase64(file);
    await PmdAPI.commandCsvImport({
      fileName: file.name,
      // @ts-ignore
      fileContentBase64Encoded,
    });
    toast.info({
      description: intl.formatMessage({
        id: "import.csv.informationSuccess",
      }),
    });
    return true;
  };

  if (isRetailer && kpiLoading) {
    return <Spinner underHeader />;
  }

  return (
    <Container isPageWrapper>
      <div className={styles.headActions}>
        <div className={styles.mainContainer}>
          <div>
            {!!groupList.length && <CompanyHeader />}
            <Heading size="l">
              {intl.formatMessage({
                id: "productsListPage.title",
              })}
            </Heading>
          </div>
          {isRetailer && (
            <BtnCreateListItem
              hideButton={!productsWrite}
              text={intl.formatMessage({
                id: "productsListPage.createProduct",
              })}
              onClick={() => history.push("/products/create")}
              iconName="product-add"
            />
          )}
        </div>

        <div>
          {productsImport && (
            <>
              <CsvImport fileSizeLessThanMB={3} onImport={handleImportCsv} />
            </>
          )}
        </div>
      </div>
      {isRetailer && (
        <div className={styles.kpiContainer}>
          <Kpi
            kpiName={intl.formatMessage({
              id: "productsListPage.kpi.withNoBrand",
            })}
            kpiNameGraphic="missing"
            kpiValue={kpiData?.NoBrandCount}
            clickable={true}
            data-test-id="NoBrandCountKpi"
            onClick={() => setSelectedKpi("NoBrandCount")}
            selected={selectedKpi === "NoBrandCount"}
          />
          <Kpi
            kpiName={intl.formatMessage({
              id: "productsListPage.kpi.withNoCategory",
            })}
            kpiNameGraphic="missing"
            data-test-id="NoCategoryCountKpi"
            kpiValue={kpiData?.NoCategoryCount}
            clickable={true}
            onClick={() => setSelectedKpi("NoCategoryCount")}
            selected={selectedKpi === "NoCategoryCount"}
          />
          <Kpi
            kpiName={intl.formatMessage({
              id: "productsListPage.kpi.withNoContact",
            })}
            kpiNameGraphic="missing"
            kpiValue={kpiData?.NoContactCount}
            clickable={true}
            data-test-id="NoContactCountKpi"
            onClick={() => setSelectedKpi("NoContactCount")}
            selected={selectedKpi === "NoContactCount"}
          />
          <Kpi
            kpiName={intl.formatMessage({
              id: "productsListPage.kpi.withNoGtin",
            })}
            kpiNameGraphic="missing"
            kpiValue={kpiData?.NoGtin}
            clickable={true}
            data-test-id="NoGtinKpi"
            onClick={() => setSelectedKpi("NoGtinKpi")}
            selected={selectedKpi === "NoGtinKpi"}
          />
        </div>
      )}
      <Spinner spinning={!isInitiated} hideChildrenVisibilityOnLoading>
        <ProductTable
          selectedKpi={selectedKpi}
          setSelectedKpi={setSelectedKpi}
          productsRead={productsRead}
        />
      </Spinner>
    </Container>
  );
};

export default enhance(ProductList);
