import CatalogueDataApi from "@trace-one/api-clients.catalogue";
import CertificationApi from "@trace-one/api-clients.certification";
import CompanyUserMasterDataApi from "@trace-one/api-clients.cumd";
import DocumentManagementSystemApi from "@trace-one/api-clients.dms";
import GlobalSearchApi from "@trace-one/api-clients.global-search";
import ProductMasterDataApi from "@trace-one/api-clients.pmd";
import ProjectManagementSystemApi from "@trace-one/api-clients.project";
import ReferenceListMasterDataApi from "@trace-one/api-clients.rlmd";
import SiteMasterData from "@trace-one/api-clients.smd";

import AXIOS_INSTANCES from "./axiosInstances";

export const CumdAPI = new CompanyUserMasterDataApi(AXIOS_INSTANCES.CUMD);
export const PmdAPI = new ProductMasterDataApi(AXIOS_INSTANCES.PMD);
export const RlmdAPI = new ReferenceListMasterDataApi(AXIOS_INSTANCES.RLMD);
export const SmdAPI = new SiteMasterData(AXIOS_INSTANCES.SMD);
export const DmsAPI = new DocumentManagementSystemApi(AXIOS_INSTANCES.DMS);
export const ProjectAPI = new ProjectManagementSystemApi(
  AXIOS_INSTANCES.PROJECT
);
export const CatalogueAPI = new CatalogueDataApi(AXIOS_INSTANCES.CATALOGUE);
export const GlobalSearchAPI = new GlobalSearchApi(AXIOS_INSTANCES.GSS);

export const CertificationAPI = new CertificationApi(
  AXIOS_INSTANCES.CERTIFICATION
);
