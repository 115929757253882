import { createAsyncThunk } from "@reduxjs/toolkit";

import { CumdAPI, PmdAPI } from "apis";
import { CompanyData } from "models";

import { ManufacturingItemStatus } from "shared/constants";
import buildCompanyTitle from "shared/utils/buildCompanyTitle";
import getAuthorsComments from "shared/utils/getAuthorsComments";
import getContacts from "shared/utils/getContacts";

async function getManufacturedItems({
  tradeItemId,
  ownerCompanyId,
}: {
  tradeItemId: string;
  ownerCompanyId: string;
}) {
  const {
    data: { manufacturedItems },
  } = await PmdAPI.getManufacturedItemsByFilters({
    ownerCompanyId,
    tradeItemId,
  });

  const companyIds = manufacturedItems
    .flatMap(({ supplierCompanyId, packerCompanyId }) => [
      packerCompanyId,
      supplierCompanyId,
    ])
    .filter(id => id);

  let companies: CompanyData[] = [];

  if (companyIds.length > 0) {
    companies = await CumdAPI.getCompaniesByFilters(
      { companyIds },
      { relatedOwnerCompanyId: ownerCompanyId, includePrivate: true }
    ).then(({ data }) => data.companies);
  }

  const manufacturedItemsForForm = manufacturedItems
    .map(({ supplierCompanyId, packerCompanyId, ...rest }) => {
      let mappedSupplierCompanyId: {
        value?: string;
        name?: string;
        companyRelationExternalIdentifier?: string;
        companyRelationExternalName?: string;
        companyType?: string;
      };
      let mappedPackerCompanyId: {
        value?: string;
        label?: string;
      };
      if (supplierCompanyId) {
        const company = companies.find(
          ({ companyId }) => companyId === supplierCompanyId
        );

        mappedSupplierCompanyId = {
          value: supplierCompanyId,
          name: buildCompanyTitle(company),
          companyRelationExternalIdentifier:
            company?.companyRelationExternalIdentifier,
          companyRelationExternalName: company?.companyRelationExternalName,
          companyType: company?.companyType,
        };
      }
      if (packerCompanyId) {
        mappedPackerCompanyId = {
          value: packerCompanyId,
          label: companies.find(
            ({ companyId }) => companyId === packerCompanyId
          )?.companyDisplayName,
        };
      }
      return {
        ...rest,
        packerCompanyId: mappedPackerCompanyId,
        supplierCompanyId: mappedSupplierCompanyId,
      };
    })
    .sort((a, b) => {
      if (a.manufacturedItemStatus !== b.manufacturedItemStatus) {
        return a.manufacturedItemStatus === ManufacturingItemStatus.ACTIVE
          ? -1
          : 1;
      } else if (
        a.manufacturedItemStatus === ManufacturingItemStatus.ACTIVE &&
        b.manufacturedItemStatus === ManufacturingItemStatus.ACTIVE
      ) {
        return (
          new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime()
        );
      } else if (
        a.manufacturedItemStatus === ManufacturingItemStatus.INACTIVE &&
        b.manufacturedItemName === ManufacturingItemStatus.INACTIVE
      ) {
        return (
          new Date(b.lastStatusUpdateDateTime).getTime() -
          new Date(a.lastStatusUpdateDateTime).getTime()
        );
      }
      return 0;
    });

  return manufacturedItemsForForm;
}

export const fetchProduct = createAsyncThunk(
  "productForm/fetchProduct",
  async (
    { id, ownerCompanyId }: { id: string; ownerCompanyId: string },
    thunkAPI
  ) => {
    try {
      const {
        data: {
          tradeItemStatus,
          itemName,
          commentIds,
          netContentUnit,
          netContentValue,
          category,
          comments,
          ...product
        },
      } = await PmdAPI.getTradeItemById(id);
      const [contacts, manufacturedItems, commentsWithUserData] =
        await Promise.all([
          getContacts({ contacts: product.contacts }),
          getManufacturedItems({ tradeItemId: id, ownerCompanyId }),
          getAuthorsComments({ comments }),
        ]);

      // to be removed after BE update
      delete product.retailerPackagingManager;
      delete product.retailerProductManager;
      delete product.retailerQualityManager;

      return {
        tradeItemStatusId: tradeItemStatus,
        tradeItemName: itemName,
        ...product,
        contacts,
        manufacturedItems,
        comments: commentsWithUserData,
      };
    } catch (err) {
      const status = err?.response?.status ?? 500;
      return thunkAPI.rejectWithValue({ status });
    }
  }
);
