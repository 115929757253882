export enum ERROR_CODES {
  FILE_TOO_BIG = "1d6a4f16-ddba-4915-8c7e-51d4ac8fcc81",
  FORBIDDEN_FILE_EXTENSION = "2c8ff17e-3ee9-4304-b65e-4d245ffbc168",
  UPLOAD_EMPTY_FILE = "68d8ae14-1825-42e7-a05a-cb9d198c3632",
  NOT_FOUND = "81d0eee0-1160-4320-919f-b6912b22f83e",
}

export enum ERROR_STATUSES {
  PAYLOAD_TOO_LARGE = 413,
}

export enum REFERENCE_LIST_ID {
  LANGUAGES = "7d9b1347-8942-4197-88c9-d146fc8d2320",
  APPS_REFERENCE_LIST_ID = "8378901f-0f83-4b62-a6fc-36a137610aa0",
  REQUESTABLE_APPS_LIST_ID = "a9ef13c0-041f-4322-8e8b-052be1112f7a",
}

export enum REFERENCE_LIST_NAME {
  COUNTRIES = "countries",
  JOB_TITLES = "Job_Title",
  NET_CONTENT = "NetContent",
}

export const APPLICATIONS = [
  {
    id: 0,
    name: "Quality Portal",
    value: "Quality Portal",
    graphic: "qp-dark",
  },
  {
    id: 1,
    name: "Pack Portal",
    value: "Pack Portal",
    graphic: "pp-dark",
  },
  {
    id: 8,
    name: "PDM",
    value: "PDM",
    graphic: "pdm",
  },
  {
    id: 9,
    name: "Business Intelligence",
    value: "Business Intelligence",
    graphic: "bi",
  },
  {
    id: 110,
    name: "Insight",
    value: "Insight",
    graphic: "insight",
  },
  {
    id: 22,
    name: "PDM NA",
    value: "PDM NA",
    graphic: "pdm_na",
  },
  {
    id: 23,
    name: "PDM FIND",
    value: "PDM FIND",
    graphic: "pdm_ms",
  },
  {
    id: 40,
    name: "Network",
    value: "Ton",
    graphic: "ton",
  },
  {
    id: 50,
    name: "Project",
    value: "Project",
    graphic: "project",
  },
  {
    id: 51,
    name: "QMS",
    value: "QMS",
    graphic: "qms",
  },
  {
    id: 70,
    name: "Specification",
    value: "Spec",
    graphic: "spec",
  },
  {
    id: 80,
    name: "Rfx",
    value: "Rfx",
    graphic: "rfx",
  },
  {
    id: 90,
    name: "Administration",
    value: "Administration",
    graphic: "admin",
  },
  {
    id: 100,
    name: "Master Data",
    value: "MD",
    graphic: "md",
  },
];

export enum NOTIFICATION_TYPE {
  WEB = "Web",
  EMAIL = "Email",
  DIGEST = "Digest",
}

export const ALLERGENS = [
  {
    id: "b085c52d-bd03-47fd-a35c-7c55338b0508",
    name: "milk",
  },
  {
    id: "2588271c-baca-4d57-a805-b66635b6a6b6",
    name: "nuts",
  },
  {
    id: "857a5b05-c8fa-41e8-a446-5356e50e6df7",
    name: "wheat",
  },
  {
    id: "58fcadd2-d5ff-4e34-af6d-7de3f52de568",
    name: "egg",
  },
  {
    id: "51b09443-9587-4a12-a3f8-ffd250fcd85d",
    name: "soy",
  },
  {
    id: "b1705138-933c-45c2-a15d-9cc263387531",
    name: "sesame",
  },
  {
    id: "0b8d7304-2d37-47a0-be9b-f94f5303c90c",
    name: "sulphite",
  },
  {
    id: "5e7e2a1f-f4b7-4d8a-adbd-c9b8d07d7397",
    name: "mustard",
  },
  {
    id: "e9c6c296-d205-496a-a971-b5922175134f",
    name: "peanuts",
  },
  {
    id: "beae650b-bd6d-4d77-baab-6c0e004d65f4",
    name: "celery",
  },
  {
    id: "e10503c1-947e-4a6d-9f26-700ace255eb1",
    name: "fish",
  },
  {
    id: "30565e30-ebc8-4a71-8c70-9176cfde7fe5",
    name: "mollusc",
  },
  {
    id: "ce5547d4-625f-4b10-aa1b-97fc8ed465e6",
    name: "lupin",
  },
  {
    id: "5c3446c5-d5d5-4ac3-a5f3-e20d7ae8dc5a",
    name: "crustacean",
  },
];

export const NOTIFICATION_LEVEL = {
  SENDER_ZONE: "SenderZone",
  NOTIFICATION_TYPE: "NotificationType",
};

export enum DIGEST_FREQUENCY {
  OFF = "Off",
  DAILY = "Daily",
  WEEKLY = "Weekly",
}

export enum AUTH_TYPE {
  OAUTH = "Oauth",
  USER_CREDENTIALS = "UserCredentials",
}

export enum USER_PROFILE_TILE {
  MY_SETTINGS = "MY_SETTINGS",
  NOTIFICATION_SETTINGS = "NOTIFICATION_SETTINGS",
}

export enum USER_PROFILE_PATH {
  MY_SETTINGS = "/user-profile/my-settings",
  NOTIFICATION_SETTINGS = "/user-profile/notification-settings",
}

export const DEFAULT_CLASS_NAME = "business-components";
