import { AxiosInstancesManager } from "../../configs/AxiosInstancesManager";
import { ExtractedFileInfoData } from "./types";

export const extractCertificateInformation = (
  DmsFileId: string,
  objectLink?: string
) => {
  const aiInstance = AxiosInstancesManager.getAI();

  return aiInstance.get<ExtractedFileInfoData>(
        `/api/certificate-extract`,
        {
          params: { DmsFileId, objectLink },
        }
  );
};
