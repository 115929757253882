import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { useIntl } from "react-intl";
import { getUploadedFileUserInfo, uploadFile } from "src/apis/document";
import { isListEmpty, isObjectEmpty } from "src/utils/general";
import { ERROR_CODES, ERROR_STATUSES } from "src/utils/constants";
import { errorMessages } from "src/components/UploadFiles/translations/messages";
import { RcFile } from "antd/lib/upload";
import { DMSFileData, DocumentConfigData } from "src/models";
import { isTheSameFile, prepareFileName } from "./utils";
import { FileListType } from "../../../types";
import { DraggerProps } from "../../types";
import moment from "moment";

const useUploadActions = ({
  defaultFileList,
  fileType,
  onUploadFile,
  onSelect,
  onUploadFiles,
  defaultOnRemove,
  allowedFileExtensions,
  maxFileSizeInBytes,
  shouldAvoidDMSUpload,
  messages,
  maxCount,
}: {
  defaultFileList: FileListType[];
  fileType: string;
  onUploadFile?: Function;
  onSelect?: Function;
  onUploadFiles?: Function;
  defaultOnRemove?: Function;
  allowedFileExtensions?: DocumentConfigData["allowedFileExtensions"];
  maxFileSizeInBytes?: DocumentConfigData["maxFileSizeInBytes"];
  shouldAvoidDMSUpload?: boolean;
  messages?: DraggerProps["messages"];
  maxCount?: number;
}) => {
  const [fileList, setFileList] = useState(defaultFileList);
  const [filesToLink, setFilesToLink] = useState<FileListType[]>([]);
  const [fileActionsInProgress, setFileActionsInProgress] = useState<{
    [key: FileListType["uid"]]: FileListType["status"];
  }>({});
  const [typeErrorUids, setTypeErrorUids] = useState<DMSFileData["fileId"][]>(
    []
  );
  const [sizeErrorUids, setSizeErrorUids] = useState<DMSFileData["fileId"][]>(
    []
  );
  const { formatMessage } = useIntl();

  const isFileActionsEmpty = isObjectEmpty(fileActionsInProgress);

  const onInputFileChange = async e => {
    for (const file of e?.target?.files || e?.dataTransfer?.files || []) {
      try {
        const newFile = await beforeUploadHandler(file as RcFile, []);

        customRequestHandler({ file: newFile });
      } catch (error) {}
    }

    if (e?.target?.value) {
      e.target.value = null;
    }
  };

  const getSetFileInformations =
    (fileUid: string) => (fileInformations: FileListType) => {
      setFileList(previousState =>
        previousState.map(previousFile => {
          if (previousFile.uid === fileUid) {
            return { ...previousFile, ...fileInformations };
          } else {
            return { ...previousFile };
          }
        })
      );
    };

  function isFileTypePresent (allowedFileExtensions, uploadedFileType) {
    // Convert the value to check to lowercase (you can use toUpperCase() for uppercase comparison)
    const checkValueLowerCase = uploadedFileType.toLowerCase();
    return allowedFileExtensions.some(
      item => item.toLowerCase() === checkValueLowerCase
    );
  }

  const handleUploadFileValidation = (file: File, fileUid: string) => {
    const uploadedFileType = file?.name?.split(".").pop();
    const uploadedFileSize = file?.size;
    const isFileTypeInvalid = !isFileTypePresent(
      allowedFileExtensions,
      uploadedFileType
    );
    const isFileSizeBiggerThanAllowed = uploadedFileSize > maxFileSizeInBytes;

    const isFileEmpty = uploadedFileSize <= 0;

    if (isFileSizeBiggerThanAllowed || isFileTypeInvalid || isFileEmpty) {
      let errorMessage = "";

      if (isFileSizeBiggerThanAllowed) {
        setSizeErrorUids(previousState => [...previousState, fileUid]);
        errorMessage =
          messages?.fileSize ?? formatMessage(errorMessages.fileSize);
      }

      if (isFileTypeInvalid) {
        setTypeErrorUids(previousState => [...previousState, fileUid]);
        errorMessage =
          messages?.fileType ?? formatMessage(errorMessages.fileType);
      }

      if (isFileEmpty) {
        setSizeErrorUids(previousState => [...previousState, fileUid]);
        errorMessage = messages?.empty ?? formatMessage(errorMessages.empty);
      }

      return {
        status: "error",
        errorMessage,
      };
    }

    return false;
  };

  const customRequestHandler = async ({ file }) => {
    const fileUid: string = v4();
    const setFileInformations = getSetFileInformations(fileUid);

    const errorFromUploadedFile = handleUploadFileValidation(file, fileUid);

    if (errorFromUploadedFile) {
      setFileInformations(errorFromUploadedFile);
    } else {
      setFileActionsInProgress(previousState => ({
        ...previousState,
        [fileUid]: "uploading",
      }));

      const lastModifiedDate = new Date(file?.lastModifiedDate);
      const uploadedDate = moment(lastModifiedDate);
      const fileUploadedDate = uploadedDate.format("DD/MM/YY");

      const fileUploadedTime = new Date(file?.lastModifiedDate)
        .toLocaleTimeString("en-us", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
        .toString();

      setFileList(previousState => [
        ...previousState,
        {
          uid: fileUid,
          name: file.name,
          size: file.size,
          status: "uploading",
          type: file?.type,
        },
      ]);

      if (shouldAvoidDMSUpload) {
        setFilesToLink(previousState => [...previousState, file]);

        setFileActionsInProgress(previousState => {
          const { [fileUid]: removedAction, ...rest } = previousState;

          return rest;
        });
      } else {
        try {
          const {
            data: { fileId, createdBy },
          } = await uploadFile({
            file,
            fileType,
          });

          const fileUploadedUserName = await getUploadedFileUserInfo(createdBy);

          setFileList(previousState => {
            return previousState.map(fileItem => {
              if (fileItem.uid === fileUid) {
                return {
                  ...fileItem,
                  fileUploadedUserName,
                  fileUploadedDate,
                  fileUploadedTime,
                  type: file?.type,
                };
              }
              return fileItem;
            });
          });

          const fileInformation = {
            uid: fileUid,
            name: file.name,
            size: file.size,
            id: fileId,
            fileUploadedUserName,
            fileUploadedDate,
            fileUploadedTime,
            type: file?.type,
          };

          setFileInformations({
            id: fileId,
            status: "done",
          });

          setFilesToLink(previousState => [...previousState, fileInformation]);

          if (typeof onUploadFile === "function") {
            onUploadFile(fileInformation);
          }
        } catch (error) {
          let errorMessage =
            messages?.upload ?? formatMessage(errorMessages.upload);

          if (error?.response?.status === ERROR_STATUSES.PAYLOAD_TOO_LARGE) {
            setSizeErrorUids(previousState => [...previousState, fileUid]);

            errorMessage =
              messages?.fileSize ?? formatMessage(errorMessages.fileSize);
          }

          switch (error?.response?.data?.errorCode) {
            case ERROR_CODES.FORBIDDEN_FILE_EXTENSION:
              setTypeErrorUids(previousState => [...previousState, fileUid]);

              errorMessage =
                messages?.fileType ?? formatMessage(errorMessages.fileType);

              break;
            case ERROR_CODES.UPLOAD_EMPTY_FILE:
              errorMessage =
                messages?.empty ?? formatMessage(errorMessages.empty);
              break;
            default:
          }

          setFileInformations({
            status: "error",
            errorMessage,
          });
        } finally {
          setFileActionsInProgress(previousState => {
            const { [fileUid]: removedAction, ...rest } = previousState;

            return rest;
          });
        }
      }
    }
  };

  const beforeUploadHandler = (
    file: RcFile,
    currentFileList: RcFile[]
  ): Promise<File> => {
    return new Promise((resolve, reject) => {
      if (maxCount && (fileList?.length || 0) === maxCount) {
        reject(new Error("File list reached max count"));
      }

      const newFile = new File(
        [file],
        prepareFileName({ fileList, fileName: file.name }),
        {
          type: file.type,
        }
      );

      resolve(newFile);
    });
  };

  const onSelectButtonClick = (files: DMSFileData[]) => {
    if (typeof onSelect === "function") {
      onSelect(files);
    }
    if (!shouldAvoidDMSUpload) {
      const newFiles = files.map(file => ({
        uid: file.fileId,
        id: file.fileId,
        name: file.fileName,
        size: file.fileTotalLength,
        status: "done",
      }));

      setFileList(previousState => [...previousState, ...newFiles]);

      setFilesToLink(previousState => [...previousState, ...newFiles]);
    }
  };

  const onRemove = async (file: FileListType) => {
    let errorMessage = "";

    if (typeof defaultOnRemove === "function" && !!file.id) {
      try {
        await defaultOnRemove(file);
      } catch (e) {
        if (e?.response?.data?.errorCode !== ERROR_CODES.NOT_FOUND) {
          errorMessage = e?.message;
        }
      }
    }

    if (!errorMessage) {
      setFileList(previousState =>
        previousState.filter(previousFile => !isTheSameFile(file, previousFile))
      );
    } else {
      setFileList(previousState =>
        previousState.map(previousFile => ({
          ...previousFile,
          ...(isTheSameFile(file, previousFile) && {
            errorMessage,
            status: "error",
          }),
        }))
      );
    }
  };

  const handleFilesToLink = () => {
    if (isObjectEmpty(fileActionsInProgress) && !isListEmpty(filesToLink)) {
      if (typeof onUploadFiles === "function") {
        onUploadFiles(filesToLink);
      }

      setFilesToLink([]);
    }
  };

  useEffect(() => {
    const fileUids = fileList.map(({ uid }) => uid);

    setSizeErrorUids(previousState =>
      previousState.filter(previousFileUid =>
        fileUids.includes(previousFileUid)
      )
    );

    setTypeErrorUids(previousState =>
      previousState.filter(previousFileUid =>
        fileUids.includes(previousFileUid)
      )
    );
  }, [fileList.length]);

  useEffect(() => {
    handleFilesToLink();
  }, [isFileActionsEmpty, filesToLink.length]);

  useEffect(() => {
    setFileList(defaultFileList);
  }, [defaultFileList]);

  return {
    onInputFileChange,
    onSelectButtonClick,
    customRequestHandler,
    beforeUploadHandler,
    onRemove,
    fileList,
    filesToLink,
    typeErrorUids,
    sizeErrorUids,
  };
};

export default useUploadActions;
