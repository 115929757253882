import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Heading, Paragraph } from "@trace-one/design-system";

import { selectAllergenData } from "reduxStore/siteRetailerDetails/selectors";

import Label from "components/Label";
import Panel from "components/Panel";
import TabPage from "components/TabPage";
import { capitalizeText } from "shared/utils/capitalizeFullText";

const AllergenBloc = () => {
  const intl = useIntl();
  const allergenData = useSelector(selectAllergenData);

  return (
    <Panel>
      <TabPage.SecondaryTitle
        label={
          <Heading data-test-id="site-allergens-heading" size="xs">
            {capitalizeText(
              intl.formatMessage({ id: "siteDetails.allergens.title" })
            )}
          </Heading>
        }
      />
      <Label
        label={
          <Heading size="xxs">
            {intl.formatMessage({ id: "siteDetails.allergenslist.label" })}{" "}
          </Heading>
        }
        testId="site-allergens"
      >
        <Paragraph size="m">{allergenData?.join(", ")}</Paragraph>
      </Label>
    </Panel>
  );
};

export default AllergenBloc;
