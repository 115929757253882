/* istanbul ignore file */
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Tooltip } from "@trace-one/design-system";
import { Spinner } from "@trace-one/react-components";
import { Menu } from "antd";
import { ProductColumn } from "pages/Products/pages/ProductList/ProductTable/models";

import { PmdAPI } from "apis";
import usePermissions from "core/oidc/usePermissions";

import {
  selectUserOwningCompanyId,
  selectUserSelectedCompany,
} from "reduxStore/user/selectors";

import ActionDropdown from "components/ActionDropdown";
import { TradeItemStatus } from "shared/constants";
import useAsyncFileDownload from "shared/hooks/useAsyncFileDownload";
import useAsyncHandler from "shared/hooks/useAsyncHandler";
import useToast from "shared/hooks/useToast";

interface ProductActionProps {
  record: ProductColumn;
  refetchProducts: () => void;
}

const ProductAction: React.FC<ProductActionProps> = ({
  record,
  refetchProducts,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const userOwningCompanyId = useSelector(selectUserOwningCompanyId);
  const { productsWrite, productsUpdate, productsEnable } = usePermissions();
  const { companyId: selectedCompanyId } =
    useSelector(selectUserSelectedCompany) ?? {};
  const [visible, setVisible] = useState<boolean>(false);
  const { rawProductData } = record;
  const toast = useToast();
  const isArchive = rawProductData.tradeItemStatus !== TradeItemStatus.INACTIVE;

  const handleEditProduct = () => {
    history.push(`/products/edit/${record.key}`);
  };
  const handleDuplicateProduct = () => {
    history.push(`/products/create?duplicate=${record.key}`);
  };
  const [handleExportProduct, isExportProductLoading] = useAsyncFileDownload(
    () => {
      return PmdAPI.exportTradeItems(
        { ids: [record.key] },
        { ownerCompanyId: userOwningCompanyId }
      );
    },
    { fileExtension: "csv" }
  );
  const [handleExportManufactedItem, isExportManufacturedItemLoading] =
    useAsyncFileDownload(
      () => {
        return PmdAPI.exportManufacturedItems(
          { tradeItemIds: [record.key] },
          { ownerCompanyId: userOwningCompanyId }
        );
      },
      { fileExtension: "csv" }
    );

  const [handleProductStatus, isArchiveProductLoading] = useAsyncHandler(
    async () => {
      try {
        if (isArchive) {
          await PmdAPI.archiveTradeItems({ idCollection: [record.key] });
          toast.success({
            message: intl.formatMessage({
              id: "productDetails.archivedSuccessTitle",
            }),
            description: intl.formatMessage({
              id: "productDetails.archivedSuccessText",
            }),
          });
        } else {
          await PmdAPI.unArchiveTradeItems(record.key);
          toast.success({
            message: intl.formatMessage({
              id: "productDetails.unArchivedSuccessTitle",
            }),
            description: intl.formatMessage({
              id: "productDetails.unArchivedSuccessText",
            }),
          });
        }
        refetchProducts();
      } catch (error) {
        if (isArchive) {
          toast.error({
            description: intl.formatMessage({
              id: "productDetials.archivedFailedText",
            }),
            message: intl.formatMessage({
              id: "productDetials.archivedFailedTitle",
            }),
            checkErrorResponse: false,
          });
        } else {
          toast.error({
            description: intl.formatMessage({
              id: "productDetials.unArchivedFailedText",
            }),
            message: intl.formatMessage({
              id: "productDetials.unArchivedFailedTitle",
            }),
            checkErrorResponse: false,
          });
        }
      }
    }
  );

  return (
    <ActionDropdown
      data-test-id="md-product-action"
      overlay={
        <Menu>
          {userOwningCompanyId === rawProductData.ownerCompanyId &&
            (productsWrite || productsUpdate) &&
            isArchive && (
              <Menu.Item key="edit-product" onClick={handleEditProduct}>
                {intl.formatMessage({
                  id: "productsListPage.table.actions.editProduct",
                })}
              </Menu.Item>
            )}
          {userOwningCompanyId === rawProductData.ownerCompanyId &&
            productsWrite && (
              <Menu.Item
                key="duplicate-product"
                onClick={handleDuplicateProduct}
              >
                {intl.formatMessage({
                  id: "productsListPage.table.actions.duplicateProduct",
                })}
              </Menu.Item>
            )}
          <Menu.Item
            key="export-product"
            onClick={handleExportProduct}
            disabled={isExportProductLoading}
          >
            <Spinner spinning={isExportProductLoading}>
              {intl.formatMessage({ id: "general.exportProduct" })}
            </Spinner>
          </Menu.Item>
          <Menu.Item
            key="edit-manufacturedItem"
            onClick={handleExportManufactedItem}
            disabled={isExportManufacturedItemLoading}
          >
            <Spinner spinning={isExportManufacturedItemLoading}>
              {intl.formatMessage({ id: "general.exportManufacturedItem" })}
            </Spinner>
          </Menu.Item>
          {isArchive
            ? (productsWrite || productsUpdate) && (
                <Menu.Item
                  key="archive-product"
                  disabled={isArchiveProductLoading}
                >
                  {
                    <Tooltip
                      className="withNoArrow"
                      onOpenChange={() => setVisible(true)}
                      placement="top"
                      trigger="click"
                      text={intl.formatMessage(
                        {
                          id: "productsList.actionTooltipTitle",
                        },
                        { Name: " " + record.itemName }
                      )}
                      actions={[
                        {
                          text: intl.formatMessage({ id: "general.cancel" }),
                          onClick: (event: React.FocusEvent<HTMLElement>) => {
                            event.target.blur();
                            setVisible(false);
                          },
                        },
                        {
                          text: intl.formatMessage({ id: "general.confirm" }),
                          onClick: () => {
                            handleProductStatus();
                            setVisible(false);
                          },
                        },
                      ]}
                      visible={visible}
                    >
                      <Spinner spinning={isArchiveProductLoading}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span>
                            {intl.formatMessage({
                              id: "general.archive",
                            })}
                          </span>
                        </div>
                      </Spinner>
                    </Tooltip>
                  }
                </Menu.Item>
              )
            : productsEnable && (
                <Menu.Item
                  key="archive-product"
                  disabled={isArchiveProductLoading}
                >
                  {
                    <Tooltip
                      className="withNoArrow"
                      onOpenChange={() => setVisible(true)}
                      placement="top"
                      trigger="click"
                      text={intl.formatMessage(
                        {
                          id: "productsList.actionTooltipTitle.unArchive",
                        },
                        { Name: " " + record.itemName }
                      )}
                      actions={[
                        {
                          text: intl.formatMessage({ id: "general.cancel" }),
                          onClick: (event: React.FocusEvent<HTMLElement>) => {
                            event.target.blur();
                            setVisible(false);
                          },
                        },
                        {
                          text: intl.formatMessage({ id: "general.confirm" }),
                          onClick: () => {
                            handleProductStatus();
                            setVisible(false);
                          },
                        },
                      ]}
                      visible={visible}
                    >
                      <Spinner spinning={isArchiveProductLoading}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span>
                            {intl.formatMessage({
                              id: "general.unArchive",
                            })}
                          </span>
                        </div>
                      </Spinner>
                    </Tooltip>
                  }
                </Menu.Item>
              )}
          {!selectedCompanyId && (
            <Menu.Item
              key="view-history"
              data-test-id="md-product-view-history"
              onClick={() => {
                history.push(`/products/history/${record.key}`);
              }}
            >
              {intl.formatMessage({ id: "general.viewVersionsHistory" })}
            </Menu.Item>
          )}
        </Menu>
      }
    />
  );
};

export default ProductAction;
