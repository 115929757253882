import React from "react";
import { DisplayCard, Typography, Spinner } from "@trace-one/design-system";
import classnames from "classnames";
import { AllergensListProps } from "./types";
import { getPrefixClassName } from "src/utils/general";
import { ALLERGENS } from "src/utils/constants";

const AllergensList: React.FC<AllergensListProps> = ({
  allergensData,
  isLoading,
  selectedAllergens,
  selectedAllergensProp,
  setSelectedAllergens,
  subtitle,
}) => {
  const prefixClassName = getPrefixClassName("allergens-modal");

  if (isLoading) {
    return (
      <div className={`${prefixClassName}-spinner`}>
        <Spinner size="large" />
      </div>
    );
  }

  return (
    <>
      <Typography className={`${prefixClassName}-title`} variant="body-regular">
        {subtitle}
      </Typography>
      <div
        className={`${prefixClassName}-list`}
        data-test-id={`${prefixClassName}-list`}
      >
        {allergensData?.map((allergen) => {
          const isSelectedAllergen = selectedAllergensProp.some(
            ({ id }) => id === allergen.id
          );
          const props = {
            title: allergen.name,
            selected: selectedAllergens.some(({ id }) => id === allergen.id),
            iconName:
              ALLERGENS.find(({ id }) => id === allergen.id)?.name || "",
          };

          return (
            <DisplayCard
              key={allergen.id}
              data-test-id={`allergen-card-${allergen.id}`}
              {...props}
              className={classnames(`${prefixClassName}-list-card`, {
                disabled: isSelectedAllergen,
              })}
              onClick={() => {
                if (!isSelectedAllergen) {
                  setSelectedAllergens((current) => {
                    if (current.some(({ id }) => id === allergen.id)) {
                      return current.filter((x) => x.id !== allergen.id);
                    }
                    const newAllergen = JSON.parse(JSON.stringify(allergen));
                    return [...current, newAllergen];
                  });
                }
              }}
            />
          );
        })}
      </div>
    </>
  );
};

export default AllergensList;
