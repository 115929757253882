export const SectionsIds = Object.freeze({
  IDENTIFICATION: "product-identification-section",
  ATTRIBUTES: "attributes-section",
  CHARATERISTICS: "product-charateristics-section",
  PACKAGING: "packaging-section",
  MANUFACTURED_ITEMS: "manufactured-items-section",
  COMMENTS: "comments-section",
  MANUFACTURED_ITEMS_SUPPLIER: "manufactured-items-section-supplier-section",
  CERTIFICATES_SECTION: "certificates-section",
});
