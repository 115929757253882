import { Section } from "../types";

export const findSubsectionWithParent = (sections: Section[], subsectionId: string) => {
  for (const section of sections) {
    if (section.sections && section.sections.length > 0) {
      for (const subsection of section.sections) {
        if (subsection.sectionId === subsectionId) {
          return {
            parent: section,
            subsection,
          };
        }
        const result = findSubsectionWithParent(subsection.sections || [], subsectionId);
        if (result) return result;
      }
    }
  }
  return null;
};

export const filterSidebarSections = (sections: Section[], searchTerm: string): Section[] => {
  const lowerInput = searchTerm.toLowerCase();

  return sections.reduce<Section[]>((acc, section) => {
    const matchesSectionName = section.sectionName.toLowerCase().startsWith(lowerInput);
    const filteredSubSections = section.sections?.length ? filterSidebarSections(section.sections, lowerInput) : [];

    if (matchesSectionName || filteredSubSections.length) {
      acc.push({
        ...section,
        sections: filteredSubSections,
        expand: matchesSectionName || filteredSubSections.length > 0,
      });
    }

    return acc;
  }, []);
};
