import classNames from "classnames";
import "./styles.less";
import React from "react";

type DotProps = {
  className?: string;
  dataTestId?: string;
};

const Dot: React.FC<DotProps> = ({ className, dataTestId }) => {
  return (
    <div
      className={classNames("dot", className)}
      data-test-id={dataTestId}
    />
  );
};

export default Dot;
