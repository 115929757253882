import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { Tooltip, Button } from "@trace-one/design-system";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";

import { DocumentsListDataItem } from "models";

import getUsersCollectionMap, {
  UserCollectionMapType,
} from "shared/utils/getUsersCollectionMap";

import styles from "../DocumentTable.module.less";
import { DocumentTableColumn } from "../models";

type ValueOf<T> = T[keyof T];

export const mapDocumentToColumn = (
  doc: DocumentsListDataItem,
  uploadedUser?: ValueOf<UserCollectionMapType>
): DocumentTableColumn => {
  const {
    fileId,
    fileName,
    fileTypeName,
    version,
    ownerCompanyId,
    uploadedAt,
  } = doc as any;

  const uploadedBy = uploadedUser?.userName ?? "";
  let ownerCompanyName = "";
  if (uploadedUser?.owningCompanyId === ownerCompanyId) {
    ownerCompanyName = uploadedUser.owningCompanyName;
  }

  return {
    key: fileId,
    documentName: fileName,
    documentType: fileTypeName,
    version: version,
    uploadedBy,
    companyOwner: ownerCompanyName,
    lastModifiedOn: uploadedAt,
    rawDocumentsListDataItem: doc,
  };
};

const useDocumentTable = ({
  documents,
  displayFileInfo = () => {},
  setSelectedRowKeys,
}: {
  documents: DocumentsListDataItem[];
  displayFileInfo?: (record?: any) => void;
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { formatMessage } = useIntl();
  const [usersMap, setUsersMap] = useState<UserCollectionMapType>({});

  useEffect(() => {
    if (documents.length > 0) {
      const uploadedUserIds = new Set<string | null>();

      for (const doc of documents) {
        uploadedUserIds.add(doc.uploadedBy);
      }
      getUsersCollectionMap({
        userIds: [...uploadedUserIds].filter(Boolean),
      }).then(users => {
        setUsersMap(Object.assign({}, usersMap, users));
      });
    }
  }, [documents]);

  const columns: ColumnsType<DocumentTableColumn> = [
    {
      title: formatMessage({
        id: "documentLibraryListPage.table.col.docName",
      }),
      dataIndex: "fileName",
      key: "documentName",
      width: 150,
      ellipsis: true,
      render: (_, record) => (
        <>
          <Tooltip showFullText text={record.documentName} placement="top">
            <Button
              type="link"
              className={styles.documentNameBtn}
              onClick={() => {
                setSelectedRowKeys(current => {
                  if (current.includes(record.key)) {
                    return current.filter(x => x !== record.key);
                  }
                  return [...current, record.key];
                });
              }}
            >
              {record.documentName}
            </Button>
          </Tooltip>
        </>
      ),
    },
    {
      title: formatMessage({
        id: "documentLibraryListPage.table.col.docVersion",
      }),
      dataIndex: "version",
      key: "version",
      width: 50,
      render: (_, record) => <div>V{record.version}</div>,
    },
    {
      title: formatMessage({
        id: "documentLibraryListPage.table.col.docType",
      }),
      dataIndex: "fileTypeId",
      key: "documentType",
      width: 150,
      ellipsis: true,
      render: (_, record) => (
        <>
          <Tooltip showFullText text={record.documentType} placement="top">
            <div className={styles.documentListTooltip}>
              {record.documentType}
            </div>
          </Tooltip>
        </>
      ),
    },
    {
      title: formatMessage({
        id: "documentLibraryListPage.table.col.companyOwner",
      }),
      dataIndex: "companyOwner",
      key: "companyOwner",
      width: 100,
      render: (_, record) => <div>{record.companyOwner}</div>,
    },
    {
      title: formatMessage({
        id: "documentLibraryListPage.table.col.uploadedBy",
      }),
      dataIndex: "uploadedBy",
      key: "uploadedBy",
      width: 100,
      render: (_, record) => <div>{record.uploadedBy}</div>,
    },
    {
      title: formatMessage({
        id: "documentLibraryListPage.table.col.lastModifiedDate",
      }),
      dataIndex: "lastModifiedOn",
      key: "lastModifiedOn",
      width: 100,
      render: (_, record) => (
        <div>{moment(record.lastModifiedOn).format("DD/MM/yyyy")}</div>
      ),
    },
  ];

  const data = useMemo(() => {
    return documents.map(doc => {
      return mapDocumentToColumn(doc, usersMap[doc.uploadedBy]);
    });
  }, [documents, usersMap]);

  return {
    columns,
    data,
  };
};

export default useDocumentTable;
