import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "reduxStore";
import { fetchUsersByCompanyId } from "reduxStore/shared/asyncActions";
import { _selectUserList } from "reduxStore/shared/selectors";
import {
  selectUserOwningCompanyId,
  selectUserSelectedCompany,
} from "reduxStore/user/selectors";

import getDisplayName from "shared/utils/getDisplayName";

import { renderHocMiddleware, BaseHocProps } from "../_helpers";

const withUsers =
  () =>
  <P extends BaseHocProps>(WrappedComponent: React.ComponentType<P>) => {
    const Component: React.FC<P> = props => {
      const dispatch = useAppDispatch();
      const owningCompanyId = useSelector(selectUserOwningCompanyId);
      const { companyId: selectedCompanyId } =
        useSelector(selectUserSelectedCompany) ?? {};

      const { isInitiated, hasError, isLoading } = useSelector(_selectUserList);

      useEffect(() => {
        dispatch(fetchUsersByCompanyId(selectedCompanyId || owningCompanyId));
      }, [owningCompanyId, selectedCompanyId, dispatch]);

      return renderHocMiddleware({
        WrappedComponent,
        _isInitiated: isInitiated,
        _hasError: hasError,
        _isLoading: isLoading,
        ...props,
      });
    };

    Component.displayName = getDisplayName(withUsers, WrappedComponent);

    return Component;
  };

export default withUsers;
