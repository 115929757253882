import { SizeType } from "@trace-one/design-system";
import { AppListProps } from "./components/AppList/types";
import { DiscoverSolutionsProps } from "./components/DiscoverSolutions/types";
import { UserApplication } from "../../models";
import { CompanySwitcherProps } from "../CompanySwitcher/types";

export enum AppSwitcherVariant {
  GRID = "grid",
  VERTICAL = "vertical",
}

export type VariantType = AppSwitcherVariant.GRID | AppSwitcherVariant.VERTICAL;

export interface AppSwitcherProps {
  accessToken: string;
  userId: string;
  excludeApps?: number[];
  open?: boolean;
  onOpenChange?: (newOpen: boolean) => void;
  showDiscoverSolutions?: boolean;
  withIntercomTrack?: boolean;
  handleClose?: DiscoverSolutionsProps["handleClose"];
  handleCreationPopUp?: AppListProps["handleCreationPopUp"];
  fetchCompaniesByIds?: Function;
  variant?: VariantType;
  includeApps?: UserApplication[];
  orderAppsById?: boolean;
  companySwitcherProps?: CompanySwitcherProps;
  size?: SizeType;
}
