import { useCallback, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Container } from "@trace-one/design-system";

import { CatalogueAPI, SmdAPI } from "apis";

import { selectIsRetailer } from "reduxStore/oidc/selectors";
import {
  selectUserOwningCompanyId,
  selectUserLanguageCode,
} from "reduxStore/user/selectors";

import Spinner from "components/Spinner";
import getUsersCollectionMap from "shared/utils/getUsersCollectionMap";

import withSiteShared, { SiteSharedProps } from "../../hocs/withSiteShared";

import { SiteVersionsResult } from "./models";
import styles from "./SiteHistory.module.less";
import VersionsDetails from "./VersionDetails";
import VersionsPanel from "./VersionsPanel";

interface SiteHistoryProps extends SiteSharedProps {}

const SiteHistory: React.FC<SiteHistoryProps> = ({ isSiteSharedInitiated }) => {
  const { siteId } = useParams<{ siteId?: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const versionsInfoRef = useRef<SiteVersionsResult[] | null>(null);
  const associatedCompanyId = useSelector(selectUserOwningCompanyId);
  const isRetailer = useSelector(selectIsRetailer);
  const languageCode = useSelector(selectUserLanguageCode);
  const [selectedVersion, setSelectedVersion] = useState<number>(null);
  const selectedVersionIndex = versionsInfoRef.current?.findIndex(
    v => v.version === selectedVersion
  );

  const refetchVersions = useCallback(async () => {
    let params;
    if (isRetailer) {
      const relation =
        await SmdAPI.getSiteRelationBySiteIdAndAssociatedCompanyId(siteId, {
          associatedCompanyId,
        }).then(({ data }) => data);
      params = {
        StartDate: relation.createdAt,
        ...(!relation.ownerCompanyRelationStatus
          ? { EndDate: relation.modifiedAt }
          : {}),
      };
    }

    try {
      const { data } = await SmdAPI.getSiteHistory(siteId, params);
      const userIds = data.map(v => {
        return [...v.contactUserIds, v.modifiedBy];
      });
      const usersList = await getUsersCollectionMap({
        userIds: Array.prototype.concat.apply([], userIds),
      });

      const allergenData = await Promise.all(
        data.map(({ allergenIds, version }) =>
          CatalogueAPI.getAllergensByIds(
            { allergenIds },
            {
              languageCode,
              includeParents: true,
            }
          ).then(({ data }) => ({ version: version, data }))
        )
      );

      const versionsData = data.map(v => ({
        ...v,
        usersData: usersList || null,
        allergenData,
      }));

      versionsInfoRef.current = versionsData;
      setSelectedVersion(versionsInfoRef.current[0].version);
      setIsLoading(false);
      return data;
    } catch (error) {
      console.error(error);
    }
  }, [siteId]);

  useEffect(() => {
    setIsLoading(true);
    refetchVersions();
  }, [refetchVersions]);

  if (isLoading || !isSiteSharedInitiated) return <Spinner underHeader />;

  return (
    <div className={styles.MainContainer}>
      <Container isPageWrapper className={styles.SiteHistoryContainer}>
        <VersionsPanel
          versions={versionsInfoRef.current}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
        />
        {versionsInfoRef.current && (
          <VersionsDetails
            versionDetails={versionsInfoRef.current[selectedVersionIndex]}
            previousVersionDetails={
              versionsInfoRef.current[selectedVersionIndex + 1]
            }
          />
        )}
      </Container>
    </div>
  );
};

export default withSiteShared(SiteHistory);
